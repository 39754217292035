.main-page {
    .header {
        min-height: 100vh;
        position: relative;
        background: #000;
        display: flex;
        align-items: center;

        &:after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background-size: cover;
            background-position: center;
            background-image: url('../../image/cover.jpg');
            width: 100%;

            @media (min-width: $break-md) {
                width: 60%;
            }
        }

        &:before {
            content: '';
            position: absolute;
            background: rgba(0, 0, 0, 0.4);
            width: 100%;
            left: 0;
            top: 0;
            bottom: 0;
            z-index: 2;

            @media (min-width: $break-md) {
                width: 70%;
                background: linear-gradient(to right, #000 60%, rgba(#000, 0.01));
            }
        }

        &__inner {
            max-width: 400px;
            position: relative;
            z-index: 5;
            padding: 100px 15px 25px;
            box-sizing: content-box;

            @media (min-width: $break-lg) {
                padding: 100px 68px 25px;
            }

            .slogan {
                color: #fff;
                width: 100%;
                margin-bottom: 40px;

                &__text {
                    font-size: 22px;
                    font-weight: 500;
                    margin-top: 20px;
                    line-height: 1.38;
                    letter-spacing: 0.76px;

                    .theme-color {
                        font-weight: bold;
                    }

                    .subtext {
                        color: #fff;
                    }

                    @media (min-width: $break-md) {
                        font-size: 26px;
                    }
                }
            }
        }
    }

    header {
    }

    .header-overlayer-categories {
        background-color: $gray-light;
        position: sticky;
        top: 50px;
        @media (min-width: $break-lg) {
            top: 75px;
        }
        z-index: 20;

        .category-container {
            position: relative;

            .category-label {
                padding: 12px 40px 11px 40px;
                font-size: 12px;
                text-align: center;
                color: $font-color-light;
                @media (min-width: $break-md) {
                    position: absolute;
                }
            }

            .categories-list {
                overflow-x: auto;
                height: 37px;

                &::-webkit-scrollbar {
                    display: none;
                }

                @media (min-width: $break-md) {
                    overflow-x: unset;
                    height: auto;
                }

                .categories-list-inner {
                    text-align: center;
                    height: 37px;
                    white-space: nowrap;

                    @media (min-width: $break-md) {
                        height: auto;
                        white-space: normal;
                    }

                    .category-item {
                        font-size: 12px;
                        font-weight: 500;
                        display: inline-block;
                        text-decoration: none;
                        position: relative;
                        padding: 12px 12px 11px 12px;

                        @media (min-width: $break-lg) {
                            padding: 12px 24px 11px 24px;
                        }
                    }
                }
            }
        }
    }
}
