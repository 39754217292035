.category__list, .location__list {
    display: none;
    list-style: none;
    
    z-index:2;
    background: #ffffff;
    ul {
        list-style: none;
        padding:0;
    }
    
    li ul {
       
    }
    
    span {
        cursor: pointer;
        display: block;
        width: 100%;
        padding: 5px 5px;
        position: relative;
        z-index: 1;
        &:hover {
           
        }
    }
    
}


   .location__list {
        &.open {
            display: block;
            position: absolute;
            top:90px;
          
            
        }
}
    .category__list 
    {
        li:first-child{
           
        }
        
        &.open {
            display: block;
            position: absolute;
            top: 90px;
            width: 450px;
            height:450px;
            border: 1px solid #ddd;
            box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.13);
            border-radius: 50px;
            padding: 20px;
            right:150px;
        }
    }

    

.location__list li {
    padding: 10px  10px;
    cursor: pointer;
    
    &:hover {
        background-color: #F7EA00;
    }
}


.category__list > ul > li{
    text-align: left;
    text-transform: lowercase;
    width:175px;
    color:#999999;
    margin-bottom:20px;
    position: relative;
    > span{
        font-style: italic;
        font-weight: bold;
    }
    
}

.form-search{position: relative;}


.category__list ul li ul{
    display: none;
   
}


.ActiveChild{
    display: block;
    float: left;
    position: absolute;
    left: 210px;
    top: 20px;
    color: #000000;
    text-align: left;
}
.ActiveChild li:hover{
    background-color: #F7EA00;
}
.category__list ul li.active{

    color:#000000;
    &::after{
    content: "";
    width: 21px;
    height: 21px;
    background-color: #F7EA00;
    border-radius: 50%;
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    z-index: 0;
    }
}