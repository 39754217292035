
.step-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    
    &:before {
        content: '';
        display: block;
        position: absolute;
        height: 1px;
        width: 100%;
        background-color: $gray-light;
        top: 13px;
        z-index: -1;
    }
    
    .step-bar-item {
        background: white;
        text-align: center;
        cursor: pointer;
        @media (min-width: $break-sm) {
            text-align: left;
            padding: 0 20px;
        }
        
        &:first-of-type {
            padding-left: 0;
        }
        
        &:last-of-type {
            padding-right: 0;
        }
        
        .item-id {
            display: inline-block;
            font-size: 13px;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            background-color: $yellow;
            color: $font-color;
            text-align: center;
            line-height: 20px;
            
        }
        
        .item-name {
            display: block;
            font-size: 13px;
            line-height: 20px;
            
            @media (min-width: $break-sm) {
                display: inline-block;
                margin-left: 10px;
            }
        }
        
        &.active {
            .item-id {
                background-color: #34D1BF ;
                color: $font-color;

            }
            
        }
    }
    .active ~ .step-bar-item {
        .item-id {  background-color:$font-color  ;
          color: white;
        }
      }
}

.event-component {
    max-width: 650px;
    border: 0px solid;
    padding: 30px;
    border-radius: 20px;
    box-shadow: 5px 5px 50px #ddd;
    background-color: #ffffff;
    .event-component-body {
        margin-top: 32px;
    }
    
    .form-separator {
        margin: 40px 0;
    }
    
    h2 {
        font-size: 24px;
    }
    
    h3 {
        font-size: 20px;
    }
    
    h4 {
        font-size: 18px;
    }
    
    h5 {
        font-size: 16px;
    }
    
    .create-form-content {
        margin-top: 35px;
    }
    
    .btn--cancel {
        padding-right: 40px;
        padding-left: 40px;
        min-width: 50px;
    }
    
    .form-description {
        margin-bottom: 40px;
        margin-top: 8px;
    }
    
    .form-add-item {
        margin-bottom: 24px;
        width: 100%;
        height: 48px;
        border-radius: 3px;
        border: dashed 1px $input-border;
        background-color: $input-bg;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        
        .add-label {
            font-weight: bold;
            margin: 5px 0 0;
        }
    }
    
    .ticket-sale-method {
        .ticket-info {
            font-family: $font-korolev;
            font-size: 15px;
        }
        
        .ticket-type-item {
            cursor: pointer;
            border-radius: 3px;
            border: solid 1px $input-border;
            background-color: $input-bg;
            padding: 16px 26px;
            color: $font-color-dark;
            
            .item-radio {
                padding-right: 45px;
                display: flex;
                align-items: center;
            }
            
            .item-content {
                display: flex;
                flex-direction: column;
                
                .item-head {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    h2 {
                        font-size: 20px;
                        margin: 0;
                        font-weight: bold;
                        letter-spacing: 0.28px;
                        color: $font-color-dark;
                    }
                    
                    .label-reco {
                        font-family: $font-korolev;
                        font-size: 16px;
                        font-weight: bold;
                        width: 134px;
                        height: 35px;
                        border-radius: 4px;
                        background-color: $yellow;
                        letter-spacing: 0.23px;
                        color: $font-color-dark;
                        padding: 8px 16px;
                    }
                }
                
                .method-info {
                    margin-top: 10px;
                    font-family: $font-korolev;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 1.73;
                    letter-spacing: 0.21px;
                }
            }
        }
    }
    
    .form-popup-layer {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1040;
        background-color: rgba(#000, .3);
    }
    
    .form-popup {
        position: fixed;
        right: -100%;
        top: 0;
        height: 100vh;
        width: 420px;
        max-width: 99vw;
        box-shadow: 0 2px 24px 0 #2c323a19;
        background-color: #ffffff;
        transition: right .3s ease;
        padding-bottom: 65px;
        z-index: 1050;
        
        &.open {
            right: 0;
        }
        
        .popup-body {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 24px;
            
            .popup-ticket-title, .popup-activity-title {
                background: url("../../image/ui/ticket.png") no-repeat left center;
                background-size: contain;
                height: 45px;
                padding-left: 55px;
                display: flex;
                align-items: center;
                margin-top: -10px;
            }
            
            .popup-activity-title {
                background: url("../../image/ui/statistic.png") no-repeat left center;
                background-size: contain;
            }
        }
        
        .popup-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 8px 0;
            margin: 0 24px;
            width: calc(100% - 48px);
            border-top: 1px solid $input-border;
            background: white;
        }
        
        .form-sep {
            margin-top: 23px;
            padding-bottom: 16px;
            height: 1px;
            border-top: 1px solid $input-border;
        }
        
        .select-category {
            border: 1px solid $input-border;
            background-color: $input-bg;
            height: 48px;
            font-family: $font-korolev;
            font-size: 14px;
            border-radius: 3px;
            line-height: 48px;
            padding: 0 20px 0 50px;
            display: inline-block;
            position: relative;
            cursor: pointer;
            
            &:before, &:after {
                content: '';
                display: block;
                height: 2px;
                width: 14px;
                top: 23px;
                left: 20px;
                background-color: $font-color-light;
                position: absolute;
            }
            
            &:after {
                transform: rotate(90deg);
            }
        }
        
        .categories-list {
            margin-top: 10px;
            
            .btn {
                margin: 5px 5px 0 0;
            }
        }
    }
    
    .form-footer {
        position: fixed;
        bottom: 0;
        background: white;
        width: 100%;
        left: 0;
        
        .event-component {
            height: 67px;
            border-top: 1px solid $input-border;
            flex-direction: row;
            justify-content: space-between;
            display: flex;
            width: 100%;
            align-items: center;
            
            .footer-stats {
                
                .stat-item {
                    padding: 0 20px;
                    
                    .stat-label {
                        font-size: 12px;
                    }
                    
                    .stat-data {
                        font-size: 20px;
                        font-family: $font-korolev;
                        font-weight: bold;
                        display: flex;
                        flex-direction: row;
                        
                        .stat-after {
                            font-weight: normal;
                        }
                    }
                }
            }
        }
    }
    
    .category-popup {
        
        .form-item {
            margin: 0;
        }
        
        .category-name {
            height: 48px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            
            &:after {
                content: '';
                display: inline-block;
                width: 7px;
                height: 13px;
                border-right: 3px solid $font-color;
                border-bottom: 3px solid $font-color;
                transform: translate(10px, -3px) rotate(45deg);
                opacity: 0;
                
            }
            
            &.selected {
                &:after {
                    opacity: 1;
                }
            }
        }
        
        .subcategories {
            padding-left: 15px;
            
            .form-item {
                margin-top: -15px;
            }
        }
    }
    
    .activity-list {
        .activity-list-item {
            margin-bottom: 80px;
            
            .item-head {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            
            .activity-name {
                font-size: 26px;
                font-weight: bold;
                color: $font-color-dark;
                margin: 0;
            }
            
            .activity-location, .activity-date {
                background: no-repeat top left;
                padding-left: 35px;
                margin-top: 18px;
                font-size: 15px;
                color: $font-color-light;
                
                &.small {
                    padding-left: 25px;
                    margin-top: 8px;
                    font-size: 12px;
                }
            }
            
            .activity-date {
                background-image: url("../../image/icon/calendar.svg");
            }
            
            .activity-location {
                background-image: url("../../image/icon/location.svg");
                background-position-x: 3px;
            }
            
            .activity-tickets {
                margin-top: 32px;
            }
        }
        
        .ticket-list-item {
            border-radius: 3px;
            border: 1px solid $input-border;
            background-color: $input-bg;
            border-left: 7px solid $yellow;
            padding: 16px 20px 16px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
            cursor: pointer;
            position: relative;
            
            .ticket-title {
                font-size: 20px;
                font-weight: bold;
                color: $font-color-dark;
                margin: 0;
            }
            
            .item-info {
                .item-info-price {
                    font-family: $font-korolev;
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 1.33;
                    letter-spacing: 0.34px;
                    text-align: right;
                    color: $font-color-dark;
                }
                
                .item-info-date {
                    font-size: 15px;
                    color: $font-color-light;
                    
                }
            }
            
            .remove-ticket {
                position: absolute;
                height: 80px;
                width: 0;
                background: url("../../image/icon/delete.svg") no-repeat center;
                cursor: pointer;
                opacity: 0;
                transition: all .3s ease;
                right: 0;
            }
            
            &:hover {
                .remove-ticket {
                    opacity: 1;
                    width: 45px;
                    right: -45px;
                }
            }
            
            @media (max-width: 800px) {
                width: calc(100% - 40px);
                
                .remove-ticket {
                    opacity: 1;
                    width: 45px;
                    right: -45px;
                }
            }
        }
        
        .ticket-list-element {
            display: flex;
            flex-direction: row;
            border-top: 1px solid $input-border;
            padding: 13px 0;
            font-size: 14px;
            margin: 0;
            align-items: center;
            
            &.head {
                border: none;
                padding: 5px 0;
                color: $font-color-light;
                font-weight: 300;
            }
            
            .ticket-title {
                flex-grow: 1;
                font-family: $font-korolev;
            }
            
            .ticket-price {
                text-align: center;
                flex: 0 0 110px;
                
            }
            
            .ticket-amount {
                display: flex;
                flex-direction: row;
                flex: 0 0 120px;
                justify-content: center;
                
                .amount-change {
                    height: 16px;
                    width: 16px;
                    border-radius: 10px;
                    background-color: $input-bg;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    line-height: 25px;
                    font-size: 20px;
                    user-select: none;
                    position: relative;
                    
                    &:after, &:before {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 7px;
                        left: 4px;
                        background: $font-color;
                        height: 1px;
                        width: 8px;
                    }
                    
                    &:hover {
                        background-color: $font-color-light;
                        
                        &:after, &:before {
                            background: #ffffff;
                        }
                    }
                    
                    &.plus:after {
                        transform: rotate(90deg);
                    }
                }
                
                .amount-count {
                    width: 40px;
                    text-align: center;
                }
            }
            
            .ticket-value {
                text-align: center;
                flex: 0 0 100px;
                
            }
        }
    }
    
    .activity-add-state {
        
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: #fff;
        padding: 25px;
        border-radius: 20px;
        box-shadow: 20px 20px 50px #f8f8f8;
        
        .add-activity-image {
            display: none;
            background: url("../../image/ui/activity@2x.png") no-repeat center;
            background-size: contain;
            width: 200px;
            height: 200px;
        }
        
        .add-activity-slogan {
            display: none;
            font-size: 15px;
            text-align: center;
            color: $font-color-light;
            margin-bottom: 40px;
            margin-top: 10px;
        }
        
        .btn {
            min-width: 100%;
        }
        
        @media (min-width: 1070px) {
            position: fixed;
            top: 30%;
            right: calc(50vw - 500px);
            width: 250px;
            
            .add-activity-slogan, .add-activity-image {
                display: block;
            }
            .btn {
                min-width: 180px;
            }
        }
        
        &.empty-state {
            position: inherit !important;
            height: 70vh !important;
            width: 100% !important;
            
            .add-activity-slogan, .add-activity-image {
                display: block;
            }
            
            .btn {
                min-width: 180px;
            }
        }
    }
    
    .form-participant {
        margin-top: 16px;
        border-top: 1px solid black;
        transition: all .3s ease;
        max-height: 650px;
        
        &.closed {
            overflow: hidden;
            max-height: 55px;
            transition: all .3s ease;
            
            .form-participant-head {
                .expand-arrow {
                    transform: rotate(180deg);
                }
            }
        }
        
        .form-participant-head {
            display: flex;
            flex-direction: row;
            font-family: $font-korolev;
            font-size: 16px;
            height: 55px;
            align-items: center;
            cursor: pointer;
            position: relative;
            padding-right: 50px;
            
            .validation-status {
                flex: 0 0 24px;
                height: 24px;
                border-radius: 12px;
                background-color: $yellow;
                position: relative;
                transition: all .3s ease;
                
                &:before, &:after {
                    transition: all .3s ease;
                    content: '';
                    display: block;
                    position: absolute;
                    height: 2px;
                    border-radius: 1px;
                    background-color: $font-color;
                    
                }
                
                &:before {
                    width: 8px;
                    transform: rotate(41deg);
                    top: 13px;
                    left: 5px;
                }
                
                &:after {
                    width: 11px;
                    transform: rotate(-53deg);
                    top: 11px;
                    left: 9px;
                }
                
                &.silent-invalid, &.invalid {
                    background-color: $font-color-light;
                    
                    &:before {
                        background-color: $font-color-light;
                        width: 10px;
                        transform: rotate(90deg);
                        top: 9px;
                        left: 7px;
                    }
                    
                    &:after {
                        background-color: $font-color-light;
                        width: 2px;
                        transform: rotate(90deg);
                        top: 18px;
                        left: 11px;
                    }
                }
                
                &.invalid {
                    background-color: $red;
                    
                    &:before, &:after {
                        background-color: #fff;
                    }
                }
            }
            
            .participant-number {
                font-weight: bold;
                margin-left: 10px;
                margin-top: 4px;
                flex: 0 0 auto;
            }
            
            .ticket-name {
                margin-left: 32px;
                margin-top: 4px;
                color: $font-color-light;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }
            
            .expand-arrow {
                position: absolute;
                transition: all .3s ease;
                top: 10px;
                right: 20px;
                width: 30px;
                height: 30px;
                
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 12px;
                    height: 12px;
                    top: 10px;
                    right: calc(50% - 6px);
                    border: 2px solid $font-color;
                    border-bottom: none;
                    border-right: none;
                    transform: rotate(45deg);
                }
            }
        }
        
        .participant-inputs {
            
            .copy-from-buyer {
                font-family: $font-korolev;
                color: $font-color-light;
                font-size: 13px;
                margin-bottom: 30px;
                margin-left: 32px;
                
                span {
                    text-decoration: underline;
                    color: $font-color;
                    margin-left: 10px;
                    cursor: pointer;
                }
                
            }
        }
    }
    
    .summary {
        h2 {
            font-size: 20px;
        }
    }
    
    .summary-item {
        display: flex;
        flex-direction: row;
        border-top: 1px solid $input-border;
        justify-content: space-between;
        align-items: center;
        padding: 6px 0;
        font-size: 14px;
        
        &.head-item {
            border: none;
            color: $font-color-light;
            padding: 4px 0;
            
            .summary-item-count {
                text-align: center;
                border-right: none;
                padding: 0;
            }
            
        }
        
        &.footer-item {
            border-top: 2px solid $font-color;
            font-weight: bold;
            font-family: $font-korolev;
            
            .summary-item-count {
                padding-right: 10px;
                flex: 0 0 100px;
            }
        }
        
        .summary-item-name {
            flex-grow: 1;
            font-family: $font-korolev;
            
            .name-bold {
                color: $font-color-dark;
                font-weight: bold;
            }
        }
        
        .summary-item-count {
            text-align: right;
            flex: 0 0 50px;
            border-right: 1px solid $input-border;
            padding-right: 20px;
            line-height: 35px;
        }
        
        .summary-item-value {
            padding-left: 20px;
            flex: 0 0 100px;
        }
    }
}
