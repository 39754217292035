.list-pagination {
    .pagination {
        margin: 60px 0;
        text-align: center;
        justify-content: center;
        
        .page-item {
            display: inline-block;
            margin: 0 12px;
            
            .page-link {
                height: 35px;
                width: 35px;
                line-height: 38px;
                border-radius: 50px;
                font-size: 20px;
                font-weight: 500;
                color: #FAE938;
                cursor: pointer;
                padding: 0 8px;
                border: none;
            }
            
            &.active {
                .page-link {
                    background-color: #FAE938;
                    color: #263238;
                    cursor: default;
                }
            }
            
            &:hover:not(.disabled) {
                .page-link {
                    background-color: #FAE938;
                    color: #263238;
                }
            }
            
            &:first-of-type, &:last-of-type {
                .page-link {
                    font-size: 0;
                    
                    &:before {
                        display: block;
                        content: '<';
                        height: 38px;
                        line-height: 38px;
                        font-size: 28px;
                        font-weight: 500;
                        color: #4a4a4a;
                    }
                }
                
                &:hover:not(.disabled) {
                    .page-link {
                        &:before {
                            color: #ffffff;
                        }
                    }
                }
                
                &:last-of-type {
                    .page-link {
                        &:before {
                            content: '>';
                        }
                    }
                }
            }
        }
    }
}
