$intend: 65px;

.review-nav {
    margin-right: 90px;
    
    a {
        padding: 0;
        display: flex;
        align-items: flex-start;
        font-size: 24px;
        line-height: 38px;
        font-family: $font-korolev;
        color: $font-color-light;
        
        .name {
            font-size: 36px;
            line-height: 38px;
            font-family: $font-korolev;
            color: $font-color-light;
            
        }
        
        &.active {
            .name {
                color: $font-color;
            }
        }
        
        .count {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin-left: 20px;
            width: 36px;
            height: 36px;
            background: $gray-light;
            border-radius: 18px;
            color: $font-color-light;
        }
    }
}

.review {
    margin-top: 60px;
    
    .review-summary {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        
        .review-avg-score {
            display: flex;
            flex-direction: row;
            align-items: baseline;
            
            .avg-score {
                font-size: 36px;
                font-weight: 500;
                font-family: $font-korolev;
            }
            
            .review-count {
                font-family: $font-korolev;
                margin-left: 8px;
                font-size: 24px;
                color: $font-color-light;
            }
        }
        
        .total-score {
            .review-score-stars {
                margin-bottom: 30px;
                
                .rate-star {
                    width: 35px;
                    height: 35px;
                }
            }
        }
        
        .detail-score {
            
            .review-score-stars {
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                
                .detail-label {
                    width: 120px;
                    font-size: 14px;
                    display: flex;
                }
                
                .rate-star {
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
    
    .review-create {
        position: relative;
        
        &.loading {
            .modal-dialog {
                &:before {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background: rgba(255, 255, 255, 0.5);
                    z-index: 1;
                }
                
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100px;
                    height: 100px;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    background: url("../../image/loader.gif") no-repeat center;
                    background-size: 100%;
                    z-index: 2;
                }
            }
        }
        
        .review-type {
            display: flex;
            flex-direction: row;
        }
        
        .review-type-label {
            width: 120px;
            font-size: 14px;
            display: flex;
        }
        
        .review-star {
            display: flex;
            flex-direction: row-reverse;
            position: relative;
            
            input:checked ~ label {
                background-image: url("../../image/icon/star-gold.svg");
            }
            
            label {
                cursor: pointer;
                display: inline-block;
                width: 30px;
                height: 30px;
                background: url("../../image/icon/star.svg") no-repeat center;
                background-size: 25px auto;
                padding: 2px;
                margin-bottom: 4px;
                
                &:hover, &:hover ~ label {
                    background-image: url("../../image/icon/star-gold.svg");
                }
                
            }
            
            &:hover {
                label {
                    background-image: url("../../image/icon/star.svg") !important;
                    
                    &:hover, &:hover ~ label {
                        background-image: url("../../image/icon/star-gold.svg") !important;
                    }
                }
            }
            
            input {
                opacity: 0;
                width: 30px;
                height: 30px;
                position: absolute;
                left: 0;
                bottom: 0;
            }
            
        }
        
        .review-comment-content {
            margin-top: 25px;
            
            textarea {
                width: 100%;
                min-height: 100px;
                max-height: 300px;
                height: 100px;
                font-size: 15px;
                padding: 20px 15px;
            }
        }
    }
    
    .review-popup {
        .user {
            display: flex;
            flex-direction: row;
            margin-bottom: 25px;
            
            .user-avatar {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                margin-right: 15px;
                background-size: cover;
                background-color: $font-color-light;
            }
            
            .user-name-label {
                display: block;
                font-size: 15px;
                color: #909090;
            }
            
            .user-name {
                font-size: 20px;
            }
        }
    }
    
    .review-item {
        border-top: 1px solid $border-color;
        padding-top: 50px;
        margin-top: 50px;
        
        &:first-of-type {
            border: none;
            padding-top: 0;
        }
        
        .review-meta {
            display: flex;
            flex-direction: row;
            
            .review-avatar {
                width: 50px;
                height: 50px;
                border-radius: 25px;
                overflow: hidden;
                margin-right: 15px;
                background-size: 100%;
                background-color: $font-color-light;
            }
            
            .review-info {
                flex-grow: 1;
                
                .review-user-name {
                    font-family: $font-korolev;
                    font-size: 20px;
                    font-weight: bold;
                }
                
                .review-date {
                    font-size: 14px;
                    color: $font-color-light;
                    line-height: 0.5;
                }
                
                .review-score {
                    margin-top: 15px;
                }
            }
        }
        
        .review-comment {
            padding-left: $intend;
            margin-top: 30px;
            font-size: 15px;
            line-height: 1.93;
        }
    }
    
}
