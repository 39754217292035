body.hide-footer {
    .footer {
        display: none !important;
    }
}

.footer {
    width: 100%;
    background-color: $footer-bg;
    margin-top: auto;
    color: #fff;
    padding: 30px 0;
    overflow: hidden;

    &__top {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .footer--logo {
            display: flex;
        }
    }

    &__middle {
        display: flex;
        margin: 20px -40px 40px -40px;
        flex-wrap: wrap;

        @media (max-width: $break-md) {
            margin: 40px -20px;
        }
    }

    &__bottom {
        padding-top: 35px;
        display: flex;
        align-items: center;
        border-top: 1px solid $footer-color;
        font-size: 14px;
        flex-wrap: wrap;

        a {
            color: #ffffff;
        }
    }

    .heading {
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;

        a {
            color: inherit !important;
            margin-bottom: 0;
        }
    }

    .list__link {
        margin: 0 40px;
        @media (max-width: $break-md) {
            margin: 0 20px;
        }

        a {
            display: block;
            color: $footer-color;
            margin-bottom: 20px;
            font-size:20px;
            text-decoration: none;
            font-weight: 300;
        }


        .term-link {
            font-size:16px;
        }
        address {
            opacity: 0.6;
        }
    }
}

.social-links {
    justify-content: space-around;
    display: flex;
    margin-top: 30px;
    line-height: 30px;
    @media (min-width: $break-sm) {
        margin-top: -3px;
    }
    @media (max-width: $break-sm) {
        width: 100%;
    }

    .social-link {
        display: inline-block;
        margin: 0 25px;
        width: 25px;
        height: 30px;
        background: transparent no-repeat center;
        background-size: 100%;

        @media (min-width: $break-md) {
            width: 15px;
            height: 20px;
            margin-left: 20px;
            margin-right: 0;
        }

        &.facebook {
            background-image: url('../../image/social/facebook.svg');
        }

        &.twitter {
            background-image: url('../../image/social/twitter.svg');
        }

        &.linkedin {
            background-image: url('../../image/social/linkedin.svg');
        }

        &.instagram {
            background-image: url('../../image/social/instagram.svg');
        }
    }
}

.link--mail {
    margin-left: auto;
}

.logo--main {
    width: 100%;
}

.copyright {
    font-weight: 500;
    color: $footer-color;
}

.awards {
    @media (max-width: $break-md) {
        width: 100%;
        text-align: center;
        margin-top: 40px;
    }

    img {
        width: 100px;
        &.payments {
            width: 355px;
            max-width: 100%;
            margin-right: 20px;
        }
    }
}

form[name='currency'] {
    position: relative;

    label,
    button {
        display: none;
    }

    select {
        margin-top: -5px;
        padding: 5px 50px 5px 8px;
        border: 1px solid #FAE938;
        color: $footer-color;
        background-color: $footer-bg;
        font-size: 14px;
        line-height: 1;
        height: 30px;
        border-radius: 9px;
    }

    // &:after {
    //     width: 18px;
    //     height: 18px;
    //     background: url('../../images/down-arrow.png') no-repeat center;
    //     background-size: contain;
    //     position: absolute;
    //     right: 12px;
    //     top: 50%;
    //     transform: translateY(-50%);
    //     -webkit-transform: translateY(-50%);
    // }

}

.currency-switcher {
    margin-top: -5px;
    padding: 5px 50px 5px 8px;
    border: 1px solid $footer-color;
    color: $footer-color;
    background-color: $footer-bg;
    font-size: 14px;
    line-height: 1;
    height: 30px;
}
