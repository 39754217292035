.event-rate {
    display: flex;
    align-items: center;
    
    .rate-star {
        font-size: 0;
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../../image/icon/star.svg") no-repeat left center;
        background-size: 100% auto;
        margin-right: 10px;
        
        .rate-star-gold {
            display: inline-block;
            width: 100%;
            height: 100%;
            background: url("../../image/icon/star-gold.svg") no-repeat left center;
            background-size: auto 100%;
            
        }
    }
    
    .rate-score {
        font-size: 14px;
    }
}

.rate-single {
    display: flex;
    align-items: center;
    justify-content: center;
    
    .star-gold {
        display: inline-block;
        width: 20px;
        height: 20px;
        background: url("../../image/icon/star-gold.svg") no-repeat left center;
        background-size: 20px;
        margin: 10px;
    }
}
