.bootstrap-datetimepicker-widget {
    .btn {
        min-width: 20px;
    }
    
    a[data-action] {
        i {
            background-color: #ededed;
        }
    }
}

.date-filter {
    &.open {
        .lightpick {
            display: inline-block;
        }
    }

    &.x {
        .lightpick {
            display: inline-block;
        }
    }
    
    .lightpick {
        box-shadow: none;
        color: white;
        font-family: $font-korolev;
        padding: 0;
        background-color: transparent;
        display: none;
        @media (max-width: $break-sm) {
            padding: 26px 30px;
            width: calc(100vw - 30px);
            .lightpick__month {
                width: 100%;
            }
        }
        
        .lightpick__inner {
            .lightpick__toolbar {
                position: relative;
                
                button {
                    position: absolute;
                    background-color: transparent;
                    display: block;
                    height: 33px;
                    width: 30px;
                    transform: rotate(0) !important;
                    border: 1px solid $font-color-light;
                    margin: 0;
                    border-radius: 4px;
                    font-size: 0;
                    z-index: 1;
                    outline: none;
                    cursor: pointer;
                    
                    &:before {
                        border: 1px solid white;
                        border-width: 0 2px 2px 0;
                        position: absolute;
                        content: '';
                        height: 10px;
                        width: 10px;
                        top: 9px;
                        left: 10px;
                    }
                    
                    &.lightpick__previous-action {
                        left: 0;
                        
                        &:before {
                            top: 10px;
                            left: 11px;
                            transform: rotate(135deg);
                        }
                    }
                    
                    &.lightpick__next-action {
                        right: 0;
                        
                        &:before {
                            top: 10px;
                            left: 7px;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
            
            .lightpick__months {
                background: transparent;
                grid-gap: 15px;
                
                .lightpick__month {
                    background-color: transparent;
                }
                
                .lightpick__toolbar {
                    position: absolute;
                    width: 100%;
                    top: 0;
                }
                
                .lightpick__month {
                    .lightpick__month-title-bar {
                        padding-bottom: 12px;
                        position: relative;
                        
                        .lightpick__month-title {
                            margin: 0 auto;
                            padding: 0;
                            
                            .lightpick__select {
                                font-size: 18px;
                                font-weight: bold;
                                color: white;
                                font-family: $font-korolev;
                                padding: 0;
                                margin: 0 5px 0 0;
                                background-image: none;
                                text-transform: capitalize;
                                height: 30px;
                            }
                        }
                    }
                    
                    .lightpick__days-of-the-week {
                        .lightpick__day-of-the-week {
                            text-transform: capitalize;
                            color: $font-color-light;
                            padding: 8px 0;
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                    
                    .lightpick__days {
                        .lightpick__day {
                            background: transparent;
                            border-radius: 0;
                            height: 32px;
                            
                            &.is-in-range {
                                background-color: rgba($yellow, 0.3);
                                color: white;
                            }
                            
                            &.is-start-date, &.is-end-date {
                                background-color: $yellow;
                                color: $font-color;
                            }
                            
                            &.is-start-date {
                                border-radius: 4px 0 0 4px;
                                
                                &.is-flipped {
                                    border-radius: 0 4px 4px 0;
                                }
                            }
                            
                            &.is-end-date {
                                border-radius: 0 4px 4px 0;
                                
                                &.is-flipped {
                                    border-radius: 4px 0 0 4px;
                                    
                                }
                            }
                            
                            &.is-start-date.is-end-date {
                                border-radius: 4px;
                            }
                            
                            &.is-today {
                                color: $yellow;
                            }
                            
                        }
                    }
                }
            }
            
            .lightpick__tooltip {
                display: none;
            }
            
            .lightpick__footer {
                margin-top: 10px;
                justify-content: flex-end;
                
                .lightpick__reset-action {
                    display: none;
                }
                
                .btn {
                    color: #ffffff;
                    border: 1px solid #909090;
                    @media (max-width: $break-md) {
                        min-width: 20px;
                    }
                }
            }
        }
    }
    
}

.flatpickr-calendar {
    border-radius: 3px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    padding: 30px 15px 0;
    width: 310px;
    
    &:before, &:after {
        display: none;
    }
    
    .flatpickr-months {
        position: relative;
        
        .flatpickr-prev-month, .flatpickr-next-month {
            width: 30px;
            height: 33px;
            border-radius: 3px;
            border: solid 1px $border-color;
            padding: 6px;
            line-height: 19px;
            
            &:hover {
                svg {
                    fill: $font-color;
                }
            }
        }
        
    }
    
    .flatpickr-month {
        height: 35px;
        
        .flatpickr-current-month {
            padding: 0;
            
            .cur-month {
                font-family: $font-korolev;
                font-size: 18px;
                font-weight: 500;
            }
            
            .numInputWrapper {
                input {
                    font-family: $font-korolev;
                    font-size: 18px;
                    font-weight: 500;
                    background: white !important;
                    border: none !important;
                    outline: none !important;
                    padding: 10px 5px;
                }
            }
        }
    }
    
    .flatpickr-innerContainer {
        margin-top: 15px;
        
        .flatpickr-rContainer {
            width: 100%;
            
            .flatpickr-weekdays {
                .flatpickr-weekdaycontainer {
                    .flatpickr-weekday {
                        width: 40px;
                        color: $font-color-light;
                        font-size: 12px;
                        font-weight: 500;
                        
                    }
                }
            }
            
            .flatpickr-days {
                width: 280px;
                
                .dayContainer {
                    width: 280px;
                    max-width: 280px;
                    min-width: 280px;
                    justify-content: flex-start;
                    
                    .flatpickr-day {
                        
                        font-size: 12px;
                        font-weight: 500;
                        color: $font-color;
                        border: none;
                        width: 40px;
                        max-width: 40px;
                        height: 32px;
                        line-height: 32px;
                        position: relative;
                        display: inline-block;
                        
                        &:before {
                            content: '';
                            background-color: transparent;
                            height: 22px;
                            width: 26px;
                            position: absolute;
                            z-index: -1;
                            left: 6px;
                            top: 5px;
                            border-radius: 4px;
                        }
                        
                        &.prevMonthDay {
                            opacity: 0;
                            pointer-events: none;
                        }
                        
                        &.nextMonthDay {
                            display: none;
                        }
                        
                        &:hover {
                            background: transparent;
                            color: $font-color;
                            
                            &:before {
                                background-color: $gray-light;
                            }
                        }
                        
                        &.selected {
                            background: transparent;
                            color: #fff;
                            
                            &:before {
                                background-color: $dark;
                            }
                        }
                        
                        &.flatpickr-disabled {
                            opacity: 0.2;
                        }
                    }
                }
            }
        }
    }
    
    .flatpickr-time {
        margin-top: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        padding-left: 30px;
        background: url("../../image/icon/time.svg") no-repeat left center;
        max-height: 100px;
        height: 90px !important;
        
        .numInputWrapper {
            height: 50px;
            width: 75px;
            
            input {
                
                font-family: $font-poppins;
                border-radius: 4px;
                background-color: $input-bg;
                font-size: 15px;
                color: $font-color;
                height: 50px;
                padding: 13px 10px;
                border: none;
                box-shadow: none;
                outline: none;
                width: 75px;
            }
        }
        
        .flatpickr-time-separator {
            width: 15px;
            height: 40px;
        }
        
        .flatpickr-am-pm {
            float: right;
            font-family: $font-poppins;
            border-radius: 4px;
            background-color: $input-bg;
            font-size: 15px;
            color: $font-color;
            height: 50px;
            line-height: 50px;
            width: 75px;
            margin-left: 13px;
        }
    }
    
    .flatpickr-confirm {
        float: right;
        margin-bottom: 10px;
        
        @extend .btn;
        @extend .btn--dark;
        @extend .btn--small;
    }
}

.date-time-picker {
    position: relative;
    color: $font-color-light;
    font-size: 15px;
    border-radius: 4px;
    border: 1px solid $input-border;
    
    &.date-filled {
        color: $font-color;
    }
    
    .date-time-picker-date {
        font-size: 13px;
        width: 100%;
        height: 48px;
        line-height: 49px;
        float: left;
        border-radius: 4px 0 0 4px;
        padding-left: 26px;
        background: $input-bg url("../../image/icon/calendar.svg") no-repeat left 6px center;
        position: relative;
        background-size: 15px;
        
    }
    
    .date-time-picker-time {
        font-size: 13px;
        width: 100%;
        height: 48px;
        line-height: 49px;
        float: left;
        border-radius: 0 4px 4px 0;
        padding-left: 29px;
        background: $input-bg url("../../image/icon/time.svg") no-repeat left 8px center;
        background-size: 17px;
    }
    
    .react-datepicker-wrapper,
    input {
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: none;
    }
}
