$yellow: #f9ea29;
$yellow-light: #fbf27f;
$gray: #f1f1f1;
$dark: #1b1b1b;
$orange: #ff9d00;
$gray-light: #faf9f9;
$gray: #dbdbdb;
$red: #f12323;
$green: #16a85a;
$white: #fff;

$mine-shaft: #242424;
$concrete: #f3f3f3;
$alto: #d7d7d7;

$border: #e5e5e5;

//font colors
// $font-color: #4a4a4a;
$font-color: #000000;
$font-color-dark: $dark;
$font-color-light: #909090;

//footer
$footer-bg: $dark;
$footer-color: $font-color-light;

// search
$search-bg: #000000;
$search-border: #454545;
$search-color: #828282;

// BUTTONS
// --yellow
$btn-bg-yellow: $yellow;
$btn-color-yellow: $font-color;
$btn-bg-yellow-hover: $yellow-light;
$btn-color-yellow-hover: $font-color;

// --main
$btn-main-bg: transparent;
$btn-main-color: $font-color;
$btn-main-bg-hover: transparent;
$btn-main-color-hover: $font-color-light;

// --dark
$btn-bg-dark: $dark;
$btn-color-dark: #fff;
$btn-bg-dark-hover: $font-color;
$btn-color-dark-hover: #fff;

// --white
$btn-bg-white: #ffffff;
$btn-color-white: $font-color;
$btn-bg-white-hover: #f9ea29;
$btn-color-white-hover: $font-color;

// --gray
$btn-bg-gray: $gray-light;
$btn-color-gray: $font-color-light;
$btn-bg-gray-hover: $gray;
$btn-color-gray-hover: $font-color-light;

// --trans
$btn-bg-trans: transparent;
$btn-color-trans: $font-color;
$btn-bg-trans-hover: $gray-light;
$btn-color-trans-hover: $font-color;

// --icon
$btn-bg-icon: transparent;
$btn-color-icon: $font-color;
$btn-bg-icon-hover: transparent;
$btn-color-icon-hover: $font-color;

// --cancel
$btn-bg-cancel: transparent;
$btn-color-cancel: $font-color-dark;
$btn-border-cancel: $font-color-dark;
$btn-bg-cancel-hover: transparent;
$btn-color-cancel-hover: $font-color-light;
$btn-border-cancel-hover: $font-color-light;

// nav
$nav-bg: $dark;
$nav-color: #fff;
$nav-color-hover: $font-color;

$profile-link-bg: #fff;
$profile-link-color: $font-color;

//event page
$price-box-bg: $gray-light;
$price-box-bg-hover: $yellow;

//input
$input-bg: $gray-light;
$input-border: $border;

$border-color: $border;
