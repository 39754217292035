
.b4 {
    z-index: 99999999999;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    
    .row > div > div {
        
        height: 100vh;
        background: rgba(175, 167, 175, 0.55);
    }
}

.switch {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2147483647 !important
}
