.upgrade-account-modal {
  .modal-dialog {
    width: 402px;
  }

  .modal-body {
    margin-top: 10px;

    .upgrade-account-title {
      font-size: 22px;
      font-weight: bold;
    }

    .upgrade-account-description {
      font-size: 14px;
      color: $font-color-light;
    }
  }

  .modal-header {
    position: absolute;
    right: 0;
    z-index: 1000;
  }
}
