.slider.slider-for {
    
    height: 500px;
    overflow: hidden;
    background: #e4e4e4 url("/static/loader.gif") no-repeat center;
    
    &.slick-initialized {
        height: auto;
        background: none;
        
        .slick-wrapper {
            opacity: 1;
        }
    }
    
    .slick-wrapper {
        position: relative;
        padding-bottom: 60%;
        opacity: 0;
        
        .slick-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #000;
            
        }
        
        img {
            max-height: 100%;
            max-width: 100%;
            height: auto;
        }
        
    }
}

.slider.slider-nav {
    margin-top: 10px;
    
    max-height: 80px;
    
    img {
        max-height: 80px;
        
    }
    
    .slick-slide {
        max-width: 110px;
        height: 80px;
        margin-right: 10px;
        cursor: pointer;
        
        & > div {
            max-width: 100%;
            height: 80px;
            line-height: 80px;
            
            img {
                max-width: 100%;
                max-height: 100%;
                opacity: 0.3;
                transition: opacity .3s ease;
            }
        }
        
        &.slick-current, &:hover {
            & > div {
                img {
                    opacity: 1;
                    vertical-align: middle;
                }
            }
        }
    }
    
}

.lb-cancel {
    width: 100px;
    height: 100px;
    background: url('../../image/loader.gif') no-repeat;
}
