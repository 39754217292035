
.select2-container {
    outline: none;
    
    .select2-selection--single {
        background-color: $input-bg;
        border: 1px solid $input-border;
        border-radius: 4px;
        height: 48px;
        outline: none;
        
        .select2-selection__rendered {
            padding: 10px 30px 10px 18px;
        }
        
        .select2-selection__arrow b {
            display: block;
            content: '';
            width: 8px;
            height: 8px;
            position: absolute;
            right: 16px;
            top: 20px;
            left: auto;
            border: 2px solid currentColor;
            border-top: none;
            border-left: none;
            transform: rotate(45deg);
        }
    }
    
    &.select2-container--open .select2-selection--single .select2-selection__arrow b {
        transform: rotate(225deg);
        border: 2px solid currentColor;
        border-top: none;
        border-left: none;
    }
    
    .select2-dropdown {
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        border: 1px solid $input-border;
        
        .select2-search--dropdown {
            padding: 10px;
            border-bottom: 1px solid $input-bg;
            
            .select2-search__field {
                font-size: 20px;
                padding: 11px 20px 11px 20px;
                border: none;
                outline: none;
                background: $input-bg;
                border-radius: 4px;
            }
        }
        
        .select2-results {
            padding: 10px 0;
            font-size: 16px;
            
            .select2-results__options {
                max-height: 270px;
                
                .select2-results__option {
                    padding: 10px 20px 10px 20px;
                    color: #808080;
                    
                    &[aria-selected=true] {
                        background-color: #fff;
                        color: #808080;
                    }
                    
                    &.select2-results__option--highlighted {
                        background-color: $input-bg;
                        color: $font-color;
                    }
                }
            }
        }
        
        &.input-mode {
            margin-top: -50px;
            
            .select2-search--dropdown {
                padding: 0;
                border-bottom: none;
                
                .select2-search__field {
                    font-size: 20px;
                    padding: 11px 20px 11px 20px;
                    border: none;
                    outline: none;
                    background: $input-bg;
                    border-radius: 4px;
                }
            }
        }
        
        .toplayer {
            z-index: 99999999;
        }
    }
}

.sport-type-select-container,
.location-select-container {
    font-family: $font-poppins;
    position: fixed;
    border: none;
    z-index: 20;
    
    .select2-container {
        
        .select2-dropdown {
            width: 380px !important;
            max-height: 360px;
            
            @media (max-width: $break-lg) {
                width: 9999px !important;
                max-width: calc(100vw - 30px);
            }
            
            .select2-search__field {
                font-size: 20px;
                padding: 11px 20px 11px 50px;
                border: none;
                outline: none;
                border-radius: 4px;
                background: $input-bg url("../../image/icon/search.svg") no-repeat left 20px center;
            }
        }
        
        .select2-results {
            .select2-results__options {
                .select2-results__option {
                    position: relative;
                    padding: 10px 10px 10px 60px;
                    
                    &:before {
                        position: absolute;
                        display: block;
                        content: '';
                        width: 10px;
                        height: 10px;
                        left: 30px;
                        top: 17px;
                        border-radius: 50%;
                        border: 3px solid #9b9b9b;
                    }
                    
                }
            }
        }
    }
}

.location-select-container {
    .select2-container {
        .select2-results {
            .select2-results__options {
                .select2-results__option {
                    &:first-of-type {
                        padding: 10px 10px 15px 60px;
                        border-bottom: 1px solid $input-bg;
                    }
                }
            }
        }
    }
}

.select-multiple {
    .select2 {
        width: 100% !important;
        
        .select2-selection {
            border: none;
            
            .select2-selection__rendered {
                padding: 0;
                
                .select2-selection__choice {
                    font-family: "Poppins", sans-serif;
                    color: #909090;
                    background-color: #faf9f9;
                    font-size: 15px;
                    padding: 12px 20px 11px 30px;
                    text-align: left;
                    position: relative;
                    font-weight: normal;
                    cursor: default !important;
                    border: none;
                    display: block;
                    direction: rtl;
                    
                    .select2-selection__choice__remove {
                        padding-left: 30px;
                        font-size: 24px;
                        line-height: 20px;
                        vertical-align: middle;
                        
                    }
                }
                
                .select2-search {
                    display: block;
                    width: 300px !important;
                    margin-top: 20px;
                    margin-left: 5px;
                    border-radius: 4px;
                    padding: 10px;
                    border: solid 1px #ededed;
                    clear: both;
                    
                    input {
                        width: 100% !important;
                        
                        font-family: $font-poppins;
                        border-radius: 4px;
                        background-color: $input-bg;
                        font-size: 15px;
                        color: $font-color;
                        height: 50px;
                        padding: 13px 30px;
                    }
                }
            }
        }
        
        &.select2-container--open {
            .select2-selection {
                .select2-selection__rendered {
                    .select2-search {
                        border: none;
                        box-shadow: 0 -4px 4px 0 rgba(0, 0, 0, 0.1);
                        border-radius: 4px 4px 0 0;
                    }
                }
            }
        }
    }
    
    .multi-select-container {
        position: relative;
        margin-left: 5px;
        margin-top: -9px;
        
        .select2-container {
            top: 0 !important;
            left: 0 !important;
            
            .select2-dropdown {
                border-top: solid 1px #ededed;
                width: 300px !important;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                
            }
        }
    }
}

.select-categories {
    .selected-categories {
        .btn {
            margin: 0 5px 10px 0;
            min-width: 20px;
            
            .text {
                display: none;
            }
        }
        
        div {
            &:first-of-type {
                &.add-new-category {
                    min-width: 200px;
                    padding-left: 0 !important;
                    
                    .text {
                        display: inline-block;
                    }
                }
            }
        }
    }
    
    .select-category {
        position: relative;
        
        .select2, select {
            position: absolute;
            top: 0;
            opacity: 0;
        }
        
        .select-category-dropdown {
            position: absolute;
            top: 0;
            
            .select2-container {
                top: 0 !important;
            }
        }
    }
}
