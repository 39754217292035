@each $name, $break-point in $grid-breakpoints {
    @media (max-width: $break-point - 1px) {
        .list-scrollable-#{$name} {
            overflow-x: auto;
            flex-wrap: nowrap;
            display: flex;
            
            &::-webkit-scrollbar {
                display: none;
            }
            
            & > * {
                min-width: 350px;
                max-width: 450px;
                padding-right: 7px;
                padding-left: 7px;
                
                &:first-of-type {
                    margin-left: 7px;
                }
                
                &:last-of-type {
                    margin-right: 7px;
                }
                
                &.col-city {
                    max-width: 360px;
                }
            }
            
            &.event-list {
                @media (min-width: $break-md) {
                    margin-right: -50px;
                    margin-left: -50px;
                }
            }
            
        }
    }
}

.auto-resize-textarea {
    textarea {
        min-height: 100px;
        max-height: 300px;
        transition: height .3s ease;
    }
}
