.location-modal {
    .modal-dialog {
        max-width: 750px;
        
        .select2 {
            width: 100% !important;
        }
        
        .map-popup-wrapper {
            margin-top: 30px;
            position: relative;
            padding-bottom: 100%;
            
            .map-popup-container {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                
                canvas {
                    display: block !important;
                }
                
                .map-marker {
                    margin-top: -15px;
                    margin-left: -15px;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    border: 12px solid $yellow;
                    background: black;
                }
            }
        }
        
        .confirm-btn {
            text-align: right;
            margin-top: 15px;
        }
    }
}
