.event-list-search {
    overflow: hidden;
    // padding: 60px 0 15px;
    background-color: #faf9f9;
    border-radius: 50px;
    box-shadow: 0px 4px 33.8px 4px #00000040;
    
    input {
        border: 0;
        width: calc((100% - 100px) / 4);
        padding: 20px;
        font-size: 18px;
        height: 100px;
    }
    form {
        margin-bottom: 15px;
        float: left;
        max-width: 100%;
        width: 100%;
        
        @media (min-width: $break-sm) {
            max-width: 50%;
        }
    }
    
    .order-select {
        width: 100%;
        float: right;
        padding-right: 35px;
        
        @media (min-width: $break-sm) {
            width: auto;
        }
    }

    button {
        width: 100px;
        height: 100px;
        background: #F7EA00 url('../../images/search.svg') no-repeat center;
        background-size: 35px;
        border: 0;
        border-radius: 50%;
    }
}
