.event-ticket-link {
    text-decoration: none;
    
    &:hover, &:active {
        text-decoration: none;
    }
}

.event-ticket {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    border-radius: 4px;
    background-color: $gray-light;
    padding: 15px 20px;
    min-height: 120px;
    transition: all .3s ease;
    
    &:hover {
        background-color: $yellow;
    }
    
    h3 {
        margin: 0;
        min-width: 120px;
    }
    
    .ticket-top-line {
        .price {
            font-size: 24px;
            font-weight: 500;
            font-family: $font-korolev;
        }
        
        .date-label {
            letter-spacing: 0.34px;
        }
    }
    
    .ticket-bottom-line {
        font-size: 15px;
        font-weight: normal;
        
        .date-label {
            color: $font-color;
            font-weight: 500;
            letter-spacing: 0.34px;
        }
        
        .date-value {
            color: $font-color-light;
        }
    }
    
    &.ticket-past, &.ticket-future {
        
        .ticket-top-line {
            position: relative;
            overflow: hidden;
            
            .price {
                transition: all .3s ease;
                color: rgba($font-color, .4);
            }
            
            .date-label {
                transition: all .3s ease;
                right: 0;
                top: 0;
                opacity: 0;
                position: absolute;
                transform: translate(0, 100%);
            }
        }
        
        .ticket-bottom-line {
            font-size: 15px;
            font-weight: normal;
            
            .date-label {
                transition: all .3s ease;
            }
            
            .date-value {
                transition: all .3s ease;
            }
        }
        
        &:hover {
            
            .ticket-top-line {
                
                .price {
                    transform: translate(0, -100%,);
                    opacity: 0;
                }
                
                .date-label {
                    opacity: 1;
                    font-size: 24px;
                    transform: translate(0, 0);
                }
            }
            
            .ticket-bottom-line {
                
                .date-label {
                    opacity: 0;
                }
                
                .date-value {
                    font-size: 24px;
                    color: $font-color;
                }
            }
        }
    }
}
