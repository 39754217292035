.tooltip {
    z-index: 1020;
    
    &.show {
        opacity: 1;
    }
    
    &.bs-tooltip-top, &.bs-tooltip-auto[x-placement^="top"] {
        .arrow {
            &:before {
                border-top-color: white;
            }
        }
    }
    
    &.bs-tooltip-bottom, &.bs-tooltip-auto[x-placement^="bottom"] {
        .arrow {
            &:before {
                border-bottom-color: white;
            }
        }
    }
    
    .tooltip-inner {
        font-family: $font-poppins;
        padding: 16px;
        max-width: 180px;
        border-radius: 3px;
        box-shadow: 0 0 24px 0 #00000017;
        background-color: #ffffff;
        font-size: 10px;
        line-height: 1.9;
        cursor: default;
        text-transform: none;
        color: $font-color;
        text-align: left;
        
        a {
            display: block;
            text-align: right;
            font-family: $font-korolev;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 0.25px;
            color: #4a4a4a;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
