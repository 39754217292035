$header-height: 100px;
$header-md-height: 150px;

.activities-popup {
    
    .modal-dialog {
        width: 100vw !important;
        max-width: 100vw !important;
        margin: 0 0 0;
        
        .modal-content {
            padding: 0;
            border: none;
            border-radius: 0;
            background-color: #ffffff;
            
            .modal-header {
                height: $header-height;
                padding: 30px 15px 35px;
                @media (min-width: $break-md) {
                    padding: 70px 35px 35px;
                    height: $header-md-height;
                }
                @media (min-width: $break-lg) {
                    padding: 70px 75px 35px;
                }
            }
            
            .modal-body {
                overflow: auto;
                text-align: center;
                padding: 35px 7px 50px;
                max-height: calc(100vh - 100px);
                @media (min-width: $break-md) {
                    max-height: calc(100vh - 150px);
                    padding: 35px 35px 50px;
                }
                @media (min-width: $break-lg) {
                    padding: 35px 75px 50px;
                }
                
            }
        }
    }
    
    .activity-block {
        float: left;
        padding: 50px 6px 0;
        width: 50%;
        
        @media (min-width: $break-md) {
            width: 33.3%;
            padding: 50px 13px 0;
        }
        @media (min-width: $break-lg) {
            width: 25%;
        }
        
        @media (min-width: $break-xl) {
            width: 20%;
        }
        
        a {
            display: inline-block;
            position: relative;
        }
        
        .activity-box {
            display: inline-block;
            position: relative;
            width: 100%;
            
            .activity-cover {
                position: relative;
                overflow: hidden;
                border-radius: 4px;
                
                .activity-layer {
                    position: absolute;
                    border-radius: 4px;
                    overflow: hidden;
                    transition: opacity .3s ease;
                    opacity: 0;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.7);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    
                    span {
                        line-height: 60px;
                        font-size: 60px;
                        font-weight: 500;
                        letter-spacing: 4.6px;
                        color: #f8e71c;
                        text-align: center;
                    }
                }
            }
            
            &:hover {
                .activity-layer {
                    opacity: 1;
                }
            }
            
            .activity-name {
                text-align: left;
                font-size: 14px;
                font-weight: 500;
                font-family: $font-korolev;
                margin-top: 15px;
               
            }
        }
    }
}


