.login-box {
    max-width: 400px;
    margin: auto;
}

.login-modal, .login-box {
    font-family: $font-korolev;
    
    a {
        text-decoration: underline;
        cursor: pointer;
    }
    
    .form-group {
        ul {
            background: url("../../image/icon/error.svg") no-repeat left top;
            list-style: none;
            padding-left: 25px;
            
            li {
                color: $red;
                font-size: 14px;
                font-family: $font-poppins;
                line-height: 1.8;
            }
        }
        
        ul ~ input {
            border: 2px solid $red;
        }
    }
    
    .account-type {
        input {
            display: none;
        }
        
        label {
            width: 49%;
            background: $gray-light no-repeat;
            background-position: left 20px center;
            color: $font-color;
            font-size: 16px;
            font-weight: bold;
            letter-spacing: 0.3px;
            outline: none;
            border: none;
            border-radius: 4px;
            padding: 15px 20px 11px;
            text-align: center;
            cursor: pointer;
            
            &:first-of-type {
                background-image: url("../../image/icon/business.svg");
            }
            
            &:last-of-type {
                float: right;
                background-image: url("../../image/icon/personal.svg");
            }
        }
        
        input {
            &:checked {
                &:first-of-type ~ label:first-of-type {
                    color: #fff;
                    background-color: $dark;
                    background-image: url("../../image/icon/business-black.svg");
                }
                
                &:last-of-type ~ label:last-of-type {
                    color: #fff;
                    background-color: $dark;
                    background-image: url("../../image/icon/personal-black.svg");
                }
            }
        }
    }
    
    .forgot-password {
        text-align: right;
        font-size: 14px;
        font-weight: 500;
        text-decoration: underline;
        padding-bottom: 31px;
        margin-top: -8px;
    }
    
    .login-or {
        position: relative;
        padding-top: 50px;
        padding-bottom: 50px;
        
        .login-or-text {
            position: absolute;
            display: block;
            width: 150px;
            text-align: center;
            left: 50%;
            transform: translate(-50%, 0);
            top: 40px;
            background-color: white;
        }
    }
    
    .btn--facebook,
    .btn--google {
        color: #fff;
        font-size: 16px;
        font-family: $font-poppins;
        font-weight: normal;
        margin-left: 0;
    }
    
    .btn--google {
        background: #3b5ea7 url("../../image/social/google.svg") no-repeat left 6px center;
        background-size: 36px;
    }
    
    .btn--facebook {
        margin-top: 15px;
        background: #3b5ea7 url("../../image/social/facebook.svg") no-repeat left 9px center;
        background-size: 30px;
    }
    
    .login-privacy {
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        margin-top: 40px;
    }
    
    .login-footer {
        text-align: center;
        font-size: 15px;
        font-weight: 500;
        margin-top: 45px;
        padding-bottom: 30px;
        
        .login-q {
            margin-top: 40px;
            color: $font-color-light;
            margin-bottom: -5px;
        }
    }
}
