
.event {
    .col-pad {
        @media (min-width: $break-lg) {
            padding-left: 45px;
        }
    }
    
    hr.sep {
        margin: 60px 0;
    }
    
    .event--info {
        @media (min-width: $break-lg) {
            position: sticky;
        }
        
        @media (min-width: $break-lg) and (max-height: 1300px) {
            position: sticky;
        }
        
        hr {
            margin: 30px 0;
            @media (min-width: $break-lg) and (max-height: 650px) {
                margin: 20px 0;
            }
        }
        
        .event--info_block {
            .event--info_heading {
                line-height: 1;
                margin: 0;
            }
            
            .event--info_body {
                font-size: 18px;
                
                .link {
                    display: block;
                    font-family: $font-korolev;
                    text-decoration: underline;
                    letter-spacing: 0.3px;
                    font-size: 18px;
                    margin-top: 30px;
                    font-weight: 500;
                }
            }
            
            .buy-button-wrapper {
                margin-top: 90px;
                height: 48px;
                overflow: hidden;
                @media (min-width: $break-lg) and (max-height: 650px) {
                    margin-top: 20px;
                }
                
            }
            
            .btn-buy {
                @media (max-width: $break-lg) {
                    margin: 0;
                    position: fixed;
                    bottom: 25px;
                    left: 50%;
                    transform: translate(-50%, 0);
                    z-index: 999;
                }
            }
        }
    }
    
    .event--title {
       
        margin: 13px 0;
        line-height: 1.1;
    }
    
    .event--action {
        margin: 20px 0;
        
        .btn {
            margin-right: 20px;
        }
    }
    
    .event--cover {
        margin-top: 30px;
        
        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
    
    .event--blocks {
        margin-top: 48px;

        .event--block {
            .block-content-wrapper {
                max-height: 9990px;
                margin-top: 17px;
                overflow: hidden;
                margin-bottom: 46px;
                position: relative;
                transition: all .3s ease;
                
                p {
                    font-size: 15px;
                    margin: 0;
                    line-height: 29px;
                    letter-spacing: 0;
                    white-space: pre-line;
                }
                
                .content-blur {
                    position: absolute;
                    height: 40px;
                    bottom: 0;
                    background-image: linear-gradient(rgba(#fff, 0.01), white);
                    width: 100%;
                    display: none;
                }
            }
            
            .read-more {
                display: none;
                font-family: $font-korolev;
                text-decoration: underline;
                cursor: pointer;
                font-weight: 500;
                font-size: 18px;
                text-align: center;
                margin-top: 20px;
                margin-bottom: 46px;
            }
            
            &.close-block {
                .block-content-wrapper {
                    max-height: 170px !important;
                    margin-bottom: 0;
                    box-shadow: inset 0px -34px 63px 0px rgba(255, 255, 255, 1);
                }
                
                .content-blur {
                    display: block;
                }
                
                .read-more {
                    display: block;
                }
            }
        }
    }
    
    .event-list {
        margin-top: 150px;
        
        .event-list-title {
            hr {
                margin-top: 35px;
                margin-left: 80px;
                margin-right: 50px;
            }
        }
        
        .event-list-block {
            margin-top: 30px;
        }
    }
    
    .event--past {
        padding: 40px;
        background-color: $gray-light;
        text-align: center;
        box-shadow: 0px 1px 1px 4px #00000009;
        
        h3 {
            text-align: center;
            margin: auto;
            max-width: 500px;
        }
    }
    
    .event--activity {
        margin-top: 60px;
        padding-bottom: 60px;

        .activity-name {
            font-size: 36px;
        }

        .activity-date {
            margin-top: 20px;
            font-size: 15px;
            color: $font-color-light;
            padding-left: 30px;
            background: url("../../image/icon/calendar.svg") no-repeat left top;
        }

        .activity-location {
            margin: 15px 0 35px;
            font-size: 15px;
            color: $font-color-light;
            padding-left: 30px;
            background: url("../../image/icon/location.svg") no-repeat left top;
        }

        .activity-popup-location-name {
            display: none;
        }
    }
    
    .event--map {
        top: 0;
        bottom: 0;
        width: 100%;
        height: calc(100vh - 170px);

        @media (min-width: $break-lg) {
            position: sticky;
            top: 125px;
        }

        @media (max-width: $break-lg) {
            height: 500px;
        }
    }
}

.hide-last {
    @media (min-width: $break-xl) {
        & > div:nth-last-of-type(4) {
            display: none;
        }
    }
}

.results-count {
    margin: 20px 0 -40px;
    font-size: 12px;
    font-family: $font-korolev;
}


.event-header {
    background-image: url('../../../public/images/blog.svg');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
}