.about-us {
    header.about-us-header {
        position: relative;
        padding-bottom: 26%;
        background: url("../../image/bg/header_about.jpg") no-repeat center top;
        background-size: cover;
        min-height: 957px;
        
        @media (min-width: $break-xl) {
            background-image: url("../../image/bg/header_about@2x1.svg");
        }
        
        .layer {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
        }
    }
    
    .container-max-width {
        max-width: 1000px;
    }
    
    .wide-box {
        max-width: 600px;
        position: relative;
        top: 50px;

        .title {
            font-size: 27px;
            font-weight: bold;
            margin-bottom: 30px;
        }
        
        p {
            font-size: 16px;
            font-weight: normal;
            color: #67748E;
        }
        
        .btn {
            margin-top: 40px;
        }
    }
    
    .section-sep {
        margin-top: 100px;
    }

    .item-atv {
        box-shadow: 0px 20px 27px 0px #0000000D;
        background-color: #ffffff;
        border-radius: 16px;
        display: grid;
        grid-template-columns: 120px auto;
        align-items: center;
        padding: 10px;
        min-height: 175px;
        margin-top: 30px;
        max-width: 400px;
        width: 100%;
        
        .icon {
            height: 110px;
            background-repeat: no-repeat;
            background-size: contain;
            
            &.icon-search {
                background-image: url("../../image/vendor/search@2x.png");
            }
            
            &.icon-inspired {
                background-image: url("../../image/vendor/inspired@2x.png");
            }
            
            &.icon-speaker {
                background-image: url("../../image/vendor/speaker@2x.png");
            }
        }
        
        .item-title {
            margin-top: 20px;
            font-size: 22px;
            font-weight: bold;
            text-align: center;
            @media (min-width: 768px) {
                text-align: left;
            }
        }
        
        p {
            margin-top: 15px;
            color: #595959;
            text-align: center;
            @media (min-width: 768px) {
                text-align: left;
            }
        }
    }
    
    
    .flex-md-half {
        display: flex;
        width: 100%;
        overflow: hidden;
        @media (min-width: $break-md) {
            width: 50%;
        }
    }
    
    .part-bg {
        width: 100%;
        min-height: 400px;
        padding-bottom: 90%;
        background: no-repeat center;
        background-size: cover;
        
        @media (min-width: $break-xl) {
            padding-bottom: 500px;
        }
        
        &.part-2-bg {
            background-image: url("../../image/vendor/about-events.png");
            background-size: contain;
        }
        &.part-3-bg {
            background-image: url("../../image/vendor/about-drivesuccess.png");
            background-size: contain;
            // @media (min-width: $break-xl) {
            //     background-image: url("../../image/vendor/bg1@2x.jpg");
            // }
        }
        
        &.part-4-bg {
            background-image: url("../../image/vendor/bg2.png");
            background-size: contain;
            // @media (min-width: $break-xl) {
            //     background-image: url("../../image/vendor/bg2@2x.png");
            // }
        }
    }
    
    .info-box {
        /*padding: 60px;*/
    }

    .active-bg {
        position:relative;
        margin-top:120px; 
        margin-bottom:190px;
        padding: 134px;
        background-image:url('../../image/about-active.png');
        background-size:cover;
        background-position:top;
    }
}

h2.about_us_title {
    font-size: 48px;
    font-weight: 800;
    position: relative;
    text-shadow: 0px 0, 0 1px, 0px 0, 0 0px, 0px -1px, 1px 0px, -1px 0px, 1px -1px;
    bottom: 90px;
}

h2.about_us_title span {
    position: relative;
    z-index: 2;
}

h2.about_us_title:before {
    content: "";
    width: 56px;
    height: 56px;
    position: absolute;
    right: calc(50% + 59px);
    bottom: 0px;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}

.item-adv-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
    position: relative;
    top:80px;
}

.item-atv-inspire {
    margin-top: 70px !important;
    
}

.item-adv-sign {
    display: flex;
    align-items: center;
    justify-content: center; 
    position: relative;
    top:135px;
}

@media (max-width: 768px) {
    .item-adv-container {
        gap: 0;
        flex-direction: column;
        margin-top: 0;
    }

    .item-atv-inspire {
        margin-top: 30px !important;
    }

    .active-bg {
        margin-bottom: 0px !important;
        padding: 50px !important;
    }
}

@media (max-width: 650px) {
    .active-bg {
        margin-bottom: 0px !important;
        background-image: unset !important;
        padding: 50px !important;
    }

    .about-us .item-atv {
        grid-template-columns: none !important;
        min-width: 250px;
    }

    .about-us .item-atv > div:nth-child(1) {
        position: relative;
        left:45px;
    }
    .about-us .item-atv > div:nth-child(2) {
        padding:0 !important;
    }
}

.about-banner .section__title {
    margin-top: 65px;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 26px;
}

.about-banner .section__text {
    margin-bottom: 50px;
    font-size: 14px;
    font-weight: normal;
    color: #595959;
}
.about-banner .show-more, .about-message .show-more, .about-more .show-more {
    margin-top: 90px;
}
.about-banner .show-more a, .about-message .show-more a{
    font-size: 24px;
    font-weight: 800;
    background: white;
    border: 3px solid #F7EA03;
    border-radius: 11px;
    padding: 8px 29px;
}

.about-more .show-more a{
    font-size: 20px;
    font-weight: 800;
    background: white;
    border: 3px solid #F7EA03;
    border-radius: 25px;
    padding: 7px 20px;
}

@media screen and (max-width: 1152px) {
    h2.about_us_title {
        bottom: unset !important;
    }
}