@import "../../colors";
@import "../../settings";

.event-preview-box {
    pointer-events: none;
    
    &:before {
        content: '';
        position: fixed;
        display: block;
        height: 100%;
        width: 100%;
        border: solid 8px #f9ea29;
        left: 0;
        top: $menu-height;
        justify-content: center;
        align-items: center;
        z-index: 9;
        @media (min-width: $menu-break) {
            top: $menu-lg-height;
        }
    }
    
    .preview-label {
        color: $font-color;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 115px;
        height: 30px;
        font-size: 14px;
        border-bottom-right-radius: 6px;
        background-color: #f9ea29;
        font-family: $font-korolev;
        left: 0;
        top: $menu-height;
        z-index: 999;
        @media (min-width: $menu-break) {
            top: $menu-lg-height;
        }
    }
}

.form-footer {
    border-top: 1px solid #e5e5e5;
    z-index: 1000;
}
