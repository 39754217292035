:root{
    --dark: #1A1A1A;
    --primary: #F7EA00;
    --light: #F8F8F8;
}
body{
    font-size: 16px;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
    background: #F8F9FA !important;
}

.container{
    max-width: calc(100% - 30px);
    width: 1300px;
}

a, button, input[type="submit"], header nav ul li, header nav{
    transition: .3s all;
    -webkit-transition: .3s all;
    -moz-transition: .3s all;
}

select, input, textarea{
    outline: 0 !important;
    box-shadow: none !important;
}
img{
    max-width: 100%;
}
a, a:hover, a:focus{
    text-decoration: none;
}

/* HEADER */
header{
    background-color: #1A1A1A;
    position: fixed;
    width:100%;
    z-index:999;

}
header .logo{
    overflow: hidden;
   
}
header .logo img{
    width: auto;
    height: 48px;
}
header nav{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
header nav .menu{
    list-style-type: none;
    margin: 0;
    padding: 0;
}
header nav .menu li{
    position: relative;
    display: inline-block;
    margin-right: 32px;
}
header nav .menu li a{
    color: #F8F8F8;
    position: relative;
}
header nav .menu li a{
    z-index: 2;
    display: block;
}
header nav .menu li:hover::after{
    content: "";
    width: 21px;
    height: 21px;
    background-color:  #F7EA00;
    border-radius: 50%;
    position: absolute;
    left: -6px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    z-index: 0;
}
header nav .menu li a:hover{
    color: #F8F8F8;
}
header nav .header-row{
    display: flex;
}
.as-button{
    margin-left: 20px;
}
.as-button a{
    border: 1px solid #F8F8F8;
    font-size: 18px;
    text-align: center;
    color: #F8F8F8;
    border-radius: 22px;
    padding: 0 30px;
    line-height: 45px;
    height: 45px;
    display: block;
}
.as-button a:hover{
    background-color: #F7EA00;
    color: #1A1A1A;
}
header .languages{
    position: relative;
    margin-left: 14px;
}
header .languages .current-lang{
    font-size: 20px;
    height: 45px;
    line-height: 45px;
    color: #F8F8F8;
    position: relative;
    padding: 0px 50px 0 20px;
    cursor: pointer;
    border: 1px solid #FAE938;
    border-radius: 12px;
    
}
header .languages .current-lang::before, header .languages .current-lang::after{
    content: "";
}
/* header .languages .current-lang::before{
    width: 25px;
    height: 25px;
    background: url('./../images/world.png') no-repeat center;
    background-size: contain;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
} */
header .languages .current-lang::after{
    width: 18px;
    height: 18px;
    background: url('./../images/down-arrow.png') no-repeat center;
    background-size: contain;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}
header .languages .desktop{
    display: block;
}
header .languages .mobile{
    display: none;
}
header .languages ul{
    background-color: #1A1A1A;
    box-shadow: 2px 10px 30px #0000001A;
    border-radius: 5px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    position: absolute;
    top: calc(100% + 20px);
    left: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 1001;
}
header .languages ul li{
    position: relative;
}
header .languages ul li span{
    width: 25px;
    height: 25px;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -.8px;
    color: #1A1A1A;
    background-color: #F7EA00;
    border-radius: 50%;
    font-weight: 800;
    font-size: 12px;
}
header .languages ul li a{
    padding: 0 20px 0 50px;
    color: #F8F8F8;
    display: block;
    height: 55px;
    line-height: 55px;
}

header .languages ul li.active a{
    background: #1A1A1A;
}
header .languages ul li a:hover{
    background: #474515;
}


header .usermenu{
    position: relative;
    margin-left: 14px;
}
header .usermenu .current-user{
    font-size: 20px;
    
    height: 50px;
    line-height: 50px;
    color: #F8F8F8;
    position: relative;
    padding: 0 40px 0 50px;
    cursor: pointer;
}
header .usermenu .current-user::before, header .usermenu .current-user::after{
    content: "";
}
header .usermenu .current-user::before{
    width: 30px;
    height: 30px;
    background: url('./../images/user-icon.png') no-repeat center;
    background-size: contain;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}
header .usermenu .current-user::after{
    width: 18px;
    height: 18px;
    /*background: url('./../images/down-arrow.png') no-repeat center;*/
    background-size: contain;
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
}
header .usermenu .desktop{
    display: block;
}
header .usermenu .mobile{
    display: none;
}
header .usermenu ul{
    background-color: #1A1A1A;
    box-shadow: 2px 10px 30px #0000001A;
    border-radius: 5px;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 250px;
    position: absolute;
    top: calc(100% + 20px);
    left: 0;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
}
header .usermenu ul hr{
    background: #f9ea29;
    margin:0 20px;
}
header .usermenu ul li{
    position: relative;
}
header .usermenu ul li span{
    width: 25px;
    height: 25px;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: -.8px;
    color: #1A1A1A;
    background-color: #F7EA00;
    border-radius: 50%;
    font-weight: 800;
    font-size: 12px;
}
header .usermenu ul li a{
    padding: 0 20px 0 50px;
    color: #F8F8F8;
    display: block;
    height: 55px;
    line-height: 55px;
}
header .usermenu ul li a.no-icon{
    padding: 0 20px 0 20px;
}
header .usermenu ul li.active a{
    background: #1A1A1A;
}
header .usermenu ul li a:hover{
    background: #474515;
}

.toggle-menu{
    width: 31pt;
    position: relative;
    cursor: pointer;
    display: none;
}
.toggle-menu span{
    display: block;
    height: 4px;
    background-color: #F7EA00;
    margin-bottom: 8px;
}
.toggle-menu span:last-child{
    margin-bottom: 0;
}

.mobile-lang{
    color: #fff;
    display: none;
    margin-right: 10px;
    font-size: 18pt;
    line-height: 21pt;
}

header .languages:hover ul{
    visibility: visible;
    opacity: 1;
    
}

header .usermenu:hover ul{
    visibility: visible;
    opacity: 1;
    
}
/* SIGN */
body.app-login , body.app-register-user{
    background: url('../images/bg-sign.png') no-repeat center;
    background-size: cover;
    min-height: 100vh;
}

.full-screen{
    overflow: hidden;
    margin-top:98px ;
}
h1.title{
    font-size: 4rem !important;
    font-weight: 600;
    color: #1A1A1A;
    position: relative;
    width: 764px;
}

.home-text {
    width:665px;
    font-size:30px;
}

h1.title::before{
    content: "";
    width: 70px;
    height: 70px;
    position: absolute;
    right: calc(100% - 59px);
    bottom: calc(100% - 71px);
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}
h1.title span{
    position: relative;
    z-index: 2;
}
.full-screen h1.title{
    text-align: left !important;
   
}
.sign-form{
    display: flex;
    justify-content: flex-end;
}
.sign-form form{
    box-shadow: 5px 5px 50px #00000029;
    border-radius: 54px;
    max-width: 650px;
    background-color: #fff;
    padding: 100px 70px;
}
.sign-form form .form-group{
    position: relative;
    display: block;
}
.sign-form form label{
    margin: 0;
    position: absolute;
    left: 20px;
    top: 7px;
    font-weight: 600;
    font-size: 14px;
}
.sign-form form input{
    background-color: #F8F8F8;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: 1px solid #1A1A1A;
    padding: 32px 20px 10px 20px;
    font-size: 13px;
    font-style: italic;
}
.sign-form form .form-group.one input{
    border-color: #d00b13;
}
.sign-form form .form-group.two input{
    border-color: #34d1bf;
}
.sign-form form .form-group.three input{
    border-color: #1A1A1A;
}
.sign-form form button{
    border: 1px solid #F7EA00;
    box-shadow: 5px 5px 50px #00000029;
    width: 100%;
    height: 90px;
    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
}
.sign-form form button:hover{
    background-color: #F7EA00;
}
.sign-form form p{
    font-size: 14px;
    font-style: italic;
}
.sign-form form p a{
    color: #1A1A1A;
    font-weight: bold;
    font-style: normal;
}
.sign-form form p a:hover{
    color: #F7EA00;
    text-decoration: underline;
}
.sign-form form ul{
    list-style-type: none;
    padding: 0;
}
.sign-form form ul li{
    display: inline-block;
    margin-right: 20px;
}
.sign-form form ul li:last-child{
    margin: 0;
}
.sign-form form ul li img{
    width: 47px;
    height: 47px;
}


/* SLIDE */
.slide{
    background-repeat: no-repeat !important;
    background-size: cover ;
    background-position: center;
    height: 956px;
    width: 100%;

    /* position: relative; */
}

.slide .container, .slide .row{
    height: 100%;
}

/* .form-search{
    position: absolute;
    left: 0;
    bottom: 20px;
    right: 0;
    z-index: 1;
} */
.form-search form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #faf9f9;
    border-radius: 50px;
    overflow: hidden;
    box-shadow: 0 0 40px 10px rgb(0, 0, 0, .13)
}
.form-search form input {
    border: 0;
    width: calc((100% - 100px) / 4);
    padding: 20px;
    font-size: 18px;
   
    height: 100px;
    text-align: center;
}
.form-search form .input__locationType , .form-search form .input__sportType , .form-search form .input__dateRange {
    border: 0;
    width: calc((100% - 100px) / 4);
   
   
    text-align: center;
}
.form-search form input::placeholder{
    color: #d5d5d5;
}
#date-formatted{width:  100% !important;}

.placeholder--select{
    border:0px !important;
  
}

.placeholder--select.empty{
    color: #d5d5d5 !important;
    font-size: 18px;
}
.form-search  form  button{
    width: 100px;
    height: 100px;
    background: #F7EA00 url('./../images/search.svg') no-repeat center;
    background-size: 35px;
    border: 0;
    border-radius: 50%;
}

/*  */
.activities{

}
.activities h2, 
.events h2, 
.discovers h2, 
.host_event h2, 
.blog h2{
    font-size: 48px;
    font-weight: 800;
}
.activities h3, 
.discovers h3, 
.host_event h3, 
.blog h3{
    font-size: 30px;
    font-weight: 400;
}
.activities .single-activity{
    /* overflow: hidden; */
    /* padding: 0 25px; */
    position: relative;
}
.activities .single-activity > img{
    width: 100%;
    position: relative;
}
.activities .single-activity h4{
    position: absolute;
    bottom: 37px;
    left: 22px;
    margin: 0;
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    z-index: 2;
    transition: all 0.7s ease;
}

.activities .single-activity h4 span, .categories h3.category__name span {
    position: relative;
    z-index: 2;
}

.activities .single-activity h4 span::after, .categories .category__cover h3.category__name span::after {
    content: "";
    position: absolute;
    width: 21px;
    height: 21px;
    top: 7px;
    right: -9px;
    background-color: #F7EA00;
    border-radius: 50%;
    transition: all 0.7s ease;
    z-index: 1;
    opacity: 0.8;
}

.categories .category__cover h3.category__name span::after {
    top: 4px;
    right: -8px;
    background-color: #FAE938D6;
}

.activities .single-activity h4 span:hover::before {
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 11px;
}



.activities .single-activity div {
    position: absolute;
    bottom: 17px;
    left: 24px;
    color: #fff;
    font-size: 15px;
    z-index: 2;
    transition: all 0.7s ease;
}

.activities .single-activity.hovered h4, .categories .category__cover.hovered h3.category__name {
    top: 30%;
    text-align: center;
    left: unset;
    right: unset;
    transform: unset;
    color: #000;
    width: 100%;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
}

.categories .category__cover.hovered h3.category__name {
    top:unset;
    bottom:5px;
}

/* .activities .single-activity.hovered h4 span, .categories .category__cover.hovered h3.category__name span {
    color: white;
} */

.categories .category__cover.hovered h3.category__name {
    font-size: 22px;
}

.view {
    display:none;
}

.view.hovered {
    display: block;
    text-align: center;
    font-size: 3rem;
    font-weight: 900;
    color: #000;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 3;
    text-shadow: -1px 0, 0 1px, 1px 0, 0 -1px, -1px -1px, 1px 1px, -1px 1px, 1px -1px;
    letter-spacing: 2px;
    transform: translate(-50%, -50%);
}

.activities .single-activity.hovered h4 span::after, .categories .category__cover.hovered h3.category__name span::after {
    content: "";
    position: absolute;
    width: 296px;
    height: 276px;
    top: -78px;
    right: calc(50% - 153px);
    background-color: #FAE938A8;
    border-radius: 10px;
    -webkit-transition: all 0.7s ease;
    transition: all 0.7s ease;
    z-index: -1;
    opacity: 0.4;
}

.categories .category__cover.hovered h3.category__name span::after {
    top: -191px;
    width: 305px;
    height: 231px;
}

.activities .single-activity.hovered div {
    top: 70%;
    text-align: center;
    left: unset;
    right: unset;
    transform: unset;
    width: 100%;
    color: #000;
    font-size:20px;
}

.activities .single-activity .counter{
    width: 100px;
    height: 100px;
    background-color: #F7EA00;
    border-radius: 50%;
    position: absolute;
    left: 0;
    bottom: -25px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
    line-height: 1.1;
}

.activities .single-activity .play{
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.activities .single-activity .play img{
    width: 29px;
    height: 29px;
}
.activities .show-more, .host_event .show-more{
    margin-top: 100px;
}



.activities .show-more a, .host_event .show-more a ,.custom_banner a  {
    border: 1px solid #F7EA00;
    color: #1A1A1A;
    box-shadow: 5px 5px 50px #00000029;
    padding: 0 70px;
    height: 90px;
    border-radius: 45px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 700;
}
.custom_banner a{
    color:var(--light)
}
.activities .show-more a:hover, .host_event .show-more a:hover,.custom_banner a:hover{
    background-color: #F7EA00;
    text-decoration: none !important;
}



.events .filter{
    list-style-type: none;
    margin: 0;
    padding: 0;
    text-align: center;
}
.events .filter li{
    position: relative;
    display: inline-block;
    margin-right: 50px;
    
    font-size: 20px;
}
.events .filter li a{
    position: relative;
    color: #1A1A1A;
    z-index: 2;
    display: block;
    opacity: .5;
}
.events .filter li:last-child{
    margin: 0;
}
.events .filter li::before{
    content: "";
    width: 30px;
    height: 30px;
    background-color: #F7EA00;
    border-radius: 50%;
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    z-index: 0;
    display: none;
}
.events .filter li.active::before{
    display: block;
}
.events .filter li.active a{
    opacity: 1;
    font-weight: 600;
    color: #1A1A1A;
}
.events .single-event{
    background-color: #fff;
    box-shadow: 0px 4px 25.8px 0px #00000021;
    border-radius: 12px;
}
.events .single-event .thubmnail{
    position: relative;
    border-radius: 50px;
}
.events .single-event .thubmnail img{
    /* width: 100%; */
}
.events .single-event .thubmnail .type{
    width: auto;
    height: 57px;
    padding: 0 30px;
    background-color: #1A1A1A;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30px;
    position: absolute;
    left: -10%;
    top: 60px;
    color: #F8F8F8;
    font-weight: 700;
    letter-spacing: 1px;
}
.events .single-event .thubmnail .info{
    /* padding: 30px;
    position: absolute;
    left: 0;
    bottom: 0;
    color: #F8F8F8;
    width: 100%; */
}
.events .single-event .thubmnail .info h3{
    /* color: #F8F8F8; */
    margin: 0;
    font-weight: 600;
    font-size: 1.6rem;
}
.events .single-event .thubmnail .info .date{
    font-size: .9rem;
}
.events .single-event .content{
    /* padding: 20px 30px 25px; */
}
.events .single-event .content p{
    /* margin-bottom: 10px; */
}
.events .single-event .content a{
    color: #1D45E2;
    letter-spacing: 0.32px;
}
.events .single-event .content a:hover{
    text-decoration: underline;
}


.discovers{

}
.single-discover{
    position: relative;
}
.single-discover img{
    width: 100%;
}
.single-discover h4{
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    padding: 10px;
    color: #F8F8F8;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

.host_event{

}
.single-host_event h3{
    font-weight: normal;
    line-height: 1.6;
    font-size: 25px;
}
.host_event .show-more{
    margin-top: 50px;
}
.host_event .show-more a {
    padding: 0 50px;
    height: 75px;
    font-size: 20px;
}

.blog{
    overflow: hidden;
}
.blog:before {
    position: absolute;
    width: 1437px;
    height: 676px;
    left: calc(50% - 1437px/2 - 0.5px);
    top: 4001px;
    background-color: #33D1BF;
}
.blog .single-blog{
    position: relative;
    border-radius: 35px;
    overflow: hidden;
}
.blog .single-blog img{
    width: 100%;
    height: 400px;
}
.blog .single-blog .info{
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 50px 40px 30px;
    z-index: 2;
    color: #F8F8F8;
    background: rgb(0,0,0);
background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.33519345238095233) 25%, rgba(0,0,0,0.774969362745098) 50%, rgba(0,0,0,0.9010197829131653) 75%, rgba(0,0,0,1) 100%);
}
.blog .single-blog .info .category{
    color: #F7EA00;
    font-size: 16px;
}
.blog .single-blog .info h4{
    margin: 5px 0 0;
    font-weight: 600;
    max-width: 70%;
    color: #F8F8F8 !important;
}
.blog .single-blog .date{
    position: absolute;
    top: 30px;
    right: 30px;
    text-align: right;
    color: #F8F8F8;
}
.blog .single-blog .read-more{
    color: #F8F8F8;
    position: absolute;
    right: 30px;
    bottom: 10px;
    z-index: 5;
    font-size: 12px;
}
.blog .single-blog .read-more:hover{
    text-decoration: underline;
}

h2.take_part_title {
    position: relative;
}

h2.take_part_title span{
    position: relative;
    z-index: 2;
}

h2.take_part_title:before {
    content: "";
    width: 60px;
    height: 60px;
    position: absolute;
    right: calc(100% - 572px);
    bottom: 2px;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}
h2.get_motivated {
    position: relative;
}
h2.get_motivated span {
    position: relative;
    z-index: 2;
}
h2.get_motivated:before {
    content: "";
    width: 60px;
    height: 60px;
    position: absolute;
    right: calc(100% - 510px);
    top: 2px;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}

.custom_banner a:hover {
    color: var(--light);
}

@media (min-width: 1300px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
      max-width: 1300px !important; 
    }

}

@media screen and (max-width: 1290px) {
    .view.hovered {
        font-size: 2.5rem;
        /* left: 28%; */
    }
    /* .activities .single-activity::before {
        right: calc(100% - 96px);
        top: calc(100% - 51px);
    }
    
    .activities .single-activity h4 {
        top: 77%;
        left: 24%;
    }

    .activities .single-activity.hovered div {
        left: 58%;
        font-size: 15px;
    } */
}

@media screen and (max-width: 885px) {
    .view.hovered {
        /* left: 27%;
        top: 35px; */
    }
}

@media screen and (max-width: 1070px){
   
   
    header nav ul li {
        margin-right: 20px;
    }
    header nav ul li.as-button {
        margin-right: 15px;
    }
    header .languages .current-lang {
        font-size: 16px;
    }
    header .usermenu .current-user {
        font-size: 16px;
    }
    header nav ul li.as-button a{
        font-size: 16px;
        padding: 0 25px;
    }
}
@media screen and (max-width: 1152px){
    .categories .activities-img-container {
        padding-top: 0 !important;
    }
    h2.activity_title, h2.claimed_events_title {
        bottom: unset !important;
    }
    .toggle-menu{
        display: block;
    }
    .mobile-lang{
        display: flex;
    }
    header{
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        z-index: 99;
    }
    header nav{
        position: fixed;
        left: -100%;
        right: 0;
        top: 80px;
        width: 100%;
        height: calc(100vh - 80px);
        z-index: 99;
        background-color: #1A1A1A;
        border-top: 8px solid #fff;
        display: block;
        padding: 50px;
        overflow-y: auto;
    }
    header nav.active{
        left: 0;
    }
    header nav .menu li {
        display: block;
        margin-right: 0;
        margin-bottom: 35px;
    }
    .as-button{
        margin: 0;
    }
    .as-button a{
        background-color: #F7EA00;
        color: #1A1A1A;
    }
    header .languages{
        margin-left: 0;
        margin-bottom: 35px;
    }
    header .languages .desktop{
        display: none;
    }
    header .languages .mobile{
        display: block;
    }
   
    header .languages ul {
        background-color: transparent;
        box-shadow: none;
        visibility: visible;
        opacity: 1;
        top: 0;
        position: relative;
        border-radius: 0;
    }
    header .usermenu{
        margin-left: 0;
        margin-bottom: 35px;
    }
    header .usermenu .desktop{
        display: none;
    }
    header .usermenu .mobile{
        display: block;
    }
   
    header .usermenu ul {
        background-color: transparent;
        box-shadow: none;
        visibility: visible;
        opacity: 1;
        top: 0;
        position: relative;
        border-radius: 0;
    }
    header .languages ul li{
        margin: 0;
    }
    header .usermenu ul li{
        margin: 0;
    }
    header nav .header-row{
        display: flex;
        flex-direction: column-reverse;
    }
    header .languages .current-lang{
        padding: 0;
    }
    header .usermenu .current-user{
        padding: 0;
    }
    header .languages .current-lang::before, 
    header .languages .current-lang::after{
        display: none;
    }
    header .usermenu .current-user::before, 
    header .usermenu .current-user::after{
        display: none;
    }
    .blog .single-blog .info h4{
        max-width: 100%;
    }
    .events .single-event{
        margin: 0;
    }
}

@media screen and (max-width: 768px){

    .full-screen{
       
        margin-top:0px !important;
    }

    .pricing .section2, .pricing .section4 {
        padding: 0 !important;
    }

    .pricing .section3 {
        padding: 100px 0 !important;
    }

    .container{
        width: 100%;
    }
    h1.title{
        font-size: 20pt !important;
        /* text-align: center; */
    }
    .main-header-text h3 {
        font-size: 15px !important;
    }
    h1.title::before{
        display: none;
    }
    .sign-form{
        justify-content: center;
    }
    .sign-form form{
        padding: 70px 30px;
    }
    .sign-form form button{
        font-size: 20px;
        height: 75px;
    }

    .slide{
        
        height: 600px;

    }
    .slide .row{
        align-items: flex-start !important;
        /* padding-top: 100px; */
    }
    .slide h1, .slide h3 {
        text-align: start !important;
    }
    .input__locationType{
        display: none;
    }
    .input__sportType{
        display: none;
    }
    .form-search form input{
        display: none;
        width: calc(100% - 52pt);
        height: 52pt;
        font-size: 16px;
    }
    .form-search form input:first-child{
        display: block;
    }
    .form-search form button{
        width: 52pt;
        height: 52pt;
        background: #1A1A1A url(./../images/search-yellow.svg) no-repeat center;
        background-size: 25px;
    }
    .activities h2, .events h2, .discovers h2, .host_event h2, .blog h2{font-size: 24px;}
    .activities h3, .discovers h3, .host_event h3, .blog h3{font-size: 16px;}
    .activities .show-more, .host_event .show-more{
        margin-top: 40px;
    }
    .activities .show-more a, .host_event .show-more a {
        padding: 0 35px;
        height: 70px;
        font-size: 20px;
    }
    /* .form-search{
        max-width: 100%;
        width: 500px;
        left: 50%;
        right: inherit;
        transform: translateX(-50%);
        -webkit-transform: translateX(-50%);
    } */

    h2.take_part_title:before, h2.get_motivated:before {
        width: 0;
        height: 0;
    }

    .single-event .thubmnail {
        flex-direction: column !important;
    }
}
@media screen and (max-width: 580px){
    .main-header-text {
        left:5% !important;
    }
    .main-header-text .title {
        font-size:17px !important;
        text-align: left !important;
    }

    .main-header-text h3 {
        font-size:15px !important;
        width: 300px !important;
    }
    header .logo img{
        width: auto;
        height: 40px;
    }
    header nav{
        padding: 50px 35px;
        top: 72px;
        height: calc(100vh - 72px);
    }
    .mobile-lang{
        font-size: 16pt;
    }
    .toggle-menu{
        width: 28pt;
    }
    .toggle-menu span{
        margin-bottom: 8px;
    }
    .slide{
        height: 270px;
        background-size:cover;
        /* background-position:left;    */
    }
    .slide h1.title {
        width: unset !important;
        font-size: 17pt !important;
        text-align: start !important;
        margin-top: 15px !important;
    }
    .slide h3 {
        font-size: 14pt !important;
        text-align: start !important;
        width: unset !important;
    }
    .activities .show-more a, .host_event .show-more a {
        font-size: 16px;
    }
    .events .filter li {
        margin-right: 15px;
        font-size: 14px;
    }
    .events .single-event{
        margin: 0 10px;
    }
    .host_event {
        text-align: center;
    }
    .host_event .show-more{
        margin: 40px auto;
    }
    .blog .single-blog .info{
        padding: 50px 20px 30px;
    }
    .blog .single-blog .info h4{
        max-width: 100%;
    }
    h2.take_part_title:before, h2.get_motivated:before {
        width: 0;
        height: 0;
    }

    .bg-blog {
        background-position-y: -50px !important;
    }

    .section__background {
        height: 345px;
    }

    .section__background .section__inner {
        position:relative;
        bottom:70px;
    }

    .section__background .section__title {
        margin-bottom:15px !important; 
        font-size: 30px !important;
    }

    .section__background .section__text {
        margin-bottom:15px !important;
    }

    h1.title{ 
        margin-top: 10px !important;
    }
}


@media screen and (max-width: 1299px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 210px;
        left: calc(50% - 105px);
    }
}

@media screen and (max-width: 991px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 443px;
        left: calc(50% - 221px);
    }
}

@media screen and (max-width: 975px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 436px;
        left: calc(50% - 218px);
    }
}

@media screen and (max-width: 965px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 430px;
        left: calc(50% - 215px);
    }
}

@media screen and (max-width: 945px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 419px;
        left: calc(50% - 210px);
    }
}

@media screen and (max-width: 920px){
    h1.title {
        font-size: 2rem !important;
        width: 300px;
    }

    h1.title::before {
        display:none !important;
    }

    .home-text {
        width: 400px;
    }

}

@media screen and (max-width: 925px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 409px;
        left: calc(50% - 205px);
    }
}

@media screen and (max-width: 905px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 399px;
        left: calc(50% - 200px);
    }
}

@media screen and (max-width: 885px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 389px;
        left: calc(50% - 195px);
    }
}

@media screen and (max-width: 865px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 380px;
        left: calc(50% - 190px);
    }
}

@media screen and (max-width: 845px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 370px;
        left: calc(50% - 184px);
    }
}

@media screen and (max-width: 825px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 360px;
        left: calc(50% - 180px);
    }
}
@media screen and (max-width: 805px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 350px;
        left: calc(50% - 175px);
    }
}

@media screen and (max-width: 785px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 340px;
        left: calc(50% - 170px);
    }
}

@media screen and (max-width: 775px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 335px;
        left: calc(50% - 168px);
    }
}

@media screen and (max-width: 767px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 690px;
        left: calc(50% - 345px);
    }
}

@media screen and (max-width: 757px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 681px;
        left: calc(50% - 341px);
    }
}

@media screen and (max-width: 747px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 672px;
        left: calc(50% - 336px);
    }
}
@media screen and (max-width: 737px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 662px;
        left: calc(50% - 331px);
    }
}
@media screen and (max-width: 727px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 651px;
        left: calc(50% - 325px);
    }
}
@media screen and (max-width: 707px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 631px;
        left: calc(50% - 316px);
    }
}



@media screen and (max-width: 700px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 623px;
        left: calc(50% - 312px);
    }
}

@media screen and (max-width: 685px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 610px;
        left: calc(50% - 305px);
    }
}

@media screen and (max-width: 675px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 600px;
        left: calc(50% - 300px);
    }
}

@media screen and (max-width: 665px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 588px;
        left: calc(50% - 295px);
    }
}

@media screen and (max-width: 655px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 579px;
        left: calc(50% - 290px);
    }
}

@media screen and (max-width: 645px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 570px;
        left: calc(50% - 285px);
    }
}

@media screen and (max-width: 635px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 558px;
        left: calc(50% - 279px);    
    }
}

@media screen and (max-width: 625px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 547px;
        left: calc(50% - 274px); 
    }
}

@media screen and (max-width: 615px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 539px;
        left: calc(50% - 270px);
    }
}

@media screen and (max-width: 605px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 529px;
        left: calc(50% - 265px);
    }
}

@media screen and (max-width: 595px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 521px;
        left: calc(50% - 260px);
    }
}

@media screen and (max-width: 585px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 521px;
        left: calc(50% - 260px);
    }
}

@media screen and (max-width: 580px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 502px;
        left: calc(50% - 251px);
    }
}

@media screen and (max-width: 575px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 493px;
        left: calc(50% - 247px);
    }
}

@media screen and (max-width: 565px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 488px;
        left: calc(50% - 244px);
    }
}

@media screen and (max-width: 555px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 481px;
        left: calc(50% - 240px);
    }
}

@media screen and (max-width: 545px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 470px;
        left: calc(50% - 235px);
    }
}

@media screen and (max-width: 535px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 459px;
        left: calc(50% - 230px);
    }
}

@media screen and (max-width: 525px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 450px;
        left: calc(50% - 225px);
    }
}

@media screen and (max-width: 515px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 440px;
        left: calc(50% - 221px);
    }
}

@media screen and (max-width: 505px){
    .activities .single-activity.hovered h4 span::after, 
    .categories .category__cover.hovered h3.category__name span::after {
        width: 430px;
        left: calc(50% - 215px);
    }
}

.security-hint {font-size: 12px;text-align:center;padding-top: 20px;max-width: 280px;}

.slick-next, .slick-prev {
    right: unset !important;
    left: unset !important;
}

h2.stripe_title {
    position: relative;
}

h2.stripe_title span {
    position: relative;
    z-index: 2;
}

h2.stripe_title:before {
    content: "";
    width: 30px;
    height: 30px;
    position: absolute;
    right: calc(100% - 24px);
    bottom: 1px;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}

.event-box .event-name {
    position: relative;
}

.event-box .event-name a {
    position: relative;
    z-index: 1;
}

.event-box .event-name::after {
    content: "";
    width: 20px;
    height: 20px;
    position: absolute;
    left: -6px;
    top: 0;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 0;
}

h2.runningevents-title {
    font-size: 48px;
    font-weight: 900;
    position: relative;
}

h2.runningevents-title span {
    position: relative;
    z-index: 2;
    color: #000000;
    text-shadow: 0px 0, 0 1px, 0px 0, 0 0px, 0px -1px, 1px 0px, -1px 0px, 1px -1px;
    letter-spacing: 2px;
}

h2.runningevents-title:before {
    content: "";
    width: 65px;
    height: 65px;
    position: absolute;
    right: calc(50% + 142px);
    bottom: -5px;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}

.categories .activities-img-container {
    padding-top: 96px;
}

.event .event--title {
    font-weight: 700;
    text-shadow: -1px 0, 0 1px, 1px 0, 0 -1px, -1px -1px, 1px 1px, -1px 1px, 1px -1px;
    letter-spacing: 2px;
}

.event .event--title span {
    position: relative;
    z-index: 2;
}

.event .event--title span:before {
    content: "";
    width: 65px;
    height: 65px;
    position: absolute;
    left: -10px;
    top: 2px;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: -1;
}
h1.event--about-title {
    position: relative;
    margin-bottom: 25px;
}

h1.event--about-title span {
    font-size:36px;
    font-weight: 600;
    position: relative;
    z-index: 2;
}

h1.event--about-title:before {
    content: "";
    width: 40px;
    height: 40px;
    position: absolute;
    top: 12px;
    right: calc(50% + 25px);
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}
h1.event--location-title {
    position: relative;
    margin-bottom: 25px;
}

h1.event--location-title span {
    font-size:36px;
    position: relative;
    z-index: 2;
}

h1.event--location-title:before {
    content: "";
    width: 40px;
    height: 40px;
    position: absolute;
    top: 12px;
    right: calc(50% + 30px);
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}
h1.event--date-title {
    position: relative;
    margin-bottom: 25px;
}

h1.event--date-title span {
    font-size:36px;
    position: relative;
    z-index: 2;
}

h1.event--date-title:before {
    content: "";
    width: 40px;
    height: 40px;
    position: absolute;
    top: 12px;
    right: calc(50% + 73px);
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}


.summary-ul li {
    padding:20px 0px;
}

.header-activity {
    position:relative;
    background-repeat: no-repeat;
    background-size:cover;
    /* height: 740px;
    max-height: 1280px; */
    width:100%;
}

.header-images {
   
    display: flex;
  justify-content: center;
  align-items: center;
}


.event--about-content {
    margin: 0px 200px;
}


.event--price-pos {
    /* position:absolute;
    top: 28%;
    left:0px;
    width:100%; */
}

.event--price {
    display:flex;
    align-items:center;
    justify-content:space-evenly;
    flex-wrap:wrap;
    position: relative;
    top: 25%;
}

.event--tickets {
    /* background-repeat: no-repeat;
    background-size:cover;
    height: 735px;
    max-height: 1280px;
    width:100%; */
    position:relative;
}

.event--location-content {
    margin: 0px 200px;
}

.event--summary {
    position:relative;
    background-repeat: no-repeat;
    background-size:cover;
    height: 650px;
    max-height: 1280px;
    width:100%;
}

.event--summary-pos {
    position:absolute;
    top:13%;
    left:0;
    width:100%;
}

.event--date-content {
    margin: 0px 200px;
}

.community-img {
    position:absolute;
    box-shadow: 1px 1px 2px 0px rgba(0, 0, 0, 0.75);
    bottom: -73px;
    left: 70px;
}

@media (max-width: 1200px) {
    .header-activity {
        background-image: unset !important;
        height: unset;
    }

    .activity-breadcrumb {
        padding-top: 0px !important;
    }

    .header-images {
        position: unset;
    }

    .event .event--title {
        padding-top: 30px !important;
    }

    .event--about-content {
        margin: 0px 20px;
    }

    .event--price-pos {
        /* position: unset; */
        background-image: unset !important;
    }

    .event--price {
        flex-direction: column;
        gap: 25px;
    }

    .event--tickets, .event--summary {
        background-image: unset !important;
        height: unset;
    }

    .new_container {
        gap: 30px;
    }

    .event--location-content, .event--date-content, .summary-ul {
        margin: 0px 20px;
    }
    
    .event--summary-pos {
        position: unset;
    }

    .event .event--title span:before {
        width: 37px;
        height: 37px;
    }

    h1.event--location-title:before {
        top: 3px;
        right: calc(50% + 38px);
    }
    h1.event--about-title:before {
        top: 3px;
        right: calc(50% + 25px);
    }
    h1.event--date-title:before {
        top: 3px;
    }

    .community-img {
        left: 30px;
    }

    .community-img > img {
        width: 95px !important;
        height: 95px !important;
    }
}


h2.claimed_events_title {
    font-size: 48px;
    font-weight: 800;
    position: relative;
    bottom:50px;
}

h2.claimed_events_title span {
    position: relative;
    z-index: 2;
    text-shadow: -1px 0, 0 1px, 1px 0, 0 -1px, -1px -1px, 1px 1px, -1px 1px, 1px -1px;
}

h2.claimed_events_title:before {
    content: "";
    width: 75px;
    height: 75px;
    position: absolute;
    right: calc(50% + 130px);
    bottom: -7px;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}


.single-item-activity .category__block {
    width: 100% !important;
}

.single-item-activity .slick-prev {
    top: 57% !important;
    z-index: 1;
    left: 14px !important;
}

.single-item-activity .slick-next {
    top: 57% !important;
    z-index: 1;
    right: 14px !important;
}

.single-item-activity .slide {
    height: unset;
}

.overlay-container {
    position: relative;
    display: inline-block;
    width: 100%; /* Ensures the container takes up the full width */
    height: 100%; /* Ensures the container takes up the full height */
}

.overlay-container img {
    display: block;
    width: 100%;
    height: auto;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3); /* Black with 50% opacity */
    pointer-events: none; /* Allows interaction with the content underneath */
}


.activity-slick-next {
    position: absolute;
    right: 15px;
    top:23%;
    width: 55px;
    cursor: pointer;
}

.activity-slick-prev {
    position: absolute;
    rotate: 180deg;
    left: 15px;
    top:23%;
    width: 55px;
    cursor: pointer;
    z-index: 1;
}

.option-menu .option-menu-popup .option-item {
    text-align: left;
}

.event--social {
    padding-bottom: 55px;
}

.event--social-img-container {
    background-color:#000000;
    border-radius:50px;
    width: 40px;
    height: 40px;
    position:relative;
}

.event--social-img-container img {
    width:25px;
    height:25px;
    position:absolute;
    top: 7px;
    left:7px;
}

.event_prices {
    margin: 150px;
}

.event_icons {
    margin: 150px 0px;
}
.buy_tickets_container {
    display:flex;
    align-items:center;
    justify-content:space-between;
    margin: 0px 50px;
    flex-wrap: wrap;
}

@media screen and (max-width: 782px) {
    .event_prices {
        margin:0 !important;
    }

    .event--social {
        margin: 0 !important;
    }

    .event_icons {
        margin: unset !important;
    }
}

@media screen and (max-width: 421px) {
    .btn-buy {
        display: block !important;
        margin-left:unset !important;
    }
    .buy_tickets_container {
        text-align: center !important;
    }

}

@media screen and (max-width: 712px) {

    .iframe-event iframe{ 
        width: 380px !important; 
        height:215px !important; 
    }

    .event_prices {
        margin:0 !important;
    }

    .event_icons {
        margin: unset !important;
    }

    .buy_tickets_container {
        flex-direction: column;
        justify-content: center;
    }

    h1.event--about-title:before {
        top: 5px !important;
    }

}


.hover {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: opacity .3s ease;
    width: 100%;
  }

  .hover:hover {
    opacity: 1;
  }

  .hoverLabel {
    color: #f8e71c;
    font-size: 45px;
    font-weight: 500;
    line-height: 45px;
    letter-spacing: 4.6px;
   
  }


#ad_banner_section {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    transform: translateY(100%); /* Initially hide the banner off the screen */
    transition: transform 1s ease-in-out; /* Smooth transition */
    display: none; /* Ensure it's hidden initially */
}

#ad_banner_section.visible {
    transform: translateY(0); /* Slide the banner into view */
    display: block; /* Make sure it becomes visible when the class is added */
}
