@font-face {
    font-display: swap;
    font-family: 'Korolev Compressed';
    src: url('../../fonts/KorolevCompressed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev';
    src: url('../../fonts/Korolev-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev Condensed';
    src: url('../../fonts/KorolevCondensed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev';
    src: url('../../fonts/Korolev-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev';
    src: url('../../fonts/Korolev-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev';
    src: url('../../fonts/Korolev-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev Compressed';
    src: url('../../fonts/KorolevCompressed-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev';
    src: url('../../fonts/Korolev-HeavyItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev Compressed';
    src: url('../../fonts/KorolevCompressed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev';
    src: url('../../fonts/Korolev-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev Condensed';
    src: url('../../fonts/KorolevCondensed-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev Condensed';
    src: url('../../fonts/KorolevCondensed-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev Condensed';
    src: url('../../fonts/KorolevCondensed-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev';
    src: url('../../fonts/Korolev-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev';
    src: url('../../fonts/Korolev-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev';
    src: url('../../fonts/Korolev-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev';
    src: url('../../fonts/Korolev-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev Compressed';
    src: url('../../fonts/KorolevCompressed-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev Compressed';
    src: url('../../fonts/KorolevCompressed-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev Condensed';
    src: url('../../fonts/KorolevCondensed-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-display: swap;
    font-family: 'Korolev';
    src: url('../../fonts/Korolev-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

