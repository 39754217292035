.security {
    flex: 1;
    display: flex;
    background-size: auto 100%;
    flex-direction: column;
    padding-top: 50px;
    
    @media (min-width: $break-lg) {
        flex-direction: row;
        padding-top: 75px;
        min-height: 100vh;
    }
    
    &.login {
        background-image: url("../../image/vendor/header_photo.jpg");
        
        .security-info {
            @media (max-width: $break-lg) {
                display: none;
            }
        }
    }
    
    &.registration {
        background-image: url("../../image/vendor/header.jpg");
        
        @media (max-width: $break-lg) {
            background-size: 200% auto;
            background-position: center top;
        }
    }
    
    .flex-md-half {
        width: 100%;
        @media (min-width: $break-md) {
            width: 50%;
        }
    }
    
    .security-info {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media (min-width: $break-lg) {
            align-items: flex-end;
        }
        
        .info-box {
            max-width: 280px;
            width: 100%;
            margin: 50px 150px;
            
            .info-title {
                font-size: 28px;
                color: #ffffff;
                font-weight: bold;
                letter-spacing: 0;
            }
            
            .info-text {
                max-width: 250px;
                margin: 40px 0;
                line-height: 1.64;
                color: #ffffff;
            }
        }
    }
    
    .security-form {
        flex: 1;
        display: flex;
        justify-content: center;
        background: #ffffff;
        flex-direction: column;
        align-items: center;
        @media (min-width: $break-lg) {
            align-items: flex-start;
        }
        
        .security-form-box {
            max-width: 320px;
            width: 100%;
            margin: 50px 100px;
            
            .security-title {
                font-size: 22px;
                font-weight: bold;
                margin-bottom: 25px;
            }
            
            .security-error {
                background: url("../../image/icon/error.svg") no-repeat left top;
                list-style: none;
                padding-left: 25px;
                color: $red;
                font-size: 14px;
                font-family: $font-poppins;
                line-height: 1.8;
                
            }
            
            .form-group {
                ul {
                    background: url("../../image/icon/error.svg") no-repeat left top;
                    list-style: none;
                    padding-left: 25px;
                    
                    li {
                        color: $red;
                        font-size: 14px;
                        font-family: $font-poppins;
                        line-height: 1.8;
                    }
                }
                
                ul ~ input {
                    border: 2px solid $red;
                }
            }
            
            .security-or {
                margin-top: 15px;
                display: block;
                text-align: center;
                color: $font-color-light;
                font-family: $font-korolev;
            }
            
            .btn--facebook {
                color: #fff;
                border-radius: 4px;
                font-size: 16px;
                font-family: Helvetica, Arial, sans-serif;
                font-weight: 700;
                margin-left: 0;
                margin-top: 15px;
                height: 40px;
                background: #1877f2 url("../../image/social/facebook.svg") no-repeat left 12px center;
                background-size: 24px;
                margin-bottom: 30px;
                text-transform: none;
                padding: 9px 10px 9px 44px;
            }
            
            .security-hint {
                margin-bottom: 25px;
                font-size: 13px;
                display: block;
                text-align: center;
                color: $font-color-light;
                font-family: $font-korolev;
                
                &.mrg-top {
                    margin-top: 25px;
                }
                
                &.no-mrg {
                    margin: 0;
                }
                
                a {
                    color: $font-color;
                    margin-left: 5px;
                }
            }
            
        }
    }
    
    &.registration-vendor {
        padding-top: 0;
        background: none;
        flex-direction: column;
        
        .vendor-header {
            // background: url("../../image/vendor/bg1.jpg") no-repeat;
            // background-size: cover;
            width: 100%;
            overflow: hidden;
            
            // @media (min-width: $break-xl) {
            //     background-image: url("../../image/vendor/bg1@2x.jpg");
            // }
            
            .vendor-header-layer {
                // min-height: 100vh;
                margin: 80px 0px;
                width: 100%;
              
                display: flex;
                flex-direction: row;
                align-items: center;
                
                .container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media (min-width: $break-md) {
                        flex-direction: row;
                        justify-content: space-around;
                    }
                }
                
                .slogan {
                    float: left;
                    display: none;
                    @media (min-width: $break-md) {
                        display: block;
                    }
                    
                    h2 {
                        color: #000000;
                        font-size: 15px;
                        // font-weight: 500;
                        line-height: 1.54;
                        letter-spacing: 0.5px;
                        
                        .theme-color {
                            font-weight: bold;
                            font-size: 24px;
                            color: #000000;
                        }
                    }
                }
                
                .security-form {
                    max-width: 350px;
                    width: 100%;
                    padding: 30px;
                    margin: 80px 0;
                    float: right;
                    
                    .security-form-box {
                        margin: 0;
                    }
                }
            }
        }
        
        .container-max-width {
            max-width: 1000px;
        }
        
        .vendor-body {
          
            min-height: 90vh;
            
            .steps-title {
                margin-top: 70px;
                font-size: 26px;
                font-weight: bold;
            }
            
            .intend-top {
                position: relative;
                // background-color: #FFFFFF;
                margin-left: 20px;
                margin-right: 20px;
                // margin-top: -60px;
                padding: 60px 30px 0;
                width: calc(100% - 40px);
                
                @media (min-width: $break-md) {
                    // padding: 60px 100px 0;
                    margin-left: auto;
                    margin-right: auto;
                }
                
                .item-atv {
                    margin-top: 30px;
                    display:flex;
                    align-items:center;
                    justify-content:space-between;
                    gap:20px;
                    width: 100%;
                    padding:10px;
                    border-radius:16px;
                    background-color:#FFF;
                    box-shadow: 0px 20px 27px 0px #0000000D;
                    // max-height: 350px;
                    // min-height: 250px;
                    
                    .icon {
                        height: 110px;
                        background-repeat: no-repeat;
                        background-size: contain;
                        width: 100%;
                        &.icon-place {
                            background-image: url("../../image/vendor/place@2x.png");
                        }
                        
                        &.icon-community {
                            background-image: url("../../image/vendor/participants@2x.png");
                        }
                        
                        &.icon-users {
                            background-image: url("../../image/vendor/users@2x.png");
                        }

                        &.icon-wallet {
                            background-image: url("../../image/vendor/wallet@2x.png");
                        }
                    }
                }
                
                .item-title {
                    margin-top: 20px;
                    font-size: 22px;
                    font-weight: bold;
                }
                
                p {
                    margin-top: 15px;
                }
                
                .one-click {
                    margin-top: 50px;
                    
                    .title {
                        margin-top: 50px;
                        font-size: 26px;
                        font-weight: bold;
                    }
                    
                    p {
                        margin-top: 30px;
                    }
                    
                    a {
                        margin-top: 30px;
                    }
                    
                    .create-event-icon {
                        padding-bottom: 60%;
                        background: url("../../image/vendor/create-event.png") no-repeat;
                        background-size: 100%;
                    }
                }
                
                .reviews {
                    margin-top: 60px;

                    .content-box {
                        background-color: #FFFFFF;
                        box-shadow: 20px 8px 80px 0px #00000029;
                        border-radius: 32px;
                        padding:25px;
                        position: relative;
                        width: 100%;
                    }

                    .content-box:after {
                        content: "";
                        border-top: 39px solid #ffffff;
                        border-right: 50px solid transparent;
                        border-left: 0px solid transparent;
                        // filter: drop-shadow(0px 4px 3px #00000029);
                        width: 0;
                        height: 0;
                        position: absolute;
                        bottom: -39px;
                        left: 80px;
                    }
                    
                    .review-box {
                        margin-top: 60px;
                        
                        .review-header {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            
                            .review-avatar {
                                height: 80px;
                                width: 80px;
                                background-size: 100%;
                                border-radius: 50%;
                                border: 5px solid #fff;
                                box-shadow: 0px 16px 16px 0px #00000014;
                            }
                            
                            .review-author {
                                margin-left: 15px;
                                
                                .review-author-name {
                                    display: block;
                                    font-size: 24px;
                                    font-weight: 600;
                                    color: #6A6A6A;
                                    margin-bottom: 1rem;
                                }
                                
                                .review-author-org {
                                    display: block;
                                    font-size: 14px;
                                    color: $font-color-light;
                                    
                                }
                            }
                        }
                        
                        .review-icon {
                            color: $font-color;
                            font-size: 90px;
                            margin-top: -50px;
                            opacity: .15;
                            font-family: $font-poppins;
                        }
                        
                        .review-content {
                            font-family: $font-korolev;
                            font-weight: 500;
                            letter-spacing: 0;
                        }
                    }
                }
            }
            
            .part-2 {
                overflow: hidden;
                margin-top: 90px;
                max-width: 100%;
                
                .part-2-bg {
                    width: 100%;
                    min-height: 400px;
                    padding-bottom: 90%;
                    background: url("../../image/vendor/bg1.jpg") no-repeat center;
                    background-size: cover;
                    
                    @media (min-width: $break-xl) {
                        background-image: url("../../image/vendor/bg1@2x.jpg");
                        padding-bottom: 500px;
                    }
                }

                .part-2-bg-2 {
                    width: 100%;
                    min-height: 400px;
                    padding-bottom: 90%;
                    background: url("../../image/bg/man-running-2.jpg") no-repeat center;
                    background-size: cover;

                    @media (min-width: $break-xl) {
                        background-image: url("../../image/bg/man-running-2.jpg");
                        padding-bottom: 500px;
                    }
                }
                
                .part-2-info {
                    display: flex;
                    justify-content: center;
                    flex: 1;
                    @media (min-width: $break-md) {
                        align-items: center;
                        justify-content: flex-start;
                    }
                    
                    .intend-left-box {
                        padding: 60px 50px;
                        background: white;
                        width: 500px;
                        margin-top: -70px;
                        max-width: calc(100% - 40px);
                        margin-left: 20px;
                        margin-right: 20px;
                        @media (min-width: $break-md) {
                            margin-top: 0;
                        }
                        @media (min-width: $break-lg) {
                            margin-top: 0;
                            margin-left: -70px;
                        }
                        
                        .title {
                            font-size: 26px;
                            font-weight: bold;
                        }
                        
                        p {
                            margin-top: 30px;
                            font-size: 14px;
                            line-height: 1.71;
                        }
                        
                        a {
                            margin-top: 50px;
                        }
                        
                    }
                }
            }
            
            .part-3 {
                margin-top: 100px;
                width: 100%;
                padding-bottom: 24.5%;
                background: url("../../image/vendor/bg3.png");
                background-size: cover;
                min-height: 300px;
            }
            
            .part-4 {
                padding-top: 20px;
                
                .title {
                    font-size: 26px;
                    font-weight: bold;
                    max-width: 300px;
                }
                
                p {
                    margin-top: 0;
                    font-size: 14px;
                    color: #595959;
                }
                
                a {
                    margin-top: 50px;
                }
            }
            
            .sign-up-btn {
                margin-top: 50px;
                margin-bottom: 150px;
                
                .btn-vendor {
                    margin-top: 20px;
                    display: inline-block;
                    line-height: 1.35;
                    font-size: 26px;
                    font-family: $font-korolev;
                    border-bottom: 2px solid $font-color;
                    background: url("../../image/vendor/arrow.png") no-repeat right center;
                    padding: 15px 60px 15px 0;
                    text-decoration: none !important;
                    @media (min-width: $break-md) {
                        margin-top: 30px;
                    }
                }
            }
        }
    }
    
}



h2.become_org {
    font-size: 48px;
    font-weight: 800;
    position: relative;
    bottom:40px;
}

h2.become_org span {
    position: relative;
    z-index: 2;
    text-shadow: 0px 0, 0 1px, 0px 0, 0 0px, 0px -1px, 1px 0px, -1px 0px, 1px -1px;
}

h2.become_org:before {
    content: "";
    width: 75px;
    height: 75px;
    position: absolute;
    right: calc(50% + 201px);
    bottom: -7px;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}

.section1 {
    background: url('../../../public/images/transaction-fee-background.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    width: 100%;
    // padding-bottom: 218px;
    // padding: 50px 0 120px;

    // .container {
    //     display: flex;
    //     flex-flow: column;
    //     align-items: center;
    // }

    .container {
        padding-top: 140px;
    }
    .title {
        color: #242424;
        font-size: 36px;
        font-weight: bold;
        text-align: left;
    }

    .description {
        color: #242424;
        margin-top: 15px;

        @media (min-width: $break-md) {
            align-self: flex-end;
        }

        ul {
            list-style-position: inside;
            padding: 0;
        }
    }
}

.btn-pricing {
    font-size: 20px;
    font-weight: 800;
    background: white;
    border: 3px solid #F7EA03;
    border-radius: 25px;
    padding: 7px 20px;
}

.organiser-section-fees {
    .description {
        margin-bottom: 50px !important;
    }
}


.box-section3 {
    display: flex;
    flex-flow: column;
    position: relative;
    left: 0;
    right: 0;
    bottom: -80px;
    
    height: 300px;
    align-items: end;

    .title {
        color: #242424;
        font-weight: bold;
        font-size: 26px;
        margin-bottom: 2rem;
    }

    div.show-more {
        margin-top: 90px;
    }

    div.show-more a {
        font-size: 21px;
        font-weight: 800;
        background: white;
        border: 3px solid #F7EA03;
        border-radius: 11px;
        padding: 8px 29px;
    }
}

@media (max-width: 976px) {
    .box-section3 {
        bottom: 0;
    }

    h2.become_org:before {
        display: none;
    }

    .organiser-section-stripe, .organiser-section-fees .container .box, .security.registration-vendor .vendor-body .intend-top .one-click {
        padding: 0 !important;
    }

    .security.registration-vendor .vendor-header .vendor-header-layer  {
        min-height: 35vh;
    }

    .security.registration-vendor .vendor-body .intend-top {
        padding: 0;
        padding-top: 140px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .organiser-section-fees .container {
        padding: 0 35px;
    }
}

@media screen and (max-width: 1152px){
    h2.become_org {
        bottom:unset;
    }
}

.create-account-btn {
    color:#000000 !important;
    background-color: #FAE938 !important;
    width: 60px !important;
}