hr {
    margin: 0;
    border: none;
    height: 1px;
    background: linear-gradient(to right, $border-color 45%, transparent 0%) repeat-x top;
    background-size: 14px 1px;
    
    &.sep {
        margin: 60px 0;
    }
}

.theme-color {
    color: $yellow;
}

.container-fluid {
    @media (min-width: $break-md) {
        padding-right: 50px;
        padding-left: 50px;
    }
}



.lazy {
    opacity: 0;
    transition: opacity 1s;
    
    &.initial,
    &.loaded,
    &.error,
    &[data-was-processed="true"] {
        opacity: 1;
    }
}

.mt-25 {
    margin-top: 25px;
}
