.section--for-beginners {
    .beginners-banner {
        margin-top: 70px;
        position: relative;
        
        .banner-cover {
            border-radius: 4px;
            background-size: cover;
            background-position: center;
            background-image: url("../../image/cover.jpg");
            padding-bottom: 350px;
            @media (min-width: $break-md) {
                padding-bottom: 450px;
            }
            
        }
        
        .banner-layer {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            
            text-align: center;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
            
            .info-decs {
                font-family: $font-korolev;
               
                max-width: 800px;
                padding: 0 15px;
                line-height: 1.33;
                color: #ffffff;
                font-size: 36px;
                margin: 40px auto 0;
                @media (min-width: $break-md) {
                    font-size: 48px;
                    padding: 0 40px;
                    margin: 120px auto 0;
                }
            }
            
            .btn {
                margin-top: 50px;
               
            }
        }
        
    }
}
