
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $font-color-light;
    font-weight: normal;
    opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $font-color-light;
    font-weight: normal;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: $font-color-light;
    font-weight: normal;
}

input,
input.form-control,
select,
textarea,
.placeholder--select,
.placeholder--input {
    font-family: $font-poppins;
    border-radius: 4px;
    background-color: $input-bg;
    /*font-size: 15px;*/
    color: $font-color;
    height: 48px;
    padding: 13px 20px;
    border: 1px solid $input-border;
    box-shadow: none;
    outline: none;
    
    &:focus {
        box-shadow: none;
        background-color: $input-bg;
    }
}

textarea {
    min-height: 200px;
}

select,
.placeholder--select {
    padding: 10px 30px 10px 18px;
    /*background-image: url($dropdownIcon);*/
    background-repeat: no-repeat;
    background-position: right 16px top 50%;
    background-size: 11.3px auto;
    appearance: none;
}

input[type="checkbox"].switcher {
    position: relative;
    width: 0;
    height: 30px;
    cursor: pointer;
    
    &:before {
        position: absolute;
        left: 0;
        top: 0;
        display: block;
        content: "";
        width: 60px;
        height: 30px;
        border-radius: 25px;
        background-color: $input-bg;
        transition: all 0.3s ease;
    }
    
    &:after {
        position: absolute;
        left: 2px;
        top: 2px;
        display: block;
        content: "";
        width: 26px;
        height: 26px;
        border: 2px solid $input-bg;
        
        border-radius: 13px;
        object-fit: contain;
        background-color: #4a4a4a;
        transition: all 0.3s ease;
    }
    
    &:checked {
        &:before {
            background-color: #4a4a4a;
        }
        
        &:after {
            border: 2px solid white;
            left: 32px;
        }
    }
}

input.search-input {
    background-image: url("../../image/icon/search.svg");
    background-repeat: no-repeat;
    background-position: left 20px center;
    padding-left: 55px;
}

.gallery-image-form {
    // height: 120px;
    float: left;
    overflow: hidden;
    margin-right: 25px;
    // margin-top: 25px;
    border-radius: 4px;
    position: relative;
    padding-left: 0 !important;
    
    input {
        display: none;
    }
    
    &.empty {
        width: 200px;
        z-index: 10;
        margin-right: 0;
        // background: $gray-light;
        
        // &:before {
        //     content: "";
        //     display: block;
        //     position: absolute;
        //     top: 40px;
        //     left: 0;
        //     height: 20px;
        //     width: 100%;
        //     background: url("../../image/icon/cross.svg") no-repeat center;
        // }
        
        // &:after {
        //     content: "Add photo";
        //     display: block;
        //     position: absolute;
        //     font-family: $font-korolev;
        //     font-size: 14px;
        //     font-weight: 500;
        //     width: 100%;
        //     text-align: center;
        //     top: 70px;
        // }
        
        .layer {
            display: none;
        }
        
        img {
            display: none;
        }
        
        input {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 20;
            opacity: 0;
            cursor: pointer;
        }
    }
    
    img {
        height: 120px;
        width: auto;
    }
    
    .layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        background-image: linear-gradient(
                        to top,
                        rgba(0, 0, 0, 0),
                        rgba(0, 0, 0, 0.5)
        );
        transition: opacity 0.3s ease;
        
        .delete-image {
            position: absolute;
            top: 0;
            right: 0;
            width: 12px;
            height: 12px;
            padding: 10px;
            background: url("../../image/delete.svg") no-repeat center;
            cursor: pointer;
        }
    }
    
    &:hover {
        .layer {
            opacity: 1;
        }
    }
}

.form {
    &__search {
        padding: 25px 30px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        position: relative;
        @media (max-width: $break-md) {
            display: none;
        }
        
        .location__list {
            max-width: 300px;
        }
        
        .lightpick {
            padding: 25px 30px;
        }
        
        input,
        select,
        .placeholder--select,
        .placeholder--input {
            margin-bottom: 15px;
            padding: 10px 15px;
            width: 100%;
            
            &.empty {
                color: $font-color-light;
            }
        }
        
        label {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 0.3px;
            color: #4a4a4a;
        }
        
        button[type="submit"] {
            @extend .btn;
            width: auto;
            margin-left: auto;
            background-image: url("../../image/icon/search.svg");
            background-repeat: no-repeat;
            background-position: left 20px center;
           
            font-size: 16px;
            margin-bottom: 0;
            background-color: $font-color-dark;
            color: #fff;
        }
        
        .category__list,
        .location__list {
            position: absolute;
            top: 0;
            right: 2px;
            bottom: 0;
            overflow: hidden;
            transform: translateX(100%);
            background: #fff;
            height: 100%;
            
            & > ul {
                padding: 25px 30px;
                overflow-y: auto;
                height: 100%;
                
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    border-radius: 10px;
                    background-color: #f5f5f5;
                }
                
                &::-webkit-scrollbar {
                    width: 12px;
                    background-color: #f5f5f5;
                }
                
                &::-webkit-scrollbar-thumb {
                    border-radius: 10px;
                    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                    background-color: #efefef;
                }
                
                & > li {
                    & > span {
                        font-weight: bold;
                        color: black;
                        font-size: 18px;
                    }
                    
                    li {
                        opacity: 0.8;
                    }
                }
            }
            
            &:before {
                content: "";
                background-image: linear-gradient(#979797 45%, transparent 0%);
                background-position: right;
                background-size: 1px 14px;
                background-repeat: repeat-y;
                height: 100%;
                width: 1px;
                display: inline-block;
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
            }
        }
    }

    &__upgrade {
        padding: 25px 30px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        position: relative;
    }
}

.input {
    &__dateRange {
        div {
            position: relative;
            
            input[type="date"] {
                position: absolute;
                top: 0;
                left:0;
                width: 100%;
                opacity: 0;
                z-index: 10;
            }
        }
    }
}

.input-date--unstyled {
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
    }
}


    .hide-input {
        select {
            display: none;
        }
    }
    
    .lightpick {
        box-shadow: none;
        color: $font-color-dark;
        font-family: $font-korolev;
        background-color: #fff;
        border-radius: 0 !important;
        
        .lightpick__days {
            .is-today {
                color: $yellow;
                background-color: $font-color-dark !important;
            }
            
            .lightpick__day {
                &:hover {
                    color: $font-color-dark;
                    background-color: #faf9f9 !important;
                }
            }
        }
        
        @media (max-width: $break-sm) {
            left: 15px;
            width: calc(100vw - 30px);
            .lightpick__month {
                width: 100%;
            }
        }
        
        .lightpick__inner {
            .lightpick__toolbar {
                position: relative;
                
                button {
                    position: absolute;
                    background-color: transparent;
                    display: block;
                    height: 33px;
                    width: 30px;
                    transform: rotate(0) !important;
                    border: 1px solid $font-color-light;
                    margin: 0;
                    border-radius: 4px;
                    font-size: 0;
                    z-index: 1;
                    outline: none;
                    cursor: pointer;
                    background: unset;
                    &:before {
                        border: 1px solid $font-color-dark;
                        border-width: 0 2px 2px 0;
                        position: absolute;
                        content: "";
                        height: 10px;
                        width: 10px;
                        top: 9px;
                        left: 10px;
                    }
                    
                    &.lightpick__previous-action {
                        left: 0;
                        
                        &:before {
                            top: 10px;
                            left: 11px;
                            transform: rotate(135deg);
                        }
                    }
                    
                    &.lightpick__next-action {
                        right: 0;
                        
                        &:before {
                            top: 10px;
                            left: 7px;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
            
            .lightpick__months {
                background: transparent;
                grid-gap: 15px;
                
                .lightpick__month {
                    background-color: transparent;
                }
                
                .lightpick__toolbar {
                    position: absolute;
                    width: 100%;
                    top: 0;
                }
                
                .lightpick__month {
                    .lightpick__month-title-bar {
                        padding-bottom: 12px;
                        position: relative;
                        
                        .lightpick__month-title {
                            display: flex;
                            width: 100%;
                            justify-content: center;
                            align-items: center;
                            margin: 0 30px;
                            
                            & > * {
                                width: auto;
                            }
                            
                            .lightpick__select {
                                font-size: 18px;
                                font-weight: bold;
                                color: $font-color-dark;
                                font-family: $font-korolev;
                                padding: 0;
                                margin: 0 5px 0 0;
                                background-image: none;
                                text-transform: capitalize;
                                height: 30px;
                            }
                        }
                    }
                    
                    .lightpick__days-of-the-week {
                        .lightpick__day-of-the-week {
                            text-transform: capitalize;
                            color: $font-color-light;
                            padding: 8px 0;
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }
                    
                    .lightpick__days {
                        .lightpick__day {
                            background: transparent;
                            border-radius: 4px;
                            height: 32px;
                            
                            &.is-in-range {
                                background-color: rgba($yellow, 0.3) !important;
                            }
                            
                            &.is-start-date,
                            &.is-end-date {
                                background-color: $yellow !important;
                                color: $font-color !important;
                            }
                            
                            &.is-start-date {
                                border-radius: 4px 0 0 4px;
                                
                                &.is-flipped {
                                    border-radius: 0 4px 4px 0;
                                }
                            }
                            
                            &.is-end-date {
                                border-radius: 0 4px 4px 0;
                                
                                &.is-flipped {
                                    border-radius: 4px 0 0 4px;
                                }
                            }
                            
                            &.is-start-date.is-end-date {
                                border-radius: 4px;
                            }
                        }
                    }
                }
            }
            
            .lightpick__tooltip {
                display: none;
            }
            
            .lightpick__footer {
                margin-top: 10px;
                justify-content: flex-end;
                
                .lightpick__reset-action {
                    display: none;
                }
                
                .btn {
                    @media (max-width: $break-md) {
                        min-width: 20px;
                    }
                }
            }
        }
    }


body .input__dateRange {
    .lightpick {
        top: 90px !important;
       /* right: 0 !important;
        left: initial !important;
        bottom: initial !important;*/
        transform: translateX(100%) !important;
      /*  height: 100% !important;*/
        
        &:before {
            content: "";
            background-image: linear-gradient(#979797 45%, transparent 0%);
            background-position: right;
            background-size: 1px 14px;
            background-repeat: repeat-y;
            height: 100%;
            width: 1px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
    }
}

.location__list {
    li {
        display: flex;
        align-items: center;
        
        &:before {
            content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="25" hright="25" viewBox="0 0 512 512"><path d="M256 0C167.641 0 96 71.625 96 160c0 24.75 5.625 48.219 15.672 69.125C112.234 230.313 256 512 256 512l142.594-279.375C409.719 210.844 416 186.156 416 160 416 71.625 344.375 0 256 0zm0 256c-53.016 0-96-43-96-96s42.984-96 96-96c53 0 96 43 96 96s-43 96-96 96z"/></svg>');
            display: block;
            margin-right: 15px;
            width: 25px;
            min-width: 25px;
            height: 25px;
        }
    }
}

form[name="event_claim_request"] {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    
    @media all and(max-width: 768px) {
        margin-top: 25px;
    }
    
    > div {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        
        textarea {
            width: 80%;
            @media all and(max-width: 768px) {
                width: 100%;
            }
        }

        label {
            font-weight: 600;
        }
    }
    
    button {
        margin-top: 25px;
        margin-left: auto;
        width: auto;
    }
}


#event-search .input__dateRange .lightpick {
    top: 375px !important;
}

#event-search .input__dateRange .lightpick__toolbar button {
    background: unset;
    max-width: 30px;
}

#event-search .input__dateRange {
    .lightpick {
        transform: translateX(100%) !important;
        
        &:before {
            content: "";
            background-image: linear-gradient(#979797 45%, transparent 0%);
            background-position: right;
            background-size: 1px 14px;
            background-repeat: repeat-y;
            height: 100%;
            width: 1px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
    }
}

#event-search #form-search-container form input {
    width: unset !important;
}

#event-search .category__list 
    {
        li:first-child{
           
        }
        
        &.open {
            display: block;
            position: absolute;
            top: 375px;
            z-index:501;
            width: 450px;
            height:450px;
            border: 1px solid #ddd;
            box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.13);
            border-radius: 50px;
            padding: 20px;
            right:150px;
        }
    }

    
#event-search-ticket .input__dateRange .lightpick {
    top: 115px !important;
}

#event-search-ticket .input__dateRange .lightpick__toolbar button {
    background: unset;
    max-width: 30px;
}

#event-search-ticket .input__dateRange {
    .lightpick {
        transform: translateX(100%) !important;
        
        &:before {
            content: "";
            background-image: linear-gradient(#979797 45%, transparent 0%);
            background-position: right;
            background-size: 1px 14px;
            background-repeat: repeat-y;
            height: 100%;
            width: 1px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
        }
    }
}

#event-search-ticket #form-search-container form input {
    width: unset !important;
}

#event-search-ticket .category__list 
    {
        li:first-child{
           
        }
        
        &.open {
            display: block;
            position: absolute;
            top: 115px;
            z-index:501;
            width: 450px;
            height:450px;
            border: 1px solid #ddd;
            box-shadow: 0 0 40px 10px rgba(0, 0, 0, 0.13);
            border-radius: 50px;
            padding: 20px;
            right:150px;
        }
    }