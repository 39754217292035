.statistics {
    
    .statistics-chart-nav {
        margin-top: 40px;
        
        .nav-item {
            margin-right: 60px;
            
            .active {
                color: $font-color;
                text-decoration: underline;
            }
        }
    }
    
    .statistics-charts {
        .event-chart {
            margin-top: 100px;
        }
        
        .tab-pane {
            position: relative;
            
            select {
                position: absolute;
                right: 0;
                top: -50px;
                background-color: transparent;
            }
            
            .statistic-chart-content {
                padding-top: 85px;
                min-height: 400px;
                height: 400px;
                position: relative;
            }
            
            .ready {
                .event-loader {
                    display: none;
                }
            }
        }
    }
    
    .statistic-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 16px 0;
        
        .organiser-logo {
            background-color: #909090;
            width: 72px;
            height: 72px;
            border-radius: 50%;
            background-size: cover;
            margin-right: 30px;
            flex: 0 0 72px;
        }
        
        h2 {
            font-size: 38px;
            margin: 0;
        }
        
        .header-stat {
            margin-top: -5px;
            
            .stat-item {
                font-size: 12px;
                margin-right: 30px;
            }
        }
    }
    
    .statistics-general {
        margin-top: 40px;
        
        .table-title {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 16px 0;
            
            &_title {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            
            .active-events {
                margin-left: 24px;
                padding: 0 24px;
                display: inline-block;
                border-left: 1px solid $border;
                font-family: $font-korolev;
                color: $font-color-light;
            }
            
            h3 {
                font-size: 24px;
                margin: 0;
            }
        }
    }
    
    .status-dot {
        display: inline-block;
        height: 7px;
        width: 7px;
        border-radius: 50%;
        background: #c1c1c1;
        margin-right: 5px;
        
        &.default {
            background: #c1c1c1;
        }
        
        &.success {
            background: $green;
        }
        
        &.error {
            background: $red;
        }
    }
    
    .statistic-table {
        max-width: 100%;
        
        thead {
            tr {
                th {
                    border: none;
                    border-bottom: 4px solid $border;
                    font-family: $font-korolev;
                    color: $font-color-light;
                    font-size: 13px;
                    padding: 15px 0px;
                    
                    &.event-status-header {
                        padding-left: 28px;
                    }
                    
                    &:first-of-type {
                        padding-left: 0;
                    }
                    
                    &:last-of-type {
                        padding-right: 0;
                    }
                }
            }
        }
        
        tr {
            td {
                vertical-align: middle;
                text-align: left;
                font-size: 12px;
                padding: 16px 12px;
                
                .const
                &:first-of-type {
                    padding-left: 0;
                }
                
                &:last-of-type {
                    padding-right: 0;
                }
                
                .event-name {
                    font-family: $font-korolev;
                    font-size: 16px;
                    font-weight: bold;
                }
                
                .promotion-left {
                    color: $green;
                }
                
                .event-status {
                    display: inline-block;
                    padding: 6px 16px 6px 16px;
                    border-radius: 3px;
                    font-size: 13px;
                    position: relative;
                    cursor: pointer;
                    height: auto;
                    width: auto;
                    background: transparent;
                    
                    &:hover {
                        background: #ededed;
                    }
                }
                
                .ticket {
                    font-size: 13px;
                    
                    .ticket-total {
                        color: $font-color-light;
                    }
                    
                    .ticket-progress {
                        margin-top: 4px;
                        width: 64px;
                        height: 2px;
                        border-radius: 1px;
                        background-color: #ededed;
                        
                        .ticket-progress-bar {
                            width: 0;
                            height: 2px;
                            border-radius: 1px;
                            background-color: $font-color;
                        }
                    }
                }
            }
        }
    }
    
    .event-data {
        width: 300px;
        
        .back-to-statistic {
            font-family: $font-korolev;
            font-size: 14px;
            font-weight: 500;
            color: $font-color-light;
            position: relative;
            padding: 15px 15px;
            
            &:before {
                content: '';
                position: absolute;
                height: 8px;
                width: 8px;
                border-right: 1px solid $font-color;
                border-top: 1px solid $font-color;
                left: 3px;
                top: 17px;
                transform: rotate(-135deg);
            }
        }
        
        .event-cover {
            margin-top: 15px;
            border-radius: 4px;
        }
        
        .event-name {
            margin-top: 60px;
        }
        
        .event-dates {
            margin-top: 30px;
            font-size: 15px;
            
            th {
                color: $font-color-light;
                padding: 7px 25px 7px 0;
            }
        }
        
        .event-promote {
            margin-top: 60px;
        }
    }
    
    .event-charts {
        flex-grow: 1;
        @media (min-width: $break-md) {
            margin-left: 50px;
        }
        @media (min-width: $break-lg) {
            margin-left: 100px;
            
        }
    }
    
    .tickets-table {
        margin-top: 70px;

        .ticket-body-wrapper {
            background-image: url('../../images/mytickets.png');
            background-size: contain;
            background-repeat: no-repeat;
            // width: 100%;
            // height: 695px;
            // max-height: 695px;
            // overflow: hidden auto;
            padding-top: 20px;
        }
        
        .ticket-item {
            font-size: 12px;
            
            h2 {
                font-size: 24px;
            }
            
            .search-ticket {
                width: 100%;
                max-width: 460px;
                margin-bottom: 20px;
            }

            .ticket-row:not(.head-row) {
                background-color: #FFFFFF;
                box-shadow: 0px 4px 13px 0px #00000040;
                border-radius: 15px;
                margin:10px 0px 15px 0px;
            }
            
            .ticket-row {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 35px;
                
                & > div {
                    padding-right: 10px;
                    
                    &:last-of-type {
                        padding-right: 0;
                    }
                }
                
                .highlight {
                    background-color: rgba(255, 255, 0, 0.4);
                    display: inline-block;
                }
                
                .past-label {
                    color: #c2c2c2;
                }
                
                .active-label {
                    color: $green;
                }
                
                &.head-row {
                    font-family: $font-korolev;
                    color: $font-color-light;
                    font-size: 13px;
                    font-weight: bold;
                    padding: 20px 35px;
                    border-bottom: 4px solid $border;
                    // margin-top: 110px;
                    
                    .head-item {
                        span {
                            display: inline-block;
                            position: relative;
                            padding-right: 20px;
                            cursor: pointer;
                            
                            &.sorted:before {
                                content: '';
                                position: absolute;
                                height: 8px;
                                width: 14px;
                                background: url("../../image/icon/arrow.svg");
                                right: 0;
                                top: 6px;
                                transition: all .3s ease;
                            }
                            
                            &.sorted.asc:before {
                                transform: rotate(180deg);
                                top: 4px;
                            }
                        }
                    }
                }
                
                .ticket-participant-name {
                    font-family: $font-korolev;
                    font-size: 16px;
                    font-weight: bold;
                }
                
                .ticket-status {
                    text-transform: capitalize;
                }
                
                .ticket-expand {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    
                    .ticket-expand-btn {
                        font-family: $font-korolev;
                        font-size: 14px;
                        font-weight: bold;
                        padding: 5px 20px 5px 5px;
                        position: relative;
                        cursor: pointer;
                        
                        &:before {
                            content: '';
                            position: absolute;
                            height: 8px;
                            width: 14px;
                            background: url("../../image/icon/arrow.svg");
                            right: 5px;
                            top: 12px;
                            transition: all .3s ease;
                        }
                        
                        &.less {
                            &:before {
                                transform: rotate(180deg);
                                top: 10px;
                            }
                        }
                    }
                }
            }
            
            .ticket-row-additional {
                overflow: hidden;
                max-height: 0;
                transition: all .3s ease;
                background-color: $gray-light;
                display: flex;
                flex-direction: row;
                
                .ticket-additional-col {
                    padding: 25px;
                    
                    table {
                        th {
                            color: $font-color-light;
                            padding: 0 15px 15px 15px;
                        }
                        
                        td {
                            padding: 0 15px 15px 15px;
                        }
                    }
                }
            }
            
            &.open {
                margin-bottom: 10px;
                
                .ticket-row-additional {
                    max-height: 400px;
                    transition: all .3s ease;
                }
            }
        }
        
        .export-table {
            background: $gray-light url("../../image/icon/excel.svg") no-repeat left 16px center;
            padding: 18px 45px 14px;
           
            width: 100%;
            font-size: 14px;
            font-family: $font-korolev;
            cursor: pointer;
        }
    }
}

.statistics-range {
    padding: 13px 30px 7px 30px;
    background: url($dropdownIcon) no-repeat right 13px top 50%, url("../../image/icon/calendar.svg") no-repeat left 5px center;
    background-size: 11.3px auto, auto;
    border: none;
    position: relative;
   
    font-weight: bold;
    font-family: $font-korolev;
    
    &:hover, &:focus, &:active {
        background-color: transparent;
    }
}

.ticket-empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;
    
    .image {
        width: 150px;
        height: 150px;
        background: url("../../image/ui/ticket-empty.png") no-repeat center;
        background-size: contain;
    }
    
    .info {
        margin: 40px 0;
        text-align: center;
    }
}

.statistics-empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 50px 0;
    
    .image {
        width: 180px;
        height: 180px;
        background: url("../../image/ui/statistic@2x.png") no-repeat center;
        background-size: contain;
    }
    
    .info {
        margin: 40px 0;
        text-align: center;
    }
}



h2.my_tickets_title, h2.statistic_title {
    font-size: 48px;
    font-weight: 800;
    position: relative;
    bottom: 50px;
}

h2.my_tickets_title span, h2.statistic_title span {
    position: relative;
    z-index: 2;
    text-shadow: 0px 0, 0 1px, 0px 0, 0 0px, 0px -1px, 1px 0px, -1px 0px, 1px -1px;
}

h2.my_tickets_title:before, h2.statistic_title:before {
    content: "";
    width: 75px;
    height: 75px;
    position: absolute;
    right: calc(50% + 65px);
    bottom: -9px;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}


h2.statistic_title:before {
    right: calc(50% + 65px);
}


@media (max-width: 768px) { 
    .ticket-body-wrapper {
        background: none;
    }
}


@media screen and (max-width: 1152px){
    h2.my_tickets_title, h2.statistic_title {
        bottom: unset;
    }
}