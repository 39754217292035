.checkout {
    .checkout-typ {
        text-align: center;
        @media (min-width: $break-md) {
            text-align: left;
        }
        
        .image-box {
            margin: 32px auto auto;
            width: 350px;
            height: 300px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            
            &.image-success {
                background-image: url("../../image/ui/success@2x.jpg");
            }
            
            &.image-fail {
                background-image: url("../../image/ui/fail@2x.jpg");
            }
        }
        
        h2 {
            font-size: 36px;
            line-height: 1.36;
            font-weight: bold;
        }
        
        .transaction-id {
            font-size: 20px;
            font-weight: bold;
        }
        
        .email-info {
            margin: 60px 0 0;
        }
        
        .my-ticket, .fail-info {
            margin: 32px 0 0;
        }
        
        p {
            font-size: 14px;
            
            a {
                text-decoration: underline;
            }
        }
        
        .btn {
            margin-top: 40px;
        }
        
    }
    
}
