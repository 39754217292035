.error-page {
    
    .not-found {
        
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 50px 0;
        
        .image {
            width: 240px;
            height: 200px;
            background: url("../../image/ui/500@2x.png") no-repeat center;
            background-size: contain;
            
            &.error-404 {
                background-image: url("../../image/ui/404@2x.png");
            }
        }
        
        .title {
            font-size: 26px;
            margin-top: 50px;
        }
        
        .info {
            margin: 0 0 32px;
            text-align: center;
        }
    }
    
}
