$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1600px
);

$container-max-widths: (
        lg: 960px,
        xl: 1300px,
);

$break-xs: map-get($grid-breakpoints, 'xs');
$break-sm: map-get($grid-breakpoints, 'sm');
$break-md: map-get($grid-breakpoints, 'md');
$break-lg: map-get($grid-breakpoints, 'lg');
$break-xl: map-get($grid-breakpoints, 'xl');

$menu-height: 50px;
$menu-lg-height: 75px;
$menu-break: $break-lg;


$font-poppins: 'Poppins', sans-serif;
$font-korolev: 'Poppins', sans-serif;


$dropdownIcon: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNDIiIGhlaWdodD0iODUiIHZpZXdCb3g9IjAgMCAxNDIgODUiPjxwYXRoIGlkPSJsaW5lQUIiIGQ9Ik0gNyA3ICBMIDcxIDcxIEwgMTM1IDciIHN0cm9rZT0iIzQ0NCIgc3Ryb2tlLXdpZHRoPSIyMCIgZmlsbD0ibm9uZSIgLz48L3N2Zz4=';
