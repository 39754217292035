.profile {
    
    hr.sep {
        margin: 120px 0 70px;
    }
    
    label {
        //font-size: 15px;
        //padding: 11px 0;
        margin: 0;
    }
    
    input, textarea, select {
        width: 100%;
    }
    
    .profile-form {
        max-width: 900px;
    }

    h2.title {
        position: relative;
    }
    
    h2.title span {
        position: relative;
        z-index: 2;
    }
    
    h2.title:before {
        content: "";
        width: 30px;
        height: 30px;
        position: absolute;
        right: calc(100% - 24px);
        bottom: 1px;
        background-color: #F7EA00;
        border-radius: 50%;
        z-index: 1;
    }
    
    .confirm-submit {
        padding: 0 50px;
    }

    input.switcher {
        margin-top: 11px;
    }
    
    .btn-change-pass {
        max-width: 300px;
        margin: 0px 190px;
    }
    
    .profile-side-menu {
        font-family: $font-korolev;
        font-size: 15px;
        font-weight: 500;
        letter-spacing: 0.3px;
        position: sticky;
        top: 160px;

        .menu-item.active:before {
            content: "";
            width: 17px;
            height: 17px;
            position: absolute;
            right: calc(100% - 10px);
            bottom: 3px;
            background-color: #F7EA00;
            border-radius: 50%;
            z-index: 1;
        }
        
        .menu-item {
            position: relative;
            color: $font-color-light;
            margin-bottom: 20px;
            
            &.active, &:hover {
                color: $font-color;
                text-decoration: none;
            }

            span {
                position: relative;
                z-index: 2;
            }
        }
    }
    
    .profile-photo {
        margin-top: 50px;
        
        .photo-edit {
            padding: 0px 190px;
            margin-bottom: 60px;

            .image-preview {
                border-radius: 4px;
                background-color: transparent;
                margin-bottom: 25px;
                margin-top: 50px;
                background-size: cover;
                height: 180px;
                width: 180px;
                cursor: pointer;
                // box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
            }
        }
        
        .edit-photo-info {
            font-size: 15px;
            color: $font-color-light;
            
        }
    }
    
    .profile-info {
        margin-top: 120px;
    }
    
    .additional-info {
        padding-top: 50px;
        
        .age-info {
            margin-top: 65px;
        }
        
        .sport-list {
            .btn {
                margin: 0 3px 5px 3px;
            }
        }
    }
    
    .notifications-new-event {
        padding-top: 55px;
        
    }
    
    .notifications-switcher {
        
        padding-top: 30px;
        
        label {
            margin-bottom: 30px;
        }
        
        .btn {
            margin-top: 90px;
        }
    }
    
}

.cropper-popup {
    
    opacity: 0;
    
    &.visible {
        opacity: 1;
    }
    
    .cropper-preview {
        position: relative;
        padding-bottom: 100%;
        background-color: $font-color-light;
        
        .image-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    
    .cropper-footer {
        .btn {
            margin: 10px 0 0;
            
            @media (max-width: $break-sm) {
                width: 100%;
            }
        }
    }
    
    .croppie-container .cr-image {
        opacity: 0;
    }
    
    .cr-slider-wrap {
        
        width: 100%;
        margin: -50px auto;
        z-index: 2;
        position: relative;
        
        input, input:active {
            background: rgba($input-bg, 0.1);
        }
    }
}

.liked-event-empty {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 70px 0;
    
    .image {
        width: 180px;
        height: 180px;
        background: url("../../image/ui/liked-event@2x.png") no-repeat center;
        background-size: contain;
    }
    
    .info {
        margin: 40px 0;
        text-align: center;
    }
}

@media (max-width: 976px) {
    .profile .profile-photo .photo-edit {
        padding: 0;
    }

    .profile .btn-change-pass {
        margin:0;
    }
}


h2.liked_events {
    font-size: 48px;
    font-weight: 800;
    position: relative;
    bottom: 70px;
}

h2.liked_events span {
    position: relative;
    z-index: 2;
    text-shadow: -1px 0, 0 1px, 1px 0, 0 -1px, -1px -1px, 1px 1px, -1px 1px, 1px -1px;
}

h2.liked_events:before {
    content: "";
    width: 75px;
    height: 75px;
    position: absolute;
    right: calc(50% + 125px);
    bottom: -10px;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}


.search-events {
    box-shadow: 0px 4px 16px 0px #F8F9FA;
    padding: 10px 25px;
    width: 284px;
    text-align: center;
    font-size:17px;
    background-color: #FAE938;
    border-radius: 36px;
    font-weight: 600;
    // margin: 40px 0;
}

@media screen and (max-width: 1152px){
    h2.liked_events {
        bottom: unset !important;
    }
}