.map-container {
    position: relative;
    
    .map-legend {
        font-family: $font-korolev;
        position: absolute;
        right: 17px;
        top: 17px;
        width: 290px;
        max-height: 257px;
        border-radius: 4px;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
        background-color: #ffffff;
        padding: 10px 0;
        overflow: auto;
        
        .legend-item {
            border-left: 4px solid transparent;
            padding: 20px 0;
            
            &.active {
                border-color: #4a4a4a;
            }
            
            .legend-name {
                font-size: 24px;
                font-weight: bold;
                color: #4a4a4a;
                padding: 0 10px;
            }
            
            .legend-location {
                font-size: 12px;
                font-weight: bold;
                color: #909090;
                padding: 0 10px;
            }
        }
    }
}

.mapboxgl-popup-content {
    width: 250px;
    padding: 20px;

    .activity-popup-location-name {
        font-size: 15px;
        color: $font-color-light;
    }
}
