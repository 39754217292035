.btn {
    background-color: transparent;
    color: $font-color;
    font-size: 15px;
    font-weight: bold;

    letter-spacing: 0.47px;
    outline: none;
    border: none;
    border-radius: 15px;
    padding: 14px 20px 10px;
    min-width: 180px;
    text-align: center;
   
    cursor: pointer;
    height: 48px;
    
    &--white {
        background: #fff;
        color: $font-color-dark;
    }
    
    &:focus,
    &.focus {
        box-shadow: none;
    }
    
    & ~ .btn {
        margin-left: 6px;
    }
    
    &.btn--big {
        width: 100%;
        font-size: 18px;
        padding: 28px 26px 24px;
        line-height: 28px;
    }
    
    &.btn--small {
        min-width: 20px;
        font-size: 12px;
        padding: 7px 20px 7px;
        line-height: 18px;
        height: 30px;
    }
    
    &.btn--full {
        width: 100%;
    }
    
    &.btn--slim {
        min-width: 20px;
    }
    
    &.btn--no-padding {
        padding: 0;
    }
    
    &.btn--tag {
        font-family: $font-poppins;
        color: $btn-color-gray;
        background-color: $btn-bg-gray;
        font-size: 18px;
        padding: 12px 40px 11px 30px;
        text-align: left;
        position: relative;
        font-weight: normal;
        cursor: default !important;
        
        .btn--closer {
            display: block;
            position: absolute;
            top: 50%;
            right: 12px;
            font-size: 21px;
            height: 25px;
            width: 25px;
            text-align: center;
            line-height: 25px;
            transform: translate(0, -50%);
            cursor: pointer;
        }
        
        &.btn--category {
            color: $font-color;
            font-size: 15px;
            padding: 12px 40px 11px 30px;
            text-transform: none;
            
            &:hover {
                color: $font-color;
            }
        }
        
        &.btn--small {
            font-size: 12px;
            padding: 6px 40px 7px 20px;
            
            .btn--closer {
                font-size: 16px;
                right: 5px;
            }
        }
        
        &:hover {
            color: $btn-color-gray-hover;
            background-color: $btn-bg-gray-hover;
        }
    }
    
    &.btn--main {
        color: $btn-main-color;
        background-color: $btn-main-bg;
        text-decoration: underline;
        
        &:hover,
        &.active {
            color: $btn-main-color-hover;
            background-color: $btn-main-bg-hover;
        }
    }
    
    &.btn--yellow {
        background-color: $btn-bg-yellow;
        color: $btn-color-yellow;
        
        &:hover,
        &.active {
            color: $btn-color-yellow-hover;
            background-color: $btn-bg-yellow-hover;
        }
    }
    
    &.btn--dark {
        color: $btn-color-dark;
        background-color: $btn-bg-dark;
        
        &:hover {
            color: $btn-color-dark-hover;
            background-color: $btn-bg-dark-hover;
        }
        border-radius:45px;
    }
    
    &.btn--white {
        color: $btn-color-white;
        background-color: $btn-bg-white;
        
        &:hover {
            color: $btn-color-white-hover;
            background-color: $btn-bg-white-hover;
        }
        border-radius:45px;
    }
    
    &.btn--gray {
        color: $btn-color-gray;
        background-color: $btn-bg-gray;
        font-weight: 500;
        
        &:hover {
            color: $btn-color-gray-hover;
            background-color: $btn-bg-gray-hover;
        }
    }
    
    &.btn--trans {
        background-color: $btn-bg-trans;
        color: $btn-color-trans;
        
        &:hover,
        &.active {
            color: $btn-color-trans-hover;
            background-color: $btn-bg-trans-hover;
        }
    }
    
    &.btn--cancel {
        background-color: $btn-bg-cancel;
        color: $btn-color-cancel;
        border: 1px solid $btn-border-cancel;
        
        &:hover,
        &.active {
            color: $btn-color-cancel-hover;
            background-color: $btn-bg-cancel-hover;
            border: 1px solid $btn-border-cancel-hover;
        }
    }
    
    &.btn--icon {
        background: $btn-bg-icon no-repeat left center;
        color: $btn-color-icon;
        padding: 0 0 0 30px;
        min-width: 10px;
        height: 25px;
        line-height: 30px;
       
        
        &.small-icon {
            padding: 0 0 0 20px;
        }
        
        &:hover,
        &.active {
            color: $font-color-light;
            background-color: $btn-bg-icon-hover;
        }
        
        &.btn--share {
            background-image: url("../../image/icon/share.svg");
        }
        
        &.btn--like {
            background-image: url("../../image/icon/like.svg");
        }
        
        &.btn--liked {
            background-image: url("../../image/icon/liked.svg");
        }
        
        &.btn--report {
            background-image: url("../../image/icon/report.svg");
        }
        
        &.btn--promote {
            background-image: url("../../image/icon/promote.svg");
        }
        
        &.btn--claim {
            background-image: url("../../image/icon/claim.svg");
        }
        
        &.btn--claimed {
            background-image: url("../../image/icon/claimed.svg");
        }
        
        &.btn--edit {
            background-image: url("../../image/icon/edit.svg");
        }
        
        &.btn--map {
            background-image: url("../../image/icon/globe.svg");
        }
        
        &.btn--delete {
            background-image: url("../../image/icon/delete.svg");
        }
    }
}
