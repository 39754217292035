.asg-search {
    position: relative;
    
    &.asg-focus {
        input {
            position: relative;
            z-index: 1000;
        }
        
        .asg-dropdown {
            display: block;
        }
        
    }
    
    .asg-dropdown {
        display: none;
        position: absolute;
        padding-top: 70px;
        top: -10px;
        left: -10px;
        width: calc(100% + 20px);
        max-height: 500px;
        overflow-y: auto;
        box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
        z-index: 999;
        background: white;
        border-radius: 4px;
        
        .asg-dropdown-inner {
            padding: 10px 0;
            
            .asg-item {
                padding: 0 10px;
                cursor: pointer;
                line-height: 45px;
                font-family: $font-poppins;
                font-size: 15px;
                color: $font-color;
                
                &.selected, &:hover {
                    background-color: $gray-light;
                }
            }
        }
    }
}
