.organizer {
    &--company-name {
        margin: 60px 0 0;
        font-size: 36px;
    }

    &--link {
        font-size: 18px;
    }

    &--rate {
        margin-top: 25px;
    }

    &--description {
        margin-top: 40px;
    }

    &--contact {
        margin-top: 40px;
    }

    &--photos {
        margin-top: 60px;

        &-row {
            font-size: 0;
            margin-top: 30px;
            width: 100%;
            overflow-x: auto;

            &-inner {
                white-space: nowrap;

                img {
                    height: 160px;
                    margin-right: 10px;
                    border-radius: 4px;
                }
            }
        }
    }

    &--socials {
        margin-top: 60px;
        padding-bottom: 25px;
        border-bottom: 1px solid $border-color;

        &-item {
            font-size: 30px;
            margin-right: 30px;
        }
    }

    &--action {
        margin-top: 25px;

        .btn {
            margin-right: 50px;
        }
    }

    &--events {
        &-title {
            font-size: 24px;
        }

        &-list {
            margin-top: 30px;

            .event-box {
                margin-top: 30px;
            }
        }
    }
}

.organiser-section-stripe {
    margin: 80px 0 40px;
    padding: 120px 0;
    position: relative;

    .container {
        @media (max-width: $break-md) {
            display: flex;
            justify-content: center;
        }
    }

    // &:before {
    //     content: '';
    //     position: absolute;
    //     background: url('../../image/bg/man-running.jpg') no-repeat center;
    //     background-size: cover;
    //     left: 0;
    //     right: 0;
    //     top: 0;
    //     bottom: 0;

    //     @media (min-width: $break-md) {
    //         right: 60%;
    //     }
    // }

    .box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-flow: column;
        position: relative;
        left: 0;
        right: 0;
        bottom: -80px;
        
        height: 300px;
        align-items: end;
    }

    // .demo {
    //     background: $white url('../../image/stripe-demo.jpg') no-repeat center;
    //     background-size: contain;
    //     width: 450px;
    //     height: 390px;
    //     padding: 4px;
    //     border-radius: 4px;

    //     @media (min-width: $break-md) {
    //         right: 60%;
    //         border-radius: 4px;
    //         box-shadow: 0 0 16px rgba($dark, 0.1);
    //     }
    // }

    .description {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex-flow: column;
        justify-content: center;
    }

    .title {
        color: $mine-shaft;
        font-weight: bold;
        font-size: 26px;
        margin-bottom: 2rem;
    }

    div.show-more {
        margin-top: 90px;
    }

    div.show-more a{
        font-size: 21px;
        font-weight: 800;
        background: white;
        border: 3px solid #F7EA03;
        border-radius: 11px;
        padding: 8px 29px;
    }
}

.organiser-section-fees {
    margin: 80px 0;

    .container {
        max-width: 800px;

        @media (max-width: $break-md) {
            padding: 0 60px;
        }

        .btn {
            margin-top: 40px;
        }

        .box {
            &:not(:first-child) {
                margin-top: 40px;
            }

            &:first-child {
                @media (max-width: $break-md) {
                    margin-top: 60px;
                }
            }

            @media (max-width: $break-md) {
                padding: 0 60px;
            }
        }

        .title {
            font-size: 36px;
            font-weight: bold;
            color: $mine-shaft;
        }

        .title-b {
            color: $mine-shaft;
            font-weight: bold;
            font-size: 26px;
        }
    }
}

.title-f {
    font-size: 36px;
    font-weight: bold;
    color: $mine-shaft;
}

.part-4-b {
    padding-bottom: 100px;
}

.container-x {
    max-width: 800px;
}

.description-u {
    margin-top: 70px;
    font-weight: 700;
    font-size: 16px;
}


.organize-btn {
    border: 3px solid #F7EA03;
    border-radius: 25px;
    padding: 10px 45px;
    font-size: 17px;
    font-weight: 600;
    margin-top: 30px;
}