
.add-event {
    padding: 0 0 50px;
    
    input, textarea {
        width: 100%;
    }
    
    label {
        font-size: 15px;
        padding: 11px 0;
        margin: 0;
    }
    
    .description-block, .event-activity, .event-pictures {
        border: 1px solid $border-color;
        padding-top: 45px;
        margin-bottom: 15px;
        border-radius: 4px;
        position: relative;
        
        .delete-block {
            position: absolute;
            right: 15px;
            top: 5px;
            cursor: pointer;
            font-size: 25px;
            opacity: 0;
        }
        
        &:hover {
            .delete-block {
                opacity: 1;
            }
        }
        
        &:first-of-type {
            .delete-block {
                display: none;
            }
        }
        
    }
    
    .event-activity, .event-pictures {
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
    }
    
    .activity-category {
        .error-info {
            display: none;
            color: #d43b3b;
            font-weight: 500;
            margin: 10px 5px;
        }
        
        &.category-error {
            background-color: #ffd0d0;
            border-radius: 3px;
            
            .error-info {
                display: block;
            }
        }
    }
    
    .event-ticket {
        .remove-ticket {
            font-size: 30px;
            width: 40px;
            line-height: 50px;
            display: inline-block;
            text-align: center;
            cursor: pointer;
        }
        
        &:first-of-type {
            .remove-ticket {
                display: none;
            }
        }
    }
    
    .event-picture {
        .remove-picture {
            font-size: 30px;
            width: 40px;
            line-height: 50px;
            display: inline-block;
            text-align: center;
            cursor: pointer;
        }
    }
    
    .event-picture {
        margin-bottom: 15px;
    }
    
    .event-pictures {
        .input-error {
            display: none;
            color: #d43b3b;
            font-weight: 500;
        }
        
        .invalid-image-size {
            .input-error {
                display: block;
            }
            
            input {
                background-color: #ffd0d0;
            }
        }
    }
}
