
.input-separator {
    width: 6px !important;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 6px !important;
    min-height: 1px;
}

.form-item {
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    
    &.item-row {
        flex-direction: column;
        @media (min-width: $break-md) {
            flex-direction: row;
            .form-label {
                width: 200px;
            }
            .form-input {
                max-width: calc(100% - 200px);
            }
        }
    }
    
    &.item-column {
        flex-direction: column;
        
        .form-label {
            justify-content: flex-start;
            line-height: 32px;
            
            .label-tooltip {
                margin: 6px 0 6px 16px;
            }
        }
    }
    
    &.with-menu {
        justify-content: space-between;
    }
    
    &.form-error {
        position: relative;
        
        .form-input {
            padding-bottom: 20px;
            
            &:after {
                display: block;
                content: attr(data-error);
                color: $red;
                position: absolute;
                bottom: 0;
                font-size: 12px;
            }
        }
    }
    
    .form-label {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        padding-right: 20px;
        margin: 0;
        line-height: 48px;
        
    }
    
    .form-input {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        
        > *, .input-wrapper > * {
            width: 100%;
        }
        
        .input-wrapper {
            position: relative;
            
            .input-loader-wrapper {
                position: absolute;
                height: 48px;
                max-width: 48px;
                top: 0;
                right: 0;
                padding-right: 2px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                
                .input-loader {
                    background: url("../../image/loader.gif") center no-repeat;
                    background-size: 100%;
                }
            }
        }
        
        .before-input {
            line-height: 48px;
            width: auto;
            padding-right: 5px;
            font-size: 12px;
        }
    }
    
    .form-radio {
        width: 24px;
        height: 24px;
        border: solid 1px #b6b6b6;
        cursor: pointer;
        border-radius: 12px;
        background: #ffffff;
        padding: 5px;
        
        &.checked {
            .form-radio-dot {
                background: #4a4a4a;
            }
        }
        
        .form-radio-dot {
            width: 12px;
            height: 12px;
            border-radius: 6px;
            background: #f7f7f7;
        }
    }
    
    .checkbox-label {
        line-height: 48px;
        margin-left: 10px;
        font-size: 14px;
        color: #4a4a4a;
        cursor: pointer;
    }
    
    .form-checkbox {
        width: 24px;
        height: 24px;
        border-radius: 2px;
        border: solid 1px #e3e3e3;
        cursor: pointer;
        padding: 2px;
        margin: 12px 0px;
        
        &.checked {
            border: solid 1px #4a4a4a;
            
            .form-checkbox-tick {
                opacity: 1;
            }
        }
        
        .form-checkbox-tick {
            opacity: 0;
            width: 18px;
            height: 18px;
            border-radius: 1px;
            background-color: #4a4a4a;
            position: relative;
            
            &:before {
                content: '';
                display: block;
                position: absolute;
                top: 3px;
                left: 6px;
                border: 1px solid white;
                width: 5px;
                height: 10px;
                border-left-color: transparent;
                border-top-color: transparent;
                transform: rotate(45deg);
            }
        }
    }
    
    .image-picker {
        
        width: 260px;
        height: 130px;
        border-radius: 3px;
        background-size: cover;
        background-position: center;
        background-color: $input-bg;
        
        input {
            display: none;
        }
        
        .image-picker-layer {
            width: 100%;
            height: 100%;
            opacity: 0;
            background-color: rgba(#000, 0.3);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 3px;
            cursor: pointer;
            transition: opacity .3s ease;
        }
        
        &:hover, &.empty {
            .image-picker-layer {
                opacity: 1;
            }
        }
    }
    
    .gallery-wrapper {
        overflow: hidden;
        
        .image-picker {
            display: block;
            float: left;
            width: 130px;
            height: 130px;
            margin: 0 16px 16px 0;
            cursor: pointer;
            
            .image-picker-layer {
                opacity: 1;
                border-radius: 3px;
                background-color: #faf9f9;
                position: relative;
                padding-top: 20px;
                user-select: none;
                
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 40px;
                    left: 0;
                    height: 20px;
                    width: 100%;
                    background: url("../../image/icon/cross.svg") no-repeat center;
                }
                
            }
        }
        
        .gallery-item {
            float: left;
            background-color: $input-bg;
            width: 130px;
            height: 130px;
            margin: 0 16px 16px 0;
            background-size: cover;
            background-position: center;
            border-radius: 3px;
            
            .gallery-item-layer {
                width: 100%;
                height: 100%;
                opacity: 0;
                border-radius: 3px;
                background-color: rgba(#000, .3);
                transition: opacity .3s ease;
                position: relative;
                
                &:hover {
                    opacity: 1;
                }
                
                .remove-item {
                    position: absolute;
                    right: 4px;
                    top: 4px;
                    width: 16px;
                    height: 16px;
                    background-color: #4a4a4a;
                    border-radius: 8px;
                    cursor: pointer;
                    
                    &:after, &:before {
                        content: '';
                        position: absolute;
                        height: 1px;
                        width: 10px;
                        background: white;
                        top: 7px;
                        left: 3px;
                        transform: rotate(45deg);
                    }
                    
                    &:before {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }
    
    .form-autocomplete {
        position: relative;
        
        .form-autocomplete-list {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            background: white;
            border-radius: 4px;
            z-index: 99;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.3);
            border-top: solid 1px $input-border;
            max-height: 300px;
            overflow-y: auto;
            
            .form-autocomplete-item {
                padding: 10px 15px;
                cursor: pointer;
                
                &:hover, &.selected {
                    background-color: $input-bg;
                }
            }
        }
    }
}



.option-menu {
    width: 34px;
    height: 34px;
    border-radius: 17px;
    cursor: pointer;
    position: relative;
   
    background: url("../../image/icon/dots.svg") no-repeat center;
    
    &:hover {
        background-color: #ededed;
    }
    
    .option-menu-popup {
        opacity: 0;
        position: absolute;
        right: -9px;
        top: calc(6px + 100%);
        box-shadow: 0 0 24px 0 #00000017;
        border-radius: 4px;
        z-index: 10;
        transition: opacity .3s ease;
        
        &.visible {
            opacity: 1;
        }
        
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 20px;
            left: 0;
            top: -20px;
        }
        
        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #fff;
            right: 20px;
            top: -5px;
        }
        
        .option-item {
            white-space: nowrap;
            padding: 12px 16px;
            font-size: 12px;
            border-radius: 4px;
            background-color: #ffffff;
            display: block;
            min-width: 130px;
            
            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
    
    &.on-hover {
        .option-menu-popup {
            display: none;
            opacity: 1;
        }
        
        &:hover {
            .option-menu-popup {
                display: block;
            }
        }
    }
    
    &.center {
        .option-menu-popup {
            right: 50%;
            transform: translate(50%, 0);
            
            &:after {
                right: 50%;
                transform: translate(50%, 0);
            }
        }
    }
}
.option-arrow {
    width: 34px;
    height: 34px;
    border-radius: 17px;
    cursor: pointer;
    position: relative;
    background: url("../../image/icon/arrow_down.svg") no-repeat center;
    
    &:hover {
        background-color: #ededed;
    }
    
    .option-menu-popup {
        opacity: 0;
        position: absolute;
        right: -9px;
        top: calc(6px + 100%);
        box-shadow: 0 0 24px 0 #00000017;
        border-radius: 4px;
        z-index: 10;
        transition: opacity .3s ease;
        
        &.visible {
            opacity: 1;
        }
        
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 20px;
            left: 0;
            top: -20px;
        }
        
        &:after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
            border-bottom: 5px solid #fff;
            right: 20px;
            top: -5px;
        }
        
        .option-item {
            white-space: nowrap;
            padding: 12px 16px;
            font-size: 12px;
            border-radius: 4px;
            background-color: #ffffff;
            display: block;
            min-width: 130px;
            
            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
    
    &.on-hover {
        .option-menu-popup {
            display: none;
            opacity: 1;
        }
        
        &:hover {
            .option-menu-popup {
                display: block;
            }
        }
    }
    
    &.center {
        .option-menu-popup {
            right: 50%;
            transform: translate(50%, 0);
            
            &:after {
                right: 50%;
                transform: translate(50%, 0);
            }
        }
    }
}

.label-tooltip {
    position: relative;
    cursor: pointer;
    
    &.q-mark {
        margin: 14px 0 14px 16px;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid #d1d1d1;
        
        &:before {
            content: '?';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            line-height: 18px;
            text-align: center;
            color: #d1d1d1;
            font-size: 14px;
        }
    }
    
    &.inline {
        display: inline-block;
        margin: 0;
        margin-left: 5px;
    }
    
    .label-tooltip-popup {
        font-family: $font-poppins;
        padding: 16px;
        position: absolute;
        top: 25px;
        left: calc(50% - 90px);
        width: 180px;
        border-radius: 3px;
        box-shadow: 0 0 24px 0 #00000017;
        background-color: #ffffff;
        font-size: 10px;
        line-height: 1.9;
        opacity: 0;
        z-index: 10;
        transition: opacity .3s ease;
        cursor: default;
        text-transform: none;
        color: $font-color;
        
        &.align-left{
            text-align: left;
        }
        
        &.visible {
            transition: opacity .3s ease;
            opacity: 1;
        }
        
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -5px;
            left: calc(50% - 5px);
            border-bottom: 5px solid white;
            width: 0;
            height: 0;
            border-left: 5px solid transparent;
            border-right: 5px solid transparent;
        }
    }
    
    &.on-hover {
        .label-tooltip-popup {
            display: none;
            opacity: 1;
        }
        
        &.force-show {
            .label-tooltip-popup {
                display: block;
            }
        }
        
        &:hover {
            .label-tooltip-popup {
                display: block;
            }
        }
    }
}

.save-button {
    margin-top: 50px;
}

.save-button-sticky {
    position: sticky;
    bottom: 0;
   
    z-index: 20;
    padding: 20px 0;
}
