.event {
    .event-duplicate {
        /*max-width: 500px;*/
        margin: 20px auto auto;
        text-align: center;
        
        h2 {
            font-size: 24px;
        }
        
        p {
           /* color: $font-color-light;*/
            font-size: 15px;
            max-width: 500px;
            margin: 15px auto auto;
        }
    }
    
    .event-form {
        
        label {
            line-height: 50px;
        }
        
        input, textarea, select {
            width: 100%;
        }
        
        .event-cover {
            margin-top: 60px;
            padding-bottom: 50%;
            background: no-repeat center;
            background-size: cover;
            position: relative;
            display: block;
            border-radius: 4px;
            overflow: hidden;
            
            &.error {
                border: 2px solid $red;
                
            }
            
            .layer {
                position: absolute;
                background: no-repeat center;
                background-size: cover;
                display: block;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                
                .layer-hover {
                    position: absolute;
                    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.63));
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    transition: opacity .3s ease;
                    cursor: pointer;
                    padding: 20px;
                    
                    .btn {
                        color: #fff;
                    }
                }
                
                &:hover {
                    .layer-hover {
                        opacity: 1;
                    }
                }
            }
            
            .select-info {
                background: $gray-light url("../../image/icon/upload.svg") no-repeat center top 100px;
                text-align: center;
                
                .info-label {
                    display: inline-block;
                    margin-top: 200px;
                    font-size: 24px;
                    color: $font-color-light;
                    font-family: $font-korolev;
                }
            }
        }
        
        .general-info {
            margin-top: 60px;
        }
        
        .add-section {
            margin-top: 60px;
            display: flex;
            width: 100%;
            height: 70px;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            border-radius: 4px;
            border: 1px solid $border-color;
            cursor: pointer;
            
            &.add-activity {
                margin-top: 30px;
                border-color: $dark;
                background-color: $dark;
                
                .section-label {
                    color: #fff;
                }
            }
            
            .section-icon {
                display: inline-block;
                height: 24px;
                width: 24px;
                
                &.ticket-icon {
                    background: url("../../image/icon/tickets.svg") no-repeat center;
                }
            }
            
            .section-label {
                font-size: 20px;
                font-family: $font-korolev;
                color: $font-color-light;
            }
        }
        
        .descriptions {
            margin-top: 70px;
            
            .description {
                margin-top: 30px;
                
                &:first-of-type:nth-last-of-type(2) {
                    .delete-section {
                        display: none;
                    }
                }
                
                .description-title {
                    font-size: 24px;
                    margin-bottom: 25px;
                }
            }
        }
        
        .gallery {
            overflow: hidden;
        }
        
        .location {
            .btn--map {
                position: absolute;
                right: 30px;
                top: 12px;
                z-index: 1002;
            }
        }
        
        .tickets {
            margin-top: 10px;
            
            .ticket {
                margin-top: 70px;
                padding-left: 35px;
                position: relative;
                
                &:before {
                    content: '';
                    position: absolute;
                    display: block;
                    height: 15px;
                    width: 15px;
                    background: $font-color-light;
                    border-radius: 50%;
                    left: 0;
                    top: 4px;
                }
                
                &:first-of-type:nth-last-of-type(2) {
                    .delete-section {
                        display: none;
                    }
                }
                
                .ticket-title {
                    font-size: 24px;
                    margin-bottom: 25px;
                }
            }
        }
        
        .activities {
            margin-top: 10px;
            
            .activity {
                margin-top: 90px;
                
                &:first-of-type:nth-last-of-type(2) {
                    .delete-activity {
                        display: none;
                    }
                }
                
                .activity-title {
                    font-size: 36px;
                    margin-bottom: 50px;
                }
            }
        }
    }
    
    .add-event-process {
        position: fixed;
        display: none;
        background: rgba(255, 255, 255, 0.6);
    }
}

*.error {
    & + .input-error {
        display: block;
    }
    
}

.error-size {
    .input-error-size {
        display: block;
    }
}

.error-empty {
    .input-error-empty {
        display: block;
    }
}

.input-error {
    display: none;
    background: url("../../image/icon/error.svg") no-repeat left top;
    padding-left: 25px;
    color: $red;
    font-size: 14px;
    line-height: 1.8;
}
