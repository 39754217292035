@import '~react-phone-input-2/lib/style.css';

.react-tel-input {
    input {
        &.phone-input {
            width: 100%;
            height: 48px;
            border-radius: 4px;
            background-color: #faf9f9;
            border-color: #e5e5e5;
        }
    }
    
    .flag-dropdown {
        background-color: #faf9f9;
        border-radius: 4px 0 0 4px;
        border-color: #e5e5e5;
        
        &:hover {
            background-color: #faf9f9;
        }
    }
}
