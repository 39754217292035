$intend: 65px;

.comment {
    margin-top: 60px;
    
    .fos_comment_user_avatar {
        width: 50px;
        height: 50px;
        border-radius: 25px;
        overflow: hidden;
        margin-right: 15px;
    }
    
    .fos_comment_intend_box {
        padding-left: $intend;
    }
    
    .fos_comment_comment_show {
        border-top: 1px solid $border-color;
        padding-top: 50px;
        margin-top: 50px;
        
        &.fos_comment_comment_depth_1 {
            padding-top: 40px;
            margin-top: 40px;
        }
        
        .fos_comment_comment_replies {
            padding-left: $intend;
            max-height: 0;
            overflow: hidden;
            transition: height .5s ease;
        }
        
        .fos_expand_replay {
            letter-spacing: 0.3px;
            font-family: $font-korolev;
            font-size: 14px;
            font-weight: bold;
            background: url("../../image/icon/comment.svg") no-repeat left center;
            cursor: pointer;
            padding: 20px 30px;
            position: relative;
            display: inline-block;
            
            &:after {
                content: '';
                position: absolute;
                height: 8px;
                width: 8px;
                border-right: 1px solid #4a4a4a;
                border-top: 1px solid #4a4a4a;
                right: 10px;
                top: 23px;
                transform: rotate(135deg);
            }
        }
        
        &.fos_open_replay {
            .fos_comment_comment_replies {
                padding-left: $intend;
                max-height: 99999px;
            }
            
            .fos_expand_replay {
                &:after {
                    transform: rotate(-45deg);
                    top: 27px;
                }
            }
        }
        
        .fos_comment_comment_metas {
            display: flex;
            flex-direction: row;
            
            .fos_comment_user_info {
                flex-grow: 1;
                
                .comment-user-name {
                    font-family: $font-korolev;
                    font-size: 20px;
                    font-weight: bold;
                }
                
                .comment-date {
                    font-size: 14px;
                    color: $font-color-light;
                    line-height: 0.5;
                }
            }
            
            .fos_comment_action {
                width: 30px;
                height: 25px;
                cursor: pointer;
                position: relative;
                background: url("../../image/icon/dots.svg") no-repeat right top;
                
                .action-dropdown {
                    display: none;
                    position: absolute;
                    right: -10px;
                    top: 21px;
                    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.1);
                    padding: 5px 10px;
                    border-radius: 4px;
                    text-align: right;
                    z-index: 10;
                    
                    &:after {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid #fff;
                        right: 11px;
                        top: -10px;
                    }
                    
                    &:before {
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-left: 14px solid transparent;
                        border-right: 14px solid transparent;
                        border-bottom: 14px solid rgba(208, 208, 208, 0.1);
                        right: 7px;
                        top: -14px;
                    }
                    
                    span {
                        white-space: nowrap;
                    }
                }
                
                &:hover {
                    .action-dropdown {
                        display: block;
                    }
                }
            }
        }
        
        .fos_comment_comment_content {
            padding-left: $intend;
            
            .fos_comment_comment_body {
                margin-top: 5px;
                font-size: 15px;
                line-height: 1.93;
            }
        }
    }
    
    .fos_comment_comment_new_form {
        margin-top: 45px;
    }
    
    .fos_comment_comment_new_form, .fos_comment_comment_edit_form {
        .fos_comment_comment_new_body {
            display: flex;
            flex-direction: row;
            
            .fos_comment_comment_new_content {
                flex-grow: 1;
                position: relative;
                
                textarea {
                    width: 100%;
                    min-height: 100px;
                    max-height: 300px;
                    height: 100px;
                    font-size: 15px;
                    padding: 20px 80px 20px 15px;
                }
                
                .fos_comment_submit {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 70px;
                    height: 99%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 10;
                    flex-direction: column;
                    
                    button {
                        background: transparent;
                        border: none !important;
                        outline: none !important;
                        cursor: pointer;
                    }
                    
                    .fos-loader {
                        width: 50px;
                        height: 50px;
                        background: url("../../image/loader.gif") no-repeat center;
                        background-size: 100%;
                        display: none;
                    }
                }
                
                &.comment_replay {
                    textarea {
                        height: 60px;
                        min-height: 60px;
                        padding: 10px 80px 10px 15px;
                    }
                }
            }
        }
        
        &.fos_loading {
            .fos_comment_comment_new_body {
                .fos_comment_comment_new_content {
                    .fos_comment_submit {
                        button {
                            display: none;
                        }
                        
                        .fos-loader {
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
