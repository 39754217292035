@import "~aos/src/sass/easing";

[data-aos] {
    @for $i from 1 through 60 {
        body[data-aos-duration='#{$i * 50}'] &,
        &[data-aos][data-aos-duration='#{$i * 50}'] {
            &:after {
                transition-duration: #{$i * 50}ms;
            }
        }
        
        body[data-aos-delay='#{$i * 50}'] &,
        &[data-aos][data-aos-delay='#{$i * 50}'] {
            &:after {
                transition-delay: 0s;
            }
            
            &.aos-animate {
                &:after {
                    transition-delay: #{$i * 50}ms;
                }
            }
        }
    }
}

[data-aos] {
    @each $key, $val in $aos-easing {
        body[data-aos-easing="#{$key}"] &,
        &[data-aos][data-aos-easing="#{$key}"] {
            &:after {
                transition-timing-function: $val;
            }
        }
    }
}

[data-aos^='cover'][data-aos^='cover'] {
    position: relative;
    
    &:after {
        content: '';
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #ffffff;
        transition: all .3s ease;
    }
    
}

$scale-end: 0.0001;

[data-aos='cover-up'] {
    &:after {
        transform-origin: 100% 0;
    }
    
    &.aos-animate {
        &:after {
            transform: scaleY($scale-end);
        }
    }
}

[data-aos='cover-down'] {
    &:after {
        transform-origin: 0 100%;
    }
    
    &.aos-animate {
        &:after {
            transform: scaleY($scale-end);
        }
    }
}

[data-aos='cover-right'] {
    &:after {
        transform-origin: 100% 0;
    }
    
    &.aos-animate {
        &:after {
            transform: scaleX($scale-end);
        }
    }
}

[data-aos='cover-left'] {
    &:after {
        transform-origin: 0 0;
    }
    
    &.aos-animate {
        &:after {
            transform: scaleX($scale-end);
        }
    }
}

