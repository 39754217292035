.pricing {
    main {
        padding: 0;
    }

    .header {
        position: relative;
        padding-bottom: 26%;
        background: url('../../image/bg/header-pricing.jpg') no-repeat center top;
        background-size: cover;
        min-height: 350px;

        @media (min-width: $break-xl) {
            background-image: url('../../image/bg/header-pricing@2x.jpg');
        }

        .heading {
            margin-top: 3rem;
            color: $white;
            font-weight: bold;
            font-size: 48px;
        }

        .inner {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .gradient {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
        }

        .heading {
            font-weight: bold;
            color: $white;
            font-size: 42px;
            text-align: center;
            display: flex;
            justify-content: center;
            margin-top: 7rem;

            @media (min-width: $break-md) {
                font-size: 56px;
            }

            .static {
                margin-right: 16px;
            }

            .animated {
                text-align: left;
                width: 300px;
                height: 150px;
                opacity: 0;
                transition: opacity 0.3s;

                @media (min-width: $break-md) {
                    width: 400px;
                }

                &.visible {
                    opacity: 1;
                }

                &.hidden {
                    display: none;
                }
            }

            .animated,
            .static {
                display: block;
            }

            strong {
                color: $yellow;
                font-weight: bold;
            }
        }
    }

    .section1 {
        background: url('../../../public/images/transaction-fee-background.png');
        background-repeat: no-repeat;
        background-size: contain;
        height: 100%;
        width: 100%;
        padding-bottom: 218px;
        // padding: 50px 0 120px;

        // .container {
        //     display: flex;
        //     flex-flow: column;
        //     align-items: center;
        // }

        .container {
            padding-top: 75px;
        }
        .title {
            color: #242424;
            font-size: 36px;
            font-weight: bold;
            text-align: left;
        }

        .description {
            color: #242424;
            margin-top: 15px;

            @media (min-width: $break-md) {
                align-self: flex-end;
            }

            ul {
                list-style-position: inside;
                padding: 0;
                padding-left:20px;
            }
        }


        .transaction-box-container {
            width: 80%; 
            display:flex; 
            align-items:center; 
            justify-content:center; 
            gap:20px;
            position: relative;
            left: 150px;
            top:150px;
        }

        .transaction-box {
            display:flex;
            align-items:center;
            justify-content:space-between;
            width: 70%;
            padding:20px;
            border-radius:16px;
            background-color:#FFF;
            box-shadow: 0px 20px 27px 0px #0000000D;
            max-height: 680px;
            min-height: 325px;
        }

        .transaction-text {
            width:70%;
            display: flex;
            flex-direction: column;
            align-self: stretch;
            justify-content: space-around;
        }
        
    }

    .section2 {
        // .container {
        //     position: relative;
        //     height: 180px;
        // }

        .box {
            display: flex;
            flex-flow: column;
            // align-items: center;
            // justify-content: center;
            position: relative;
            // top: -60px;
            left: 0;
            right: 0;
            bottom: -80px;
           
            height: 300px;
        }

        .section2-img {
            @media (max-width: $break-md) {
                margin-top: 110px;
            }
        }
        
        .title {
            color: $mine-shaft;
            font-size: 36px;
            font-weight: bold;
            text-align: left;
            margin-bottom:85px;
        }

        .description {
            color: #67748E;
            text-align: left;
            max-width: 430px;
            margin-bottom:50px;
        }
    }

    .section3 {
        padding: 120px 0;
        position: relative;

        // .container {
        //     @media (max-width: $break-md) {
        //         display: flex;
        //         justify-content: center;
        //     }
        // }

        // &:before {
        //     content: '';
        //     position: absolute;
        //     background: url('../../image/bg/man-running.jpg') no-repeat center;
        //     background-size: cover;
        //     left: 0;
        //     right: 0;
        //     top: 0;
        //     bottom: 0;
        //     z-index: -1;

        //     @media (min-width: $break-md) {
        //         right: 60%;
        //     }
        // }

        .box {
            display: flex;
            flex-flow: column;
            // align-items: center;
            // justify-content: center;
            position: relative;
            // top: -60px;
            left: 0;
            right: 0;
            bottom: -80px;
            
            height: 300px;
            align-items: end;
        }

        // .demo {
        //     background: $white url('../../image/stripe-demo.jpg') no-repeat center;
        //     background-size: contain;
        //     width: 450px;
        //     height: 390px;
        //     padding: 4px;

        //     @media (min-width: $break-md) {
        //         right: 60%;
        //         border-radius: 4px;
        //         box-shadow: 0 0 16px rgba($dark, 0.1);
        //     }
        // }

        .description {
            display: flex;
            flex-flow: column;
            justify-content: center;
            // align-items: center;

            @media (max-width: $break-md) {
                margin-top: 50px;
                padding-bottom: 50px;
            }

            .title {
                font-size: 32px;
                margin-bottom:2rem;
                font-weight: 700;
                align-self: start;
            }

            .btn {
                align-self: flex-start;
            }

            .show-more a{
                font-size: 24px;
                font-weight: 800;
                background: white;
                border: 3px solid #F7EA03;
                border-radius: 11px;
                padding: 8px 29px;
                margin-top: 6rem;
                float:left;
            }
        }

        .title {
            color: $mine-shaft;
            font-weight: bold;
            font-size: 26px;
            margin-bottom: 16px;
        }
    }

    .section4 {
        .title {
            font-size: 36px;
            font-weight: bold;
            color: $mine-shaft;
        }

        .container {
            padding: 120px 0;

            // @media (min-width: $break-sm) {
            //     background: url('../../image/demo/commission.jpg') no-repeat bottom;
            // }

            // @media (min-width: $break-sm) and (max-width: $break-md) {
            //     background-size: auto 550px;
            //     padding-bottom: 560px;
            // }

            // @media (min-width: $break-md) {
            //     background-size: 60% auto;
            //     background-position: right bottom;
            // }
           

            .box {
                display: flex;
                flex-flow: column;
                // align-items: center;
                // justify-content: center;
                position: relative;
                // top: -60px;
                left: 0;
                right: 0;
                bottom: -80px;
              
                height: 300px;
                align-items: start;
            }

            .title {
                color: $mine-shaft;
                font-size: 36px;
                font-weight: bold;
                text-align: left;
                margin-bottom:85px;
            }
    
            .description {
                text-align: left;
                max-width: 430px;
                margin-bottom:50px;
            }

            .section4-img {
                left: 130px;
            }
        }

        .summary {
            @media (max-width: $break-md) {
                margin: 0 auto;
            }

            @media (min-width: $break-md) {
                margin: 90px 0 45px;
                padding: 0 30px 0 0;
                width: 40%;
            }

            @media (min-width: $break-lg) {
                margin: 180px 0 90px;
                padding: 0 30px 0 0;
            }

            @media (min-width: $break-xl) {
                margin: 240px 0 120px;
                padding: 0 50px 0 0;
            }

            .description {
                @media (min-width: $break-md) {
                    margin-top: 70px;
                }
            }

            .title {
                font-weight: bold;
                color: $mine-shaft;
                line-height: 1.54;
                font-size: 26px;
            }
        }

        .items {
            display: flex;
            margin-top: 100px;
            align-items: flex-start;

            @media (max-width: $break-md) {
                flex-flow: column;
                align-items: center;
            }

            @media (min-width: $break-md) {
                justify-content: space-evenly;
            }
        }

        .item {
            align-items: stretch;
            display: flex;
            flex-flow: column;
            justify-content: center;
            max-width: 270px;

            .description {
                margin-top: 20px;
            }

            .icon {
                align-self: center;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                height: 120px;
                width: 120px;

                &.ticket {
                    background-image: url('../../image/ui/ticket-2.png');

                    @media (min-width: $break-xl) {
                        background-image: url('../../image/ui/ticket-2@2x.png');
                    }
                }

                &.payment {
                    background-image: url('../../image/ui/payment.png');

                    @media (min-width: $break-xl) {
                        background-image: url('../../image/ui/payment@2x.png');
                    }
                }
            }

            .title {
                margin-top: 40px;
                font-size: 16px;
                font-weight: bold;
                color: $mine-shaft;
            }
        }
    }

    .section5 {
        padding: 120px 0;

        .container {
            // display: flex;

            // @media (min-width: $break-sm) {
            //     background: url('../../image/demo/tickets.png') no-repeat;
            // }

            // @media (min-width: $break-sm) and (max-width: $break-md) {
            //     padding-bottom: 630px;
            // }

            // @media (min-width: $break-md) {
            //     padding: 240px 0;
            // }

            // @media (max-width: $break-md) {
            //     background-position: bottom;
            //     background-size: auto 600px;
            // }

            // @media (min-width: $break-md) {
            //     background-position: left;
            //     background-size: 55% auto;
            // }

            // @media (min-width: $break-md) {
            //     justify-content: flex-end;
            // }
        }

        // .box {
        //     @media (min-width: $break-md) {
        //         width: 40%;
        //     }
        // }

        .box {
            display: flex;
            flex-flow: column;
            // align-items: center;
            // justify-content: center;
            position: relative;
            // top: -60px;
            left: 0;
            right: 0;
            bottom: -80px;
           
            height: 300px;
        }

        .title {
            font-size: 36px;
            font-weight: bold;
            color: $mine-shaft;
        }

        .description {
            margin-top: 16px;
        }

        .contact-us {
            margin-top: 100px;
            font-size: 20px;
            padding: 16px 36px;
            border-left: 8px solid $yellow;
            box-shadow: 0px 4px 28.7px 0px #F8F9FA;
            border-radius: 6px;
        }
    }

    .section6 {
        padding: 120px 0 30px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 170px;
            z-index: -1;
            background: url('../../image/bg/footer-pricing.jpg') no-repeat;
            background-size: cover;

            @media (min-width: $break-md) {
                background-image: url('../../image/bg/footer-pricing@2x.jpg');
            }
        }

        .box {
            padding: 70px 0;
        }

        .container {
            align-items: center;
           
            display: flex;
            justify-content: center;
            margin-top: 150px;
        }

        .title {
            text-align: center;
            color: $dark;
            font-size: 42px;
            font-weight: bold;
        }

        .description {
            text-align: center;
        }
    }
}


h2.pricing_title {
    font-size: 48px;
    font-weight: 800;
    position: relative;
    bottom: 90px;
}

h2.pricing_title span {
    position: relative;
    z-index: 2;
    text-shadow: 0px 0, 0 1px, 0px 0, 0 0px, 0px -1px, 1px 0px, -1px 0px, 1px -1px;
}

h2.pricing_title:before {
    content: "";
    width: 56px;
    height: 56px;
    position: absolute;
    right: calc(50% + 45px);
    bottom: 0px;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}


@media (max-width: 976px) {
    .pricing .section1 .transaction-box-container {
        width: 100% !important;
        flex-direction: column;
        left:unset !important;
        right: unset !important;
    }

    .pricing .section1 .transaction-box {
        flex-direction: column;
        width: 100%;
    }

    .pricing .section1 .transaction-text {
        width: 100%;
    }

}

@media (max-width: 650px) {
    .pricing .section1 .container {
        padding-top: 0px !important;
    }

    .pricing .section1 {
        background-image: unset !important;
    }

    .pricing .section4 .container .section4-img {
        left: unset !important;
        margin-top:100px;
    }

    .pricing .section5 {
        padding: 0 !important;
        padding-bottom: 120px !important;
    }
}

@media screen and (max-width: 1152px){
    h2.pricing_title {
        bottom: unset !important;
    }
}