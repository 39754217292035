.event-box {
    // max-width: 450px;
    margin: auto;
    
    &:hover, &.hover {
        .event-cover {
            .cover-hover {
                opacity: 1;
            }
        }
    }
    
    .event-cover {
        position: relative;
        
        .event-figure {
            padding-bottom: 51%;
            background-size: cover;
            background-position: center;
            border-radius: 4px;
        }
        
        .event-label {
            position: absolute;
            top: 10px;
            left: 0;
            
            .evt-label {
                float: left;
                background-color: #fff;
                border-radius: 0 4px 4px 0;
                font-size: 13px;
                line-height: 13px;
                padding: 12px 17px 6px 17px;
                letter-spacing: 0.5px;
                height: 33px;
                
                &.label-past {
                    color: $font-color;
                    background-color: #fff;
                }
                
                &.label-new {
                    color: #fff;
                    background-color: $dark;
                }
                
                &.label-promoted {
                    color: $yellow;
                    background-color: $dark;
                }
            }
        }
        
        .cover-hover {
            border-radius: 4px;
            transition: opacity .3s ease;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            
            span {
                line-height: 45px;
                font-size: 45px;
                font-weight: 500;
                letter-spacing: 4.6px;
                color: #f8e71c;
            }
        }
    }
    
    .event-data {
        padding: 20px 0 0;
        
        .event-date {
            font-size: 14px;
            color: $font-color-light;
            
            .tag {
               
                color: #50e3c2;
                font-weight: bold;
            }
        }
        
        .event-name {
            margin: 10px 0 0;
            font-size: 20px;
            height: 50px;
            overflow: hidden !important;
            max-height: 50px !important;
            text-overflow: ellipsis !important;
            display: -webkit-box !important;
            -webkit-line-clamp: 2 !important;
            -webkit-box-orient: vertical;
        }
        
        .event-description {
            margin-top: 5px;
            font-size: 14px;
            color: $font-color-light;
            height: 45px;
            overflow: hidden !important;
            max-height: 40px !important;
            text-overflow: ellipsis !important;
            display: -webkit-box !important;
            -webkit-line-clamp: 2 !important;
            -webkit-box-orient: vertical;
        }
        
        .event-tags {
            color: $font-color-light;
            margin-top: 15px;
            position: relative;
            
            .btn {
                margin-top: 5px;
            }
        }
    }
    
    &.box-horizontal {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        max-width: 100%;
        
        .event-cover {
            .event-figure {
                padding-bottom: 60%;
                width: 275px;
            }
        }
        
        .event-data {
            flex-grow: 1;
            padding: 0 30px;
        }
    }
}

.event-list {
    .event-box {
        margin-top: 50px;
        @media (min-width: $break-md) {
            margin-top: 70px;
        }
    }
    
}

#form-search-container form{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #faf9f9;
    border-radius: 50px;
    overflow: hidden;
    // box-shadow: 0 0 40px 10px rgb(0, 0, 0, .13);
    box-shadow: 0px 4px 33.8px 4px #0000001F;
}
#form-search-container form input {
    border: 0;
    width: calc((100% - 100px) / 4);
    padding: 20px;
    font-size: 18px;
   
    height: 100px;
    text-align: center;
}
#form-search-container form .input__locationType , #form-search-container form .input__sportType , #form-search-container form .input__dateRange {
    border: 0;
    width: calc((100% - 100px) / 4);
   
   
    text-align: center;
}
#form-search-container form input::placeholder{
    color: #d5d5d5;
}
#date-formatted{width:  100% !important;}

.placeholder--select{
    border:0px !important;
  
}

.placeholder--select.empty{
    color: #d5d5d5 !important;
    font-size: 18px;
}
#form-search-container  form  button{
    width: 100px;
    height: 100px;
    background: #F7EA00 url('../../images/search.svg') no-repeat center;
    background-size: 35px;
    border: 0;
    border-radius: 50%;
}