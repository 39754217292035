.ol-zoom {
    bottom: 25px;
    right: 8px;
    top: auto;
    left: auto;
    padding: 0;
    
    button {
        display: block;
        padding: 0;
        text-decoration: none;
        text-align: center;
        border: none;
        cursor: pointer;
        margin: 0;
        color: $font-color-light;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        background-color: #ffffff;
        font-size: 24px;
        font-weight: 500;
        outline: none;
        line-height: 44px;
        
        &:hover, &:focus {
            background-color: #e6e6e6;
        }
    }
    
    .ol-zoom-in {
        border-radius: 3px 3px 0 0;
    }
}

.ol-full-screen {
    bottom: 110px;
    right: 8px;
    top: auto;
    left: auto;
    padding: 0;
    
    button {
        display: block;
        padding: 0;
        border: none;
        cursor: pointer;
        margin: 0;
        color: $font-color-light;
        width: 40px;
        height: 40px;
        border-radius: 4px;
        font-weight: 500;
        outline: none;
        font-size: 0 !important;
        background: #ffffff url("../../image/map/full.png") no-repeat center;
        
        &:hover, &:focus {
            background-color: #e6e6e6;
        }
    }
}
