.price-filter-container {
    font-family: $font-poppins;
    color: $font-color;
    border: none;
    cursor: default;
    
    .price-filter-wrapper {
        margin: 20px auto;
        width: 100%;
        max-width: 420px;
        
        h3 {
            color: white;
        }
        
        .price-range {
            overflow: hidden;
            margin-top: 20px;
            
            .price-select {
                display: inline-block;
                width: 49%;
                max-width: 200px;
                float: left;
                
                &:last-child {
                    float: right;
                }
                
                select {
                    width: 100%;
                }
            }
        }
        
        .price-filter-free {
            margin-top: 30px;
        }
        
        .buttons {
            margin-top: 50px;
            
            .btn {
                @media (max-width: $break-md) {
                    min-width: 140px;
                    &:first-of-type {
                        float: left;
                    }
                }
            }
        }
    }
}
