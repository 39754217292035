@media (min-width: 576px) {
    .modal-dialog {
        max-width: 550px;
        transform: translate(-50%, 0);
        
    }
}

.modal-backdrop {
    z-index: 2040;
}

.modal {
    z-index: 2050;
}

.modal-content {
    border: none;
    border-radius: 4px;
    background-color: #ffffff;
    
    .modal-header {
        border: none;
        
        .close {
            font-size: 0;
            
            width: 24px;
            height: 24px;
            padding: 0;
            display: inline-block;
            margin: 0 0 0 auto;
            position: relative;
            outline: 0;
            
            &:before, &:after {
                content: '';
                display: block;
                position: absolute;
                width: 2px;
                height: 22px;
                top: 0;
                left: 11px;
                background-color: $font-color-light;
                
            }
            
            &:before {
                transform: rotate(-45deg);
            }
            
            &:after {
                transform: rotate(45deg);
            }
        }
    }
    
    .modal-body {
        padding: 1rem 2rem;
    }
    
}

.popup-style {
    .modal-dialog {
        @media (min-width: 700px) {
            min-width: 650px;
        }
        
        .modal-header {
            background-color: $dark;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            align-items: center;
            
            .modal-title {
                color: white;
                display: block;
                font-size: 22px;
            }
            
            .modal-sub-title {
                color: #909090;
                font-size: 15px;
            }
        }
        
        .modal-body {
            padding: 25px 15px;
        }
        
        .button-box {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            margin-top: 5px;
        }
    }
}
