.lightpick__apply-action {
    @extend .btn;
    @extend .btn--dark;
}

.filter-item {
    @media all and (min-width: 1050px) {
        position: relative;
    }
    
    label {
        margin: 0;
    }
    
    .price-range {
        display: none;
        position: absolute;
        top: 40px;
        left: 0;
        padding-top: 10px;
        flex-wrap: wrap;
        min-width: 320px;
        max-width: calc(100% - 30px);
        justify-content: space-between;
        @media all and (min-width: $break-md) {
            min-width: 350px;
        }
        @media all and (max-width: 1050px) {
            top: 50px;
            left: 15px;
            right: 15px;
        }
        @media all and (max-width: 992px) {
            top: 60px;
        }
        
        span {
            width: calc(50% - 10px);
            position: relative;
            
            &:before {
                content: attr(data-currency);
                display: block;
                position: absolute;
                top: 15px;
                right: 0;
                font-size: 15px;
                color: #4a4a4a;
                font-family: $font-poppins;
                width: 40px;
                text-align: center;
            }
        }
        
        input {
            width: 100%;
        }
        
        .btn {
            margin-top: 25px;
            margin-left: auto;
            color: #fff;
            border: 1px solid #909090;
        }
    }
    
    .lightpick {
        top: 8px !important;
        left: 0 !important;
    }
    
    .placeholder--select {
        display: none;
    }
    
    .category__list {
        position: absolute;
        left: 0;
        top: 50px;
        
        @media all and(max-width: 1050px) {
            top: 60px !important;
            left: 15px !important;
        }
        
        @media all and(max-width: $break-md) {
            right: 15px !important;
            overflow-y: auto;
            height: calc(100vh - 180px);
        }
        
        span {
            position: relative;
            border-radius: 3px;
            
            &:hover {
                background: #dbdbdb !important;
                color: #909090;
            }
        }
        
        ul {
            padding: 0 !important;
        }
        
        & > ul {
            @media all and(min-width: $break-sm) {
                display: block;
                position: relative;
                & > li {
                    position: absolute;
                }
            }
            
            ul {
                padding-left: 15px !important;
            }
            
            & > li {
                white-space: nowrap;
                
                & > span {
                    font-weight: bold;
                    color: #fff;
                }
                
                &:nth-child(1) {
                    top: 0;
                    left: 0;
                    
                    span {
                        padding: 10px 10px;
                    }
                }
                
                &:nth-child(2) {
                    top: 0;
                    left: 200px;
                    
                    span {
                        padding: 10px 10px;
                    }
                }
                
                &:nth-child(3) {
                    top: 165px;
                    left: 200px;
                    
                    span {
                        padding: 10px 10px;
                    }
                }
                
                &:nth-child(4) {
                    top: 0;
                    left: 400px;
                    
                    span {
                        padding: 6px 10px;
                    }
                }
                
                &:nth-child(5) {
                    top: 140px;
                    left: 400px;
                    
                    span {
                        padding: 6px 10px;
                    }
                }
                
                &:nth-child(6) {
                    top: 310px;
                    left: 400px;
                    
                    span {
                        padding: 6px 10px;
                    }
                }
            }
        }

        
    }
    

 

    .placeholder--select {
        display: none !important;
    }
    
    &.open {
        .price-range {
            display: flex;
        }
        
        .filter-option {
            color: #fff !important;
            
            &:after {
                color: #fff;
                transform: rotate(-135deg) !important;
                top: 13px !important;
            }
        }
        
        .location-search {
            display: block;
        }
        
        .category__list {
            display: block;
        }
    }
}

.navbar {
    .location-search {
        display: none;
        position: absolute;
        top: 50px;
        left: 0;
        @media all and(max-width: 1050px) {
            top: 60px !important;
            left: 15px !important;
        }
        
        @media all and(max-width: 992px) {
            top: 70px !important;
            width: calc(100% - 30px);
        }
    }
    
    .location__list {
        position: absolute;
        top: 100px;
        left: 0;
        @media all and(max-width: 1050px) {
            top: 110px;
        }
        @media all and(max-width: 992px) {
            top: 120px;
            width: calc(100vh - 320px);
            overflow-y: hidden;
        }
        
        ul {
            padding: 0;
            min-width: 300px;
            
            li:before {
                content: url('data:image/svg+xml;charset=UTF-8, <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 512 512"><path fill="rgb(255,255,255)" d="M256 0C167.641 0 96 71.625 96 160c0 24.75 5.625 48.219 15.672 69.125C112.234 230.313 256 512 256 512l142.594-279.375C409.719 210.844 416 186.156 416 160 416 71.625 344.375 0 256 0zm0 256c-53.016 0-96-43-96-96s42.984-96 96-96c53 0 96 43 96 96s-43 96-96 96z"/></svg>');
            }
        }
    }
    
    form {
        display: none;
        
        &.open {
            flex-grow: 1;
            display: block;
            
            &.open-filter {
                &::before {
                    content: "";
                    display: block;
                    z-index: -1;
                    position: fixed;
                    top: 120px;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    background: rgba(#000000, 0.85);
                    backdrop-filter: blur(8px);
                    min-height: 100vh;
                }
            }
            
            .search-input-wrapper {
                display: flex;
                
                @media all and(max-width: 993px) {
                    padding-left: 15px;
                    padding-right: 15px;
                }
                
                .search-input {
                    width: 100%;
                    flex-grow: 1;
                    opacity: 1;
                }
                
                .confirm-button {
                    position: absolute;
                    opacity: 0;
                    width: 0;
                    height: 0;
                    padding: 0;
                    border: none;
                }
            }
        }
    }
}

.search-input-wrapper {
    display: none;
    text-align: right;
    position: relative;
    background: $nav-bg;
    @media (max-width: 1200px) {
        position: fixed;
        z-index: 2;
        left: 0;
        right: 0;
        top: 75px;
        padding-left: 68px;
        padding-right: 68px;
        
        .search-close {
            right: 75px !important;
        }
    }
    @media (max-width: $break-lg) {
        padding: 0;
        top: 50px;
        
        .search-close {
            right: 10px !important;
        }
    }
    
    .search-input {
        font-family: $font-poppins;
        border-radius: 4px;
        font-size: 18px;
        line-height: 24px;
        font-weight: normal;
        color: #bababa;
        background: $search-bg url("../../image/icon/search.svg") no-repeat left 20px center;
        opacity: 0;
        height: 50px;
        border: none;
        outline: none;
        transition: opacity 0.3s ease-in-out;
        box-shadow: inset 0 -1px 1px 0 rgba(255, 255, 255, 0.18);
    }
    
    .search-close {
        position: absolute;
        cursor: pointer;
        right: 6px;
        top: 6px;
        padding: 12px;
        height: 36px;
        width: 36px;
        
        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 2px;
            height: 17px;
            top: 12px;
            background-color: #8d9ba9;
        }
        
        &:before {
            transform: rotate(-45deg);
        }
        
        &:after {
            transform: rotate(45deg);
        }
    }
}

.search-button {
    transition: all 0.3s ease;
    padding-left: 5px;
    background: url("../../image/icon/search-strong.svg") no-repeat left 2px top 10px;
    background-size: 14px auto;
    cursor: pointer;
}

.search-filters {
    transition: all 0.3s ease-in-out;
    font-family: $font-korolev;
    color: $font-color-light;
    width: 100%;
    background-color: $nav-bg;
    position: fixed;
    z-index: 1000;
    font-weight: 500;
    top: 75px;
    padding: 10px 68px;
    left: 0;
    right: 0;
    
    @media (max-width: 1200px) {
        top: 125px;
    }
    
    @media (max-width: $break-lg) {
        top: 100px;
        padding-left: 15px;
        padding-right: 15px;
    }
    
    .search-filters-inner {
        height: 40px;
        white-space: nowrap;
        @media (min-width: $break-lg) {
            height: auto;
            white-space: normal;
        }
    }
    
    .filter-item {
        display: inline-block;
        font-size: 14px;
        font-weight: 500;
        
        &.filter-label {
            margin-right: 32px;
            color: $font-color;
            display: none;
            @media (min-width: $break-lg) {
                display: inline-block;
            }
        }
        
        &.open {
            .location__list, .category__list {
                display: block;
            }
        }
        
        .filter-option {
            cursor: pointer;
            position: relative;
            color: $font-color-light;
            border-radius: 4px;
            background-color: #292929;
            padding: 12px 28px 9px 5px;
            font-size: 12px;
            
            @media (min-width: $break-lg) {
                padding: 4px 42px 4px 20px;
                background-color: transparent;
                margin: 0 30px 0 0;
                font-size: 14px;
            }
            
            &:after {
                display: block;
                content: "";
                width: 6px;
                height: 6px;
                position: absolute;
                right: 13px;
                top: 17px;
                border: 1px solid currentColor;
                border-top: none;
                border-left: none;
                transform: rotate(45deg);
            }
            
            @media (min-width: $break-lg) {
                &:after {
                    top: 10px;
                    right: 16px;
                }
            }
            
            &:last-child:before {
                display: none;
            }
            
            &.sport-type-filter,
            &.location-filter {
                .select2 {
                    display: none;
                }
                
            }
        }
    }
}

.filter-layer-open {
    main,
    footer {
        filter: blur(8px);
    }
    
    .search-filters-layer {
        display: block;
    }
}

.search-filters-layer {
    display: none;
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999;
    background-color: #000000cc;
    padding: 10px 68px;
    top: 160px;
    @media (min-width: $break-lg) {
        top: 174px;
    }
    @media (min-width: $break-xl) {
        top: 124px;
    }
}

body .lightpick__close-action {
    display: none !important;
}


