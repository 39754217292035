html {
    height: 100%;
}

body {
    display: flex;
    flex-direction: column;
    min-height: 100%;
}

main {
    padding: 140px 0;
    transition: margin-top .1s ease;
    
    &.main-page, &.no-space {
        padding-top: 0;
    }
    
    .container-max-with {
        max-width: 1500px;
    }
    
    &.result {
        @media (min-width: $break-lg) {
            padding-top: 125px;
        }
    }
}

.search-open {
    main {
        transition: margin-top .3s ease;
        transition-delay: .1s;
    }
}

@each $name, $break-point in $grid-breakpoints {
    .hidden-#{$name}-down {
        @media (max-width: $break-point) {
            display: none !important;
        }
    }
    .hidden-#{$name}-up {
        @media (min-width: $break-point) {
            display: none !important;
        }
    }
}
