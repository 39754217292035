.flash-message {
    position: fixed;
    width: 100%;
    left: 0;
    top: 98px;
    z-index: 20;
    
    @media (min-width: $break-lg) {
        top: 98px;
    }
    
    .message {
        font-family: $font-korolev;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 0.2px;
        text-align: center;
        color: #000;
        padding: 5px;
        background-color: #d3f7d1;
        
        .message-content {
            display: inline-block;
            position: relative;
            
            &:before {
                position: absolute;
                display: block;
                left: -20px;
                width: 15px;
                height: 15px;
                content: ' ';
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;
                background-image: url("../../image/notify/success.svg");
            }
        }
        
        &.warning {
            background-color: #fefbd3;
            
            .message-content {
                &:before {
                    background-image: url("../../image/notify/warning.svg");
                }
            }
        }
        
        &.error {
            background-color: #fbdbdc;
            
            .message-content {
                &:before {
                    background-image: url("../../image/notify/error.svg");
                }
            }
        }
        
        .close-block {
            display: block;
            float: right;
            position: relative;
            height: 15px;
            width: 15px;
            
            &:before, &:after {
                content: '';
                display: block;
                position: absolute;
                width: 2px;
                height: 11px;
                top: 2px;
                left: 7px;
                background-color: $font-color;
                cursor: pointer;
            }
            
            &:before {
                transform: rotate(-45deg);
            }
            
            &:after {
                transform: rotate(45deg);
            }
        }
    }
}

.search-open {
    .flash-message {
        position: fixed;
        @media (min-width: $break-lg) {
            top: 128px;
        }
    }
    
}
