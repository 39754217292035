.home,
.about-us,
.pricing {
    nav.navbar {
        background: transparent;
        
        &.scrolled, &.menu-open {
            background-color: $nav-bg;
        }
    }
}

body.open-menu {
    
    //nav.navbar {
    //  background: transparent;
    //
    //  &.scrolled, &.menu-open {
    //    background-color: $nav-bg;
    //  }
    //}
}

nav.navbar {
    color: $nav-color;
    font-family: $font-korolev;
    padding: 0 15px;
    transition: all 0.2s ease-in-out;
    background-color: $nav-bg;
    
    @media (min-width: $break-lg) {
        padding: 5px 68px 5px;
    }
    
    .mobile-search {
        display: none;
        width: 30px;
        height: 30px;
        border: none;
        background-position: center;
        background-size: 27px;
        margin-left: auto;
        margin-right: 20px;
        
        @media (max-width: $break-lg) {
            display: block;
        }
    }
    
    .navbar-brand {
        width: 147px !important;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -0.2px;
        text-decoration: none;
        color: $nav-color;
        margin: 0;
        padding-right: 30px;
        height: 50px;
        line-height: 2.2;
        
        @media (min-width: $break-lg) {
            height: 65px;
            line-height: 2.95;
        }
    }
    
    .navbar-nav {
        margin-left: auto;
        white-space: nowrap;
        
        @media (max-width: $break-lg) {
            position: fixed;
            top: 50px;
            left: 0;
            right: 0;
            height: 0;
            overflow: hidden;
            transition: all 0.4s ease-in-out;
            background-color: $nav-bg;
            &.show {
                padding-top: 25px;
                overflow-y: auto;
                height: calc(100vh - 50px);
            }
        }
        
        .nav-item {
            padding: 0;
            border-radius: 4px;
            margin-left: 5px;
            order: 2;
            
            &.profile-link {
                @media (max-width: $break-lg) {
                    order: 1;
                    margin-bottom: 25px;
                }
            }
            
            .nav-link {
                color: $nav-color;
               
                font-size: 14px;
                font-weight: bold;
                text-decoration: none;
                padding: 10px 23px 5px;
                text-align: center;
                cursor: pointer;
                letter-spacing: 0.2px;
                
                @media (max-width: $break-lg) {
                    font-size: 24px;
                    font-weight: 400;
                    text-transform: initial;
                    text-align: left;
                }
                
                &:after {
                    display: none;
                }
                
                &:hover {
                    text-decoration: none;
                    color: $nav-color-hover;
                }
                
                &.dropdown-toggle {
                    display: flex;
                }
            }
            
            &.profile-link {
                margin-left: 27px;
                line-height: 35px;
                
                .nav-link {
                    color: #fff;
                    padding: 0;
                    
                    &:hover {
                        color: #fff;
                    }
                    
                    .profile-user-img {
                        display: inline-block;
                        width: 35px;
                        height: 35px;
                        margin-right: 10px;
                        border-radius: 50%;
                        background-color: $font-color-light;
                        background-size: cover;
                    }
                    
                    .profile-username {
                        display: inline-block;
                        padding-top: 2px;
                    }
                }
                
                .profile-dropdown {
                    font-family: $font-poppins;
                    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
                    left: auto;
                    right: -10px;
                    padding: 15px 25px;
                    border-radius: 4px;
                    border: none;
                    margin-top: 15px;
                    outline: none;
                    z-index: 2010;
                    
                    @media (max-width: $break-lg) {
                        background: transparent;
                        box-shadow: none;
                        color: #fff;
                        display: block;
                        padding: 0 28px 0 0;
                        font-size: 24px;
                    }
                    
                    hr {
                        padding: 4px;
                        @media (max-width: $break-lg) {
                            margin-top: 20px;
                        }
                    }
                    
                    .dropdown-item {
                        padding: 8px 0;
                        min-width: 230px;
                        
                        @media (max-width: $break-lg) {
                            color: #fff;
                            padding: 8px 0 7px;
                            &:last-of-type {
                                display: none;
                            }
                        }
                        
                        &.favourite-item, &.ticket-item, &.claim-item {
                            padding-left: 40px;
                            position: relative;
                            
                            &:before {
                                content: '';
                                display: block;
                                position: absolute;
                                left: 0;
                                top: 0;
                                height: 50px;
                                width: 40px;
                                background: url("../../image/icon/like.svg") no-repeat left center;
                            }
                        }
                        
                        &.ticket-item {
                            &:before {
                                background-image: url("../../image/icon/tickets.svg");
                            }
                        }
                        
                        &.claim-item {
                            &:before {
                                background-image: url("../../image/icon/promote.svg");
                            }
                        }
                        
                        &.profile-item {
                            display: flex;
                            flex-direction: row;
                            justify-items: center;
                            
                            .profile-user-img {
                                display: inline-block;
                                width: 60px;
                                height: 60px;
                                margin-right: 10px;
                                border-radius: 50%;
                                background-color: $font-color-light;
                                background-size: cover;
                            }
                            
                            .profile-username {
                                justify-content: center;
                                display: flex;
                                flex-direction: column;
                                line-height: 1;
                                
                                .profile-name {
                                    font-family: $font-korolev;
                                    font-size: 20px;
                                }
                                
                                .profile-email {
                                    font-size: 12px;
                                }
                            }
                        }
                        
                        &:hover,
                        &:active,
                        &:focus {
                            background: transparent;
                            color: inherit;
                        }
                    }
                }
            }
            
            &.log-in-link {
                background-color: #292929;
                border: 1px solid #454545;
                margin-left: 27px;
                width: 120px;
                
                @media (max-width: $break-lg) {
                    margin-top: 20px;
                    min-width: 200px;
                }
                
                .nav-link {
                    color: #fff;
                    
                    &:hover {
                        color: #fff;
                    }
                }
            }
            
            &.yellow {
                .nav-link {
                    color: $yellow;
                }
            }
            
            &.sign-up-link {
                background-color: #fff;
                margin-left: 27px;
                width: 120px;
                @media (max-width: $break-lg) {
                    margin-top: 25px;
                    min-width: 200px;
                }
                
                .nav-link {
                    color: $font-color;
                    
                    &:hover {
                        color: $font-color;
                    }
                }
            }
        }
    }
}

.nav-item {
    &.search-button {
        &.hide {
            display: none;
        }
        
        @media (max-width: $break-lg) {
            position: fixed;
            top: 15px;
            right: 70px;
            background-size: 25px auto;
            background-position: 0;
            padding: 0;
            width: 25px;
            height: 25px;
            
            span {
                display: none;
            }
        }
    }
}

.open-menu {
    .search-button {
        display: none !important;
    }
}

.navbar-toggler {
    width: 30px;
    height: 25px;
    border: none;
    padding: 6px 3px;
    cursor: pointer;
    position: relative;
    
    span {
        background-color: #9b9b9b;
        position: absolute;
        border-radius: 2px;
        transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
        width: 100%;
        height: 3px;
        transition-duration: 300ms;
        
        &:nth-child(1) {
            top: 0;
            left: 0;
        }
        
        &:nth-child(2) {
            top: 11px;
            left: 0;
        }
        
        &:nth-child(3) {
            bottom: 0;
            left: 0;
        }
    }
    
    &.open {
        span {
            &:nth-child(1) {
                transform: rotate(45deg);
                top: 11px;
            }
            
            &:nth-child(2) {
                opacity: 0;
            }
            
            &:nth-child(3) {
                transform: rotate(-45deg);
                top: 11px;
            }
        }
    }
}

.mobile-log-out {
    display: none;
    @media (max-width: $break-lg) {
        display: block;
        hr {
            margin: 25px 28px;
        }
    }
}
