@import 'core/korolev';

$font-poppins: 'Poppins', sans-serif;
$font-korolev: 'Korolev', sans-serif;

body {
    color: $font-color;
    font-family: $font-poppins;
}

h1, h2, h3, h4, h5, h6 {
    color: $font-color;
    font-family: $font-poppins;
    font-weight: 500;
}

h1 {
    font-size: 32px;
    @media (min-width: $break-md) {
        font-size: 48px;
    }
    
    .title {
        font-size: 32px;
        line-height: 44px;
        @media (min-width: $break-md) {
            font-size: 36px;
            line-height: 49px;
        }
    }
}

h2 {
    font-size: 20px;
    @media (min-width: $break-md) {
        font-size: 32px;
    }
    
    &.big {
        font-size: 32px;
        @media (min-width: $break-md) {
            font-size: 48px;
        }
    }
}

h3 {
    font-size: 24px;
}

p {
    font-family: $font-poppins;
    font-size: 15px;
    line-height: 1.73;
    
    &.info {
        color: $font-color-light;
    }
    
    &.info-box {
        max-width: 600px;
    }

    &.pre-wrap {
        white-space: pre-wrap;
    }
}

.main-font {
    font-family: $font-poppins;
}

.head-font {
    font-family: $font-poppins;
}

.btn, button {
    font-family: $font-korolev;
    font-weight: 700;
}

label, .label {
    font-family: $font-poppins;
    font-size: 14px;
}

a {
    color: $font-color;
    background-color: transparent;
    
    &:hover, &:active {
        color: $font-color;
        
    }
}


h2.claim_event_title span {
    position: relative;
    z-index: 2;
    font-weight: 600;
}

h2.claim_event_title:before {
    content: "";
    width: 37px;
    height: 37px;
    position: absolute;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}