body.popup-overflow {
    overflow: hidden;
}

.event-create-form {
    max-width: 650px;
 
    .form-separator {
        margin: 40px 0;
    }
    
    .step-bar {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        
        &:before {
            content: '';
            display: block;
            position: absolute;
            height: 1px;
            width: 100%;
            background-color: $gray-light;
            top: 13px;
            z-index: -1;
        }
        
        .step-bar-item {
            background: white;
            padding: 0 20px;
            
            &:first-of-type {
                padding-left: 0;
            }
            
            &:last-of-type {
                padding-right: 0;
            }
            
            .item-id {
                display: inline-block;
                font-size: 13px;
                width: 20px;
                height: 20px;
                border-radius: 10px;
                background-color: $font-color;
                color: #fff;
                text-align: center;
                line-height: 20px;
            }
            
            .item-name {
                display: inline-block;
                margin-left: 10px;
                font-size: 13px;
                line-height: 20px;
            }
            
            &.active {
                .item-id {
                    background-color: $yellow;
                    color: $font-color;
                }
            }
        }
    }
    
    h2 {
        font-size: 24px;
    }
    
    h3 {
        font-size: 20px;
    }
    
    h4 {
        font-size: 18px;
    }
    
    h5 {
        font-size: 16px;
    }
    
    .create-form-content {
        margin-top: 35px;
    }
    
    .btn--cancel {
        padding-right: 40px;
        padding-left: 40px;
        min-width: 50px;
    }
    
    .form-description {
        margin-bottom: 40px;
        margin-top: 8px;
    }
    
    .form-add-item {
        margin-bottom: 24px;
        width: 100%;
        height: 48px;
        border-radius: 3px;
        border: dashed 1px $input-border;
        background-color: $input-bg;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        
        .add-label {
            font-weight: bold;
            margin: 5px 0 0;
        }
    }
    
    .ticket-sale-method {
        .ticket-info {
            font-family: $font-korolev;
            font-size: 15px;
        }
        
        .ticket-type-item {
            cursor: pointer;
            border-radius: 3px;
            border: solid 1px $input-border;
            background-color: $input-bg;
            padding: 16px 26px;
            color: $font-color-dark;
            
            .item-radio {
                padding-right: 45px;
                display: flex;
                align-items: center;
            }
            
            .item-content {
                display: flex;
                flex-direction: column;
                
                .item-head {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    
                    h2 {
                        font-size: 20px;
                        margin: 0;
                        font-weight: bold;
                        letter-spacing: 0.28px;
                        color: $font-color-dark;
                    }
                    
                    .label-reco {
                        font-family: $font-korolev;
                        font-size: 16px;
                        font-weight: bold;
                        width: 134px;
                        height: 35px;
                        border-radius: 4px;
                        background-color: $yellow;
                        letter-spacing: 0.23px;
                        color: $font-color-dark;
                        padding: 8px 16px;
                    }
                }
                
                .method-info {
                    margin-top: 10px;
                    font-family: $font-korolev;
                    font-size: 15px;
                    font-weight: 500;
                    line-height: 1.73;
                    letter-spacing: 0.21px;
                }
            }
        }
    }
    
    .form-popup-layer {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1040;
        background-color: rgba(#000, .3);
    }
    
    .form-popup {
        position: fixed;
        right: -100%;
        top: 0;
        height: 100vh;
        width: 420px;
        max-width: 99vw;
        box-shadow: 0 2px 24px 0 #2c323a19;
        background-color: #ffffff;
        transition: right .3s ease;
        padding-bottom: 65px;
        z-index: 1050;
        
        &.open {
            right: 0;
        }
        
        .popup-body {
            height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            padding: 24px;
        }
        
        .popup-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding: 8px 0;
            margin: 0 24px;
            width: calc(100% - 48px);
            border-top: 1px solid $input-border;
            background: white;
        }
        
        .form-sep {
            margin-top: 23px;
            padding-bottom: 16px;
            height: 1px;
            border-top: 1px solid $input-border;
        }
        
        .select-category {
            border: 1px solid $input-border;
            background-color: $input-bg;
            height: 48px;
            font-family: $font-korolev;
            font-size: 14px;
            border-radius: 3px;
            line-height: 48px;
            padding: 0 20px 0 50px;
            display: inline-block;
            position: relative;
            cursor: pointer;
            
            &:before, &:after {
                content: '';
                display: block;
                height: 2px;
                width: 14px;
                top: 23px;
                left: 20px;
                background-color: $font-color-light;
                position: absolute;
            }
            
            &:after {
                transform: rotate(90deg);
            }
        }
        
        .categories-list {
            margin-top: 10px;
            
            .btn {
                margin: 5px 5px 0 0;
            }
        }
    }
    
    .form-footer {
        position: fixed;
        bottom: 0;
        background: white;
        width: 100%;
        left: 0;
        
        .event-create-form {
            height: 67px;
            border-top: 1px solid $input-border;
            flex-direction: row;
            justify-content: space-between;
            display: flex;
            width: 100%;
            align-items: center;
            
        }
    }
    
    .activity-list {
        .activity-list-item {
            margin-bottom: 64px;
            
            .item-head {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
            
            .activity-name {
                font-size: 26px;
                font-weight: bold;
                color: $font-color-dark;
                margin: 0;
                
                &.small {
                    font-size: 20px;
                }
            }
            
            .activity-date {
                background: url("../../image/icon/calendar.svg") no-repeat top left;
                padding-left: 35px;
                margin-top: 24px;
                font-size: 15px;
                color: $font-color-light;
            }
            
            .activity-location {
                background: url("../../image/icon/location.svg") no-repeat top left;
                padding-left: 35px;
                margin-top: 14px;
                font-size: 15px;
                color: $font-color-light;
            }
            
            .activity-tickets {
                margin-top: 32px;
            }
        }
        
        .ticket-list-item {
            border-radius: 3px;
            border: 1px solid $input-border;
            background-color: $input-bg;
            border-left: 7px solid $yellow;
            padding: 16px 20px 16px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
            cursor: pointer;
            position: relative;
            
            .ticket-title {
                font-size: 20px;
                font-weight: bold;
                color: $font-color-dark;
                margin: 0;
            }
            
            .item-info {
                .item-info-price {
                    font-family: $font-korolev;
                    font-size: 24px;
                    font-weight: bold;
                    line-height: 1.33;
                    letter-spacing: 0.34px;
                    text-align: right;
                    color: $font-color-dark;
                }
                
                .item-info-date {
                    font-size: 15px;
                    color: $font-color-light;
                    
                }
            }
            
            .remove-ticket {
                position: absolute;
                height: 80px;
                width: 0;
                background: url("../../image/icon/delete.svg") no-repeat center;
                cursor: pointer;
                opacity: 0;
                transition: all .3s ease;
                right: 0;
            }
            
            &:hover {
                .remove-ticket {
                    opacity: 1;
                    width: 45px;
                    right: -45px;
                }
            }
            
            @media (max-width: 800px) {
                width: calc(100% - 40px);
                
                .remove-ticket {
                    opacity: 1;
                    width: 45px;
                    right: -45px;
                }
            }
        }
    }
}

