.react-datepicker {
    
    border-radius: 3px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    width: 310px;
    border: none;
    font-family: $font-poppins;
    font-size: 12px;
    
    &.react-datepicker--time-only {
        width: 150px;
    }
    
    .react-datepicker__header__dropdown {
        position: absolute;
        top: 0;
        width: 100%;
        
        .react-datepicker__year-dropdown {
            box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
            background-color: #ffffff;
            border: none;
            max-height: 200px;
            overflow: auto;
        }
        
        .react-datepicker__year-read-view {
            padding: 15px 30px;
            opacity: 0;
            
        }
    }
    
    .react-datepicker__navigation {
        width: 30px;
        height: 33px;
        border-radius: 3px;
        border: solid 1px #e5e5e5;
        padding: 6px;
        line-height: 19px;
        top: 8px;
        
        &:before {
            display: block;
            content: '';
            position: absolute;
            border: 2px solid $dark;
            border-top: none;
            border-right: none;
            height: 8px;
            width: 8px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }
        
        &--previous {
            left: 16px;
        }
        
        &--next {
            right: 16px;
            
            &:before {
                content: '';
                position: absolute;
                border: 2px solid $dark;
                border-top: none;
                border-right: none;
                height: 8px;
                width: 8px;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%) rotate(-135deg);
            }
        }
        
        &--years-upcoming, &--years-previous {
            display: none;
        }
    }
    
    .react-datepicker__time-container,
    .react-datepicker__month-container {
        float: initial;
        width: 100%;
        
        .react-datepicker__time-box {
            width: 100%;
            text-align: left;
            
            .react-datepicker__time-list {
                .react-datepicker__time-list-item {
                    height: 50px !important;
                    padding: 15px 25px !important;
                    font-size: 15px;
                    color: $font-color !important;
                }
            }
        }
        
        .react-datepicker__header {
            padding: 0;
            background-color: #ffffff;
            border: none;
            
            &.react-datepicker__header--time {
                display: none;
            }
            
            .react-datepicker__current-month {
                height: 50px;
                display: flex;
                justify-content: center;
                border-bottom: solid 1px #ededed;
                align-items: center;
                font-family: $font-korolev;
                font-size: 18px;
                font-weight: 500;
                
                &:after {
                    content: '';
                    display: inline-block;
                    border: 2px solid $dark;
                    border-top: none;
                    border-right: none;
                    margin: 5px;
                    height: 8px;
                    width: 8px;
                    transform: rotate(-45deg) translate(3px, -1px);
                }
            }
            
            .react-datepicker__day-names {
                margin: 0 10px;
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                
                .react-datepicker__day-name {
                    color: #cacaca;
                }
            }
        }
        
        .react-datepicker__month {
            margin: 5px 10px 10px;
            
            .react-datepicker__week {
                display: grid;
                grid-template-columns: repeat(7, 1fr);
                
                .react-datepicker__day {
                    width: auto;
                    color: $font-color;
                    
                    &--outside-month,
                    &--disabled {
                        color: #cacaca;
                    }
                    
                    &--keyboard-selected {
                        background-color: $dark;
                        color: #fff;
                    }
                }
            }
        }
    }
}
