.categories {
  
    
    .category {
        &__title {
            width: 100%;
            display: block;
            margin: 50px 10px 0px;
        }
        
        &__block {
            float: left;
            padding: 50px 6px 0;
            width: 50%;
            
            @media (min-width: $break-md) {
                width: 33.3%;
                padding: 50px 10px 0;
            }
            
            @media (min-width: $break-lg) {
                width: 25%;
            }
            
            @media (min-width: $break-xl) {
                width: 20%;
            }
            
            &:hover {
                .category__layer {
                    opacity: 1;
                }
            }
        }
        
        &__layer {
            position: absolute;
            border-radius: 4px;
            overflow: hidden;
            transition: opacity .3s ease;
            opacity: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.7);
            display: flex;
            justify-content: center;
            align-items: center;
            
            span {
                line-height: 60px;
                font-size: 60px;
                font-weight: 500;
                letter-spacing: 4.6px;
                color: #f8e71c;
                text-align: center;
            }
        }
        
        &__cover {
            padding-bottom: 75%;
            position: relative;
            overflow: hidden;
            border-radius: 11px;
            position: relative;
            
            img {
                position: absolute;
                top: 0;
                left: 0;
                // height: 100%;
                width: 100%;
                object-fit: cover;
                opacity: 1;
            }
        }
        
        &__name {
            text-align: left;
            font-size: 20px;
            font-weight: 600;
            /* margin-top: 15px; */
            position: absolute;
            bottom: 17px;
            left: 10px;
            color: white;
           
            // margin-top: 15px;
            
        }
    }
}


h2.activity_title {
    font-size: 48px;
    font-weight: 900;
    position: relative;
    bottom: 90px;
}

h2.activity_title span {
    position: relative;
    z-index: 2;
    text-shadow: 0px 0, 0 1px, 0px 0, 0 0px, 0px -1px, 1px 0px, -1px 0px, 1px -1px;
    letter-spacing: 2px;
}

h2.activity_title:before {
    content: "";
    width: 65px;
    height: 65px;
    position: absolute;
    right: calc(50% + 77px);
    bottom: -4px;
    background-color: #F7EA00;
    border-radius: 50%;
    z-index: 1;
}