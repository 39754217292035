$bg-color: #f8f8f8;

.section {
    
    &__background {
        background-size: cover;
        background-position: center;
        color: #fff;
        padding: 100px 0;
       
        
        .section__inner {
            max-width: 600px;
            text-align: center;
            margin: 0 auto;
        }
        
        .section__title {
            color: #fff;
            font-size: 48px;
            font-weight: 500;
            margin-bottom: 60px;
            text-shadow: 0 0 14px rgba(0, 0, 0, 0.47);
        }
        
        .section__text {
            line-height: 1.75;
            letter-spacing: -0.05px;
            margin-bottom: 60px;
            font-size: 16px;
        }
    }
    
    .section-title {
        margin-top: 80px;
        @media (min-width: $break-xl) {
            margin-top: 120px;
        }
        
        .d-flex {
            align-items: center;
        }
        
        hr {
            margin: 0 10px 0;
        }
        
        p {
            max-width: 650px;
            margin-top: 25px;
        }
        
        .period-list {
            overflow-x: auto;
            height: 50px;
            @media (min-width: $break-xl) {
                overflow-x: unset;
                height: auto;
            }
            
            .period-list-inner {
                height: 50px;
                white-space: nowrap;
                @media (min-width: $break-xl) {
                    height: auto;
                    white-space: normal;
                }
                
                .period {
                }
                
            }
            
        }
    }
    
    .view-all-mobile {
        text-align: center;
        margin-top: 50px;
    }
    
    .explore-city-select-wrapper {
        margin: 0 8px;
        
        .explore-city {
            text-decoration: underline;
        }
        
        .select2 {
            width: 0 !important;
            overflow: hidden;
            float: left;
            opacity: 0;
        }
        
        .select2-dropdown {
            width: 300px !important;
        }
    }
    
    .no-result-col {
        max-width: 100% !important;
        
        .no-result {
            text-align: center;
            display: block;
            height: 215px;
            position: relative;
            margin-bottom: 100px;
            margin-top: 30px;
            padding-top: 130px;
           
            font-size: 40px;
            background-image: repeating-linear-gradient(to right, $bg-color, $bg-color 24%, transparent 24%, transparent 25.5%);
            
            &:before {
                height: 20px;
                top: 225px;
                display: block;
                content: '';
                position: absolute;
                width: 100%;
                background-image: repeating-linear-gradient(to right, $bg-color, $bg-color 13%, transparent 13%, transparent 25.5%)
            }
            
            &:after {
                height: 55px;
                top: 255px;
                display: block;
                content: '';
                position: absolute;
                width: 100%;
                background-image: repeating-linear-gradient(to right, $bg-color, $bg-color 24%, transparent 24%, transparent 25.5%);
            }
            
            @media (max-width: $break-xl) {
                background-image: repeating-linear-gradient(to right, $bg-color, $bg-color 48%, transparent 48%, transparent 51%);
                &:before {
                    background-image: repeating-linear-gradient(to right, $bg-color, $bg-color 26%, transparent 26%, transparent 51%)
                }
                &:after {
                    background-image: repeating-linear-gradient(to right, $bg-color, $bg-color 48%, transparent 48%, transparent 51%);
                }
            }
            
            @media (max-width: $break-md) {
                background-image: repeating-linear-gradient(to right, $bg-color, $bg-color 100%);
                &:before {
                    background-image: repeating-linear-gradient(to right, $bg-color, $bg-color 51%, transparent 51%, transparent 100%)
                }
                &:after {
                    background-image: repeating-linear-gradient(to right, $bg-color, $bg-color 100%);
                }
            }
        }
    }
}

.city-block {
    margin-top: 50px;
    padding-bottom: 150%;
    position: relative;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    
    .city-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
        display: flex;
        color: #ffffff;
        text-shadow: 0 2px 14px rgba(0, 0, 0, 0.6);
        padding: 20px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        font-family: $font-korolev;
        
        .city-name {
            font-size: 30px;
        }
        
        .city-count {
            font-size: 14px;
        }
    }
}

.category-block {
    margin-top: 50px;
    padding-bottom: 40%;
    position: relative;
    border-radius: 4px;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    
    .category-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.3);
        display: flex;
        color: #ffffff;
        text-shadow: 0 2px 14px #000000;
        padding: 20px 0;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: $font-korolev;
        
        .category-name {
            font-size: 30px;
        }
        
        .category-count {
            font-size: 14px;
        }
    }
}

.explore-events-list {
    position: relative;
}

.event-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100px;
        height: 100px;
        background: url("../../image/loader.gif") no-repeat center;
        background-size: 100%;
    }
}
